import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { getColumns, searchFields } from './constants'
import ListTemplate from '../../ListTemplate'
import { Button, Card, Checkbox, Layout, Space, Typography } from 'antd'
import { SelectFetch } from '../../../components'
import { useList } from '../../../contexts'
import { ResetFilter } from '../../../assets/svg'
import { MultipleTableFilter } from '../../../contexts/list/types'
import { RawMatStorage } from '@isf/backend/src/modules/stocks/modules/raw-mat/entities/raw-mat-storage.entity'
import Bar from '../../../layout/bar/Bar'
import { useColumnSearchProps } from '../../../utils/hooks'

const RawMatList = () => {
	const data = useLoaderData() as RawMatStorage[]
	const {
		state: { inStock, filters },
		setFilters,
		resetFilters,
		setInStock,
	} = useList()
	const navigate = useNavigate()
	const location = useLocation()
	const onRow = (record: RawMatStorage) => ({
		onClick: () => navigate(`${location.pathname}/${record.id}`),
	})
	const filteredData = data.filter((el) => {
		if (inStock === true) {
			if (!['Необходим вх. контроль', 'Доступно'].includes(el.status)) {
				return false
			}
		}
		if (filters.organization) {
			if (
				(filters as MultipleTableFilter).organization.some(
					(org) => org.value !== el.rawMat.supplyNomenclature.organization?.id
				)
			) {
				return false
			}
		}
		if (filters.rawMatCategory) {
			if (
				(filters as MultipleTableFilter).rawMatCategory.some(
					(cat) => cat.value !== el.rawMat.supplyNomenclature.rawMatCategory.id
				)
			) {
				return false
			}
		}
		return true
	})
	const { getColumnSearchProps } = useColumnSearchProps(filteredData)

	const prefix = (
		<Space size="small">
			<Typography.Text strong>В наличии</Typography.Text>
			<Checkbox checked={inStock} onChange={(e) => setInStock(e.target.checked)} />
			<Typography.Text strong>Организация заказчик</Typography.Text>
			<SelectFetch
				mode="multiple"
				style={{ width: 200 }}
				url={`/dictionaries/organizations`}
				onSelect={(value) =>
					setFilters({
						...filters,
						organization: [...((filters as MultipleTableFilter).organization || []), value],
					})
				}
				onDeselect={({ value }) =>
					setFilters({
						...filters,
						organization: (filters as MultipleTableFilter).organization.filter(
							(f) => f.value !== value
						),
					})
				}
				value={filters.organization}
			/>
			<Typography.Text strong>Категория</Typography.Text>
			<SelectFetch
				mode="multiple"
				style={{ width: 200 }}
				url={`/dictionaries/raw-mat-categories`}
				onSelect={(value) =>
					setFilters({
						...filters,
						rawMatCategory: [...((filters as MultipleTableFilter).rawMatCategory || []), value],
					})
				}
				onDeselect={({ value }) =>
					setFilters({
						...filters,
						rawMatCategory: (filters as MultipleTableFilter).rawMatCategory.filter(
							(f) => f.value !== value
						),
					})
				}
				value={filters.rawMatCategory}
			/>
			<Button
				type="text"
				icon={<ResetFilter className="pictogram" style={{ fontSize: 24 }} />}
				onClick={() => {
					resetFilters()
				}}
			/>
		</Space>
	)
	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<ListTemplate
						searchFields={searchFields}
						prefixRow={prefix}
						columns={getColumns(getColumnSearchProps)}
						dataSource={filteredData}
						onRow={onRow}
						noRowButtons
					/>
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default RawMatList
