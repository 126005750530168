import { useLoaderData } from 'react-router-dom'
import { formItems } from './constants'
import { Card, Form, Space } from 'antd'
import { CustomFormWithTabs } from '../../../components'
import { useForm } from 'antd/es/form/Form'
import { Warehouse as WarehouseType } from '@isf/backend/src/modules/dictionaries/modules/warehouse/entities/warehouse.entity'
import FixShiftTab from './tabs/FixShiftTab'
import { SlidingShiftTab } from './tabs'
import Bar from '../../../layout/bar/Bar'
import { InternalNamePath } from 'antd/es/form/interface'

const Warehouse = () => {
	const data = useLoaderData() as WarehouseType
	const [form] = useForm()
	const slidingOrFixShifts = Form.useWatch('slidingOrFixShifts', form)
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar form={form} />
			<Card style={{ margin: '0 16px' }}>
				<CustomFormWithTabs
					form={form}
					colCount={3}
					rowCount={2}
					items={formItems}
					requiredTabFields={[['fixShifts'], ['slidingShifts']] as InternalNamePath[]}
					initialValues={data || { slidingOrFixShifts: 'Фиксированные', location: 'Внутренний' }}
					tabs={[
						slidingOrFixShifts === 'Фиксированные' || !slidingOrFixShifts
							? { key: 'fixed', children: <FixShiftTab />, label: 'Смены' }
							: { key: 'sliding', children: <SlidingShiftTab />, label: 'Смены' },
					]}
				/>
			</Card>
		</Space>
	)
}

export default Warehouse
