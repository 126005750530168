import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { ProductGroup as ProductGroupEntity } from '@isf/backend/src/modules/dictionaries/modules/product-group/entities/product-group.entity'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['label']

const ProductGroup = () => {
	const data = useLoaderData()
	const submit = useSubmit()
	const columns: ColumnsType<ProductGroupEntity> = [
		{
			title: 'Группа продуктов',
			dataIndex: 'label',
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'label',
			label: 'Группа продуктов',
			rules: [{ required: true }],
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default ProductGroup
