import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { ProductPackageType as ProductPackageTypeEntity } from '@isf/backend/src/modules/dictionaries/modules/product-package-type/entities/product-package-type.entity'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import { Col, Select } from 'antd'
import { MaskedInput, SelectFetch } from '../../../components'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { CompareFn } from 'antd/es/table/interface'
import { getColumnSearchProps } from '../../../utils/helpers'

const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['label']

const ProductPackageType = () => {
	const data = useLoaderData() as ProductPackageTypeEntity[]
	const submit = useSubmit()
	const columns: ColumnsType<ProductPackageTypeEntity> = [
		{
			title: 'Типы упаковки продукции',
			dataIndex: 'label',
			...getColumnSearchProps(data, 'label'),
			sorter: sorter(SorterTypes.string, 'label') as CompareFn<ProductPackageTypeEntity>,
		},
		{
			title: 'Квант',
			dataIndex: 'quantum',
			...getColumnSearchProps(data, 'quantum'),
		},
		{
			title: 'ЕИ',
			dataIndex: ['measure', 'label'],
			...getColumnSearchProps(data, ['measure', 'label']),
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'label',
			label: 'Типы упаковки продукции',
			rules: [{ required: true }],
			component: (
				<Select notFoundContent={<Col>Не найдено</Col>}>
					{['Штучная', 'Весовая'].map((val: string) => (
						<Select.Option key={val} value={val}>
							{val}
						</Select.Option>
					))}
				</Select>
			),
			...layout,
		},
		{
			name: 'quantum',
			label: 'Квант',
			rules: [{ required: true }],
			component: <MaskedInput max={99.999} scale={3} allowNegative={false} />,
			...layout,
		},
		{
			name: 'measure',
			label: 'ЕИ',
			required: true,
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
				id: value,
				label,
			}),
			component: (
				<SelectFetch
					url={`/dictionaries/measures?filterBy=group:${encodeURIComponent(
						'Экономические единицы'
					)}`}
				/>
			),
			rules: [{ required: true }],
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default ProductPackageType
