import React from 'react'
import { Layout } from 'antd'
import Logo from './Logo'
import Items from './Items'
import { menuPositions, useMenu } from '../../contexts'

const { Sider } = Layout

const Menu = () => {
	const {
		state: { position, collapsed },
	} = useMenu()
	return position === menuPositions.left ? (
		<Sider
			width={250}
			collapsed={collapsed}
			collapsible
			id="leftMenu"
			trigger={null}
			style={{
				overflow: 'auto',
				position: 'sticky',
				top: 0,
				left: 0,
			}}
		>
			<Logo />
			<Items />
		</Sider>
	) : (
		<Items />
	)
}

export default Menu
