import React from 'react'
import {
	Button,
	Col,
	Form,
	FormListFieldData,
	FormListOperation,
	Row,
	Select,
	Typography,
} from 'antd'
import { DatePicker, SelectFetch, TabItem, TableForm } from '../../../components'
import { Link, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import { SupplyNomenclature } from '@isf/backend/src/modules/supply/entities/supply_nomenclature.entity'

interface NomenclatureTabColumnType extends FormListFieldData {}

const columns: NomenclatureTabColumnType[] = [
	{
		dataIndex: 'partyNum',
		title: '№ партии',
		readOnly: true,
	},
	{
		dataIndex: ['rawMatNomenclature', 'rawMatType', 'label'],
		title: 'Номенклатура',
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		noStyle: true,
		component: <SelectFetch url="/nomenclatures/raw-mat-nomenclatures" />,
		rules: [{ required: true }],
	},
	{
		dataIndex: 'quantity',
		noStyle: true,
		title: 'Кол-во, факт',
		rules: [
			{ required: true },
			({ getFieldValue, setFieldValue }) => ({
				validator({ field }, value: SupplyNomenclature['quantity']) {
					const totalSum = getFieldValue(field.split('.').toSpliced(-1, 1, 'totalSum'))
					setFieldValue('price', +totalSum / +value)
					return Promise.resolve()
				},
			}),
		],
	},
	{
		dataIndex: 'measure',
		noStyle: true,
		title: 'ЕИ',
	},
	{
		dataIndex: 'price',
		title: 'Цена за ед. с НДС',
		key: 'price',
		noStyle: true,
		readOnly: true,
	},
	{
		dataIndex: 'nds',
		title: 'НДС, %',
		noStyle: true,
		rules: [{ required: true }],
	},
	{
		dataIndex: 'totalSum',
		title: 'Сумма с НДС',
		dependencies: ['quantity', 'price'],
		noStyle: true,
		rules: [
			{ required: true },
			({ getFieldValue, setFieldValue }) => ({
				validator({ field }, value: string) {
					const quantity = getFieldValue(field.split('.').toSpliced(-1, 1, 'quantity'))
					setFieldValue('price', +value / +quantity)
					return Promise.resolve()
				},
			}),
		],
	},
	{
		dataIndex: 'prodDate',
		title: 'Дата производства',
		rules: [{ required: true }],
		noStyle: true,
		component: <DatePicker />,
	},
	{
		dataIndex: 'expirationDate',
		title: 'Срок годности',
		noStyle: true,
		rules: [{ required: true }],
	},
	{
		dataIndex: 'expirationDate',
		title: 'Срок годности',
		rules: [{ required: true }],
		noStyle: true,
		component: <Select options={[{ value: 'сут' }, { value: 'мес' }]} />,
	},
	{
		dataIndex: 'partyNum',
		title: 'Помещение хранения',
		noStyle: true,
		component: <Select options={[{ value: 'Склад' }, { value: 'РЦ' }]} />,
	},
	{
		dataIndex: 'partyNum',
		noStyle: true,
		title: 'Помещение',
	},
]

const NomenclatureTab = () => {
	const url = '/raw-mat-nomenclatures/new'
	const location = useLocation()
	const form = Form.useFormInstance()
	const nomenclatures = (Form.useWatch('nomenclatures', { form, preserve: true }) ||
		[]) as SupplyNomenclature[]
	const sum = nomenclatures.reduce((total, el) => total + +(el.quantity || 0), 0)
	const getTableHeaderRow = (add: FormListOperation['add']) => (
		<Row>
			<Col>
				<Button onClick={() => add()}>Добавить новую строку</Button>
			</Col>
			<Col>
				<Link to={url} target="_blank">
					<Button htmlType="submit" form={location.pathname} onClick={() => {}}>
						Добавить новую номенклатуру
					</Button>
				</Link>
			</Col>
			<Col style={{ marginLeft: 'auto' }}>
				<Typography.Text strong>Итого вкл. НДС: {sum} руб</Typography.Text>
			</Col>
		</Row>
	)
	return (
		<TabItem>
			<TableForm
				getTableHeaderRow={getTableHeaderRow}
				columns={columns}
				field="nomenclatures"
				defaultValue={{ prodDate: DateTime.now() }}
			/>
		</TabItem>
	)
}

export default NomenclatureTab
