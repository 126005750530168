import React from 'react'
import { createBrowserRouter, Link } from 'react-router-dom'
import App from '../App'
import DictionaryList from '../pages/dictionaries/DictionaryList'
import Index from '../pages/Index'
import ErrorPage from '../ErrorPage'
import * as Staff from '../pages/staff'
import * as Supply from '../pages/supply'
import * as TechCard from '../pages/tech-card'
import * as RawMat from '../pages/stocks/raw-mat'
import * as RawMatNomenclature from '../pages/raw-mat-nomenclature'
import * as TmcManufacturer from '../pages/dictionaries/tmc-manufacturer'
import * as Manufacturer from '../pages/dictionaries/manufacturer'
import * as RawMatType from '../pages/dictionaries/raw-mat-type'
import * as WorkCenter from '../pages/dictionaries/work-center'
import * as RoleType from '../pages/dictionaries/role-type/'
import * as RawMatGroup from '../pages/dictionaries/raw-mat-group'
import * as TmcGroup from '../pages/dictionaries/tmc-group'
import * as Equipment from '../pages/dictionaries/equipment'
import * as EquipmentType from '../pages/dictionaries/equipment-type'
import * as MaterialType from '../pages/dictionaries/material-type'
import * as StaffStatus from '../pages/dictionaries/staff-status'
import * as TmcCategory from '../pages/dictionaries/tmc-category'
import * as ShipmentWave from '../pages/system-settings/shipment-wave'
import * as RawMatCategory from '../pages/dictionaries/raw-mat-category'
import * as Measure from '../pages/dictionaries/measure'
import * as NdsRate from '../pages/dictionaries/nds-rate'
import * as OwnBrand from '../pages/dictionaries/own-brand'
import * as Organization from '../pages/dictionaries/organization'
import * as ProductPackageType from '../pages/dictionaries/product-package-type'
import * as ReadyMealsType from '../pages/dictionaries/ready-meals-type'
import * as ProductGroup from '../pages/dictionaries/product-group'
import * as ProcessType from '../pages/dictionaries/process-type'
import * as Warehouse from '../pages/dictionaries/warehouse'
import { ChangePassword, ForgotPassword, SignIn } from '../pages/auth'
import { TechCard as TechCardEntity } from '@isf/backend/src/modules/tech-card/entities/tech-card.entity'
import { Staff as StaffEntity } from '@isf/backend/src/modules/staff/entities/staff.entity'
import { Supply as SupplyEntity } from '@isf/backend/src/modules/supply/entities/supply.entity'
import { RawMat as RawMatEntity } from '@isf/backend/src/modules/stocks/modules/raw-mat/entities/raw-mat.entity'
import { RoleType as RoleTypeEntity } from '@isf/backend/src/modules/dictionaries/modules/role-type/entities/role-type.entity'
import { RawMatNomenclature as RawMatNomenclatureEntity } from '@isf/backend/src/modules/raw-mat-nomenclature/entities/raw-mat-nomenclature.entity'
import { queryClient } from '../query-client'

const routes = [
	{
		path: '/',
		element: <App />,
		errorElement: <ErrorPage />,
		handle: {
			crumb: () => <Link to="/">Главная</Link>,
		},
		children: [
			{ index: true, element: <Index />, path: '/stocks' },
			{
				element: <Index />,
				handle: {
					crumb: () => 'Настройки системы',
				},
				children: [
					{
						path: '/system-settings/shipment-waves',
						element: <ShipmentWave.Page />,
						loader: ShipmentWave.loader(queryClient),
						action: ShipmentWave.action(queryClient),
						handle: {
							crumb: () => 'Волны отгрузки',
						},
					},
				],
			},
			{
				element: <Index />,
				path: '/stocks',
				handle: {
					crumb: () => <Link to="/stocks">Запасы</Link>,
				},
				children: [
					{
						index: true,
						loader: RawMat.listLoader(queryClient),
						path: '/stocks/raw-mats',
						element: <RawMat.RawMatList />,
						handle: {
							crumb: () => 'Сырье',
						},
					},
					{
						path: '/stocks/raw-mats',
						handle: {
							crumb: () => <Link to="/stocks/raw-mats">Сырье</Link>,
						},
						children: [
							{
								path: '/stocks/raw-mats/:id',
								element: <RawMat.RawMat />,
								loader: RawMat.loader(queryClient),
								action: RawMat.action(queryClient),
								handle: {
									crumb: (rawMat: RawMatEntity) => rawMat.id || 'Новый',
								},
							},
						],
					},
				],
			},
			{
				path: '/stock-operations',
				handle: {
					crumb: () => 'Операции с запасами',
				},
				children: [
					{
						path: '/stock-operations/incoming',
						handle: {
							crumb: () => 'Поступление',
						},
						children: [
							{
								index: true,
								element: <Supply.SupplyList />,
								path: '/stock-operations/incoming/supplies',
								loader: Supply.listLoader(queryClient),
								handle: {
									crumb: () => 'Поставки',
								},
							},
							{
								handle: {
									crumb: () => <Link to="/stock-operations/incoming/supplies">Поставки</Link>,
								},
								children: [
									{
										path: '/stock-operations/incoming/supplies/:id',
										element: <Supply.Supply />,
										loader: Supply.loader(queryClient),
										action: Supply.action(queryClient),
										handle: {
											crumb: (supply: SupplyEntity) => supply.id || 'Новая',
										},
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'staff',
				handle: {
					crumb: () => <Link to="/staff">Персонал</Link>,
				},
				children: [
					{
						index: true,
						element: <Staff.StaffList />,
						loader: Staff.listLoader(queryClient),
					},
					{
						path: '/staff/:id',
						element: <Staff.Staff />,
						loader: Staff.loader(queryClient),
						action: Staff.action(queryClient),
						handle: {
							crumb: (staff: StaffEntity) => staff?.surname || 'Новый сотрудник',
						},
					},
				],
			},
			{
				path: 'tech-cards',
				handle: {
					crumb: () => <Link to="/tech-cards">Тех. карты</Link>,
				},
				children: [
					{
						index: true,
						element: <TechCard.TechCardList />,
						loader: TechCard.listLoader(queryClient),
					},
					{
						path: ':id',
						element: <TechCard.TechCard />,
						loader: TechCard.loader(queryClient),
						action: TechCard.action(queryClient),
						handle: {
							crumb: ({ data }: { data: TechCardEntity }) =>
								data?.latestVersion?.recipeNumber || 'Новая',
						},
						children: [
							{
								path: 'tech-cards',
								element: <TechCard.TechCardModalList fieldName="compositions" />,
								loader: TechCard.listLoader(queryClient),
							},
							{
								element: <RawMatType.RawMatTypeModalList fieldName="compositions" />,
								path: 'dictionaries/raw-mat-types',
								loader: RawMatType.listLoader(queryClient),
							},
						],
					},
				],
			},
			{
				element: <Index />,
				path: '/nomenclatures',
				handle: {
					crumb: () => <Link to="/nomenclatures">Номенклатура</Link>,
				},
				children: [
					{
						index: true,
						element: <RawMatNomenclature.RawMatNomenclatureList />,
						path: '/nomenclatures/raw-mat-nomenclatures',
						loader: RawMatNomenclature.listLoader(queryClient),
						handle: {
							crumb: () => 'Номенклатура сырья',
						},
					},
					{
						path: '/nomenclatures/raw-mat-nomenclatures',
						handle: {
							crumb: () => (
								<Link to="/nomenclatures/raw-mat-nomenclatures">Номенклатура сырья</Link>
							),
						},
						children: [
							{
								path: '/nomenclatures/raw-mat-nomenclatures/:id',
								element: <RawMatNomenclature.RawMatNomenclature />,
								loader: RawMatNomenclature.loader(queryClient),
								action: RawMatNomenclature.action(queryClient),
								handle: {
									crumb: (nomenclature: RawMatNomenclatureEntity) =>
										nomenclature.id ? nomenclature.articul : 'Новая',
								},
							},
						],
					},
				],
			},
			{
				path: 'dictionaries',
				handle: {
					crumb: () => <Link to="/dictionaries">Справочники</Link>,
				},
				children: [
					{ index: true, element: <DictionaryList /> },
					{
						path: '/dictionaries/tmc-manufacturers',
						handle: {
							crumb: () => (
								<Link to="/dictionaries/tmc-manufacturers">Производители и бренды ТМЦ</Link>
							),
						},
						children: [
							{
								index: true,
								element: <TmcManufacturer.TmcManufacturersList />,
								loader: TmcManufacturer.listLoader(queryClient),
							},
							{
								path: '/dictionaries/tmc-manufacturers/:id',
								element: <TmcManufacturer.TmcManufacturer />,
								loader: TmcManufacturer.loader(queryClient),
								action: TmcManufacturer.action(queryClient),
								handle: {
									crumb: (manuf: any) => manuf?.label || 'Новый',
								},
							},
						],
					},
					{
						path: '/dictionaries/raw-mat-types',
						handle: {
							crumb: () => <Link to="/dictionaries/raw-mat-types">Виды сырья</Link>,
						},
						children: [
							{
								index: true,
								element: <RawMatType.RawMatTypeList />,
								loader: RawMatType.listLoader(queryClient),
							},
							{
								path: '/dictionaries/raw-mat-types/:id',
								element: <RawMatType.RawMatType />,
								loader: RawMatType.loader(queryClient),
								action: RawMatType.action(queryClient),
								handle: {
									crumb: (rmTypes: any) => rmTypes?.label || 'Новый',
								},
							},
						],
					},

					{
						path: '/dictionaries/work-centers',
						handle: {
							crumb: () => <Link to="/dictionaries/work-centers">Рабочие центры</Link>,
						},
						children: [
							{
								index: true,
								element: <WorkCenter.WorkCenterList />,
								loader: WorkCenter.listLoader(queryClient),
							},
							{
								path: '/dictionaries/work-centers/:id',
								element: <WorkCenter.WorkCenter />,
								loader: WorkCenter.loader(queryClient),
								action: WorkCenter.action(queryClient),
								handle: {
									crumb: (workCenter: { label: string }) => workCenter?.label || 'Новый',
								},
							},
						],
					},
					{
						path: '/dictionaries/nds-rates',
						element: <NdsRate.Page />,
						loader: NdsRate.loader(queryClient),
						action: NdsRate.action(queryClient),
						handle: {
							crumb: () => 'Ставки НДС',
						},
					},
					{
						path: '/dictionaries/equipment',
						element: <Equipment.Page />,
						loader: Equipment.loader(queryClient),
						action: Equipment.action(queryClient),
						handle: {
							crumb: () => 'Оборудование',
						},
					},
					{
						path: '/dictionaries/equipment-types',
						element: <EquipmentType.Page />,
						loader: EquipmentType.loader(queryClient),
						action: EquipmentType.action(queryClient),
						handle: {
							crumb: () => 'Тип / Модель оборудования',
						},
					},
					{
						path: '/dictionaries/process-types',
						element: <ProcessType.Page />,
						loader: ProcessType.loader(queryClient),
						action: ProcessType.action(queryClient),
						handle: {
							crumb: () => 'Типы обработки',
						},
					},
					{
						path: '/dictionaries/own-brands',
						element: <OwnBrand.Page />,
						loader: OwnBrand.loader(queryClient),
						action: OwnBrand.action(queryClient),
						handle: {
							crumb: () => 'Собственные торговые марки',
						},
					},
					{
						path: '/dictionaries/manufacturers',
						handle: {
							crumb: () => (
								<Link to="/dictionaries/manufacturers">Производители и бренды сырья</Link>
							),
						},
						children: [
							{
								index: true,
								element: <Manufacturer.ManufacturersList />,
								loader: Manufacturer.listLoader(queryClient),
							},
							{
								path: '/dictionaries/manufacturers/:id',
								element: <Manufacturer.Manufacturer />,
								loader: Manufacturer.loader(queryClient),
								action: Manufacturer.action(queryClient),
								handle: {
									crumb: (manuf: any) => manuf?.label || 'Новый',
								},
							},
						],
					},
					{
						path: '/dictionaries/warehouses',
						handle: {
							crumb: () => <Link to="/dictionaries/warehouses">Склады</Link>,
						},
						children: [
							{
								index: true,
								element: <Warehouse.WarehouseList />,
								loader: Warehouse.listLoader(queryClient),
							},
							{
								path: '/dictionaries/warehouses/:id',
								element: <Warehouse.Warehouse />,
								loader: Warehouse.loader(queryClient),
								action: Warehouse.action(queryClient),
								handle: {
									crumb: (warehouse: any) => warehouse?.label || 'Новый',
								},
							},
						],
					},
					{
						path: '/dictionaries/organizations',
						element: <Organization.Page />,
						loader: Organization.loader(queryClient),
						action: Organization.action(queryClient),
						handle: {
							crumb: () => 'Организации',
						},
					},
					{
						path: '/dictionaries/product-package-types',
						element: <ProductPackageType.Page />,
						loader: ProductPackageType.loader(queryClient),
						action: ProductPackageType.action(queryClient),
						handle: {
							crumb: () => 'Типы упаковки продукции',
						},
					},
					{
						path: '/dictionaries/ready-meals-types',
						element: <ReadyMealsType.Page />,
						loader: ReadyMealsType.loader(queryClient),
						action: ReadyMealsType.action(queryClient),
						handle: {
							crumb: () => 'Типы готовых блюд',
						},
					},
					{
						path: '/dictionaries/product-groups',
						element: <ProductGroup.Page />,
						loader: ProductGroup.loader(queryClient),
						action: ProductGroup.action(queryClient),
						handle: {
							crumb: () => 'Группы продуктов',
						},
					},

					{
						path: '/dictionaries/tmc-categories',
						element: <TmcCategory.Page />,
						loader: TmcCategory.loader(queryClient),
						action: TmcCategory.action(queryClient),
						handle: {
							crumb: () => 'Категории ТМЦ',
						},
					},
					{
						path: '/dictionaries/raw-mat-categories',
						element: <RawMatCategory.Page />,
						loader: RawMatCategory.loader(queryClient),
						action: RawMatCategory.action(queryClient),
						handle: {
							crumb: () => 'Категории сырья',
						},
					},
					{
						path: '/dictionaries/tmc-groups',
						element: <TmcGroup.Page />,
						loader: TmcGroup.loader(queryClient),
						action: TmcGroup.action(queryClient),
						handle: {
							crumb: () => 'Группы ТМЦ',
						},
					},
					{
						path: '/dictionaries/material-types',
						element: <MaterialType.Page />,
						loader: MaterialType.loader(queryClient),
						action: MaterialType.action(queryClient),
						handle: {
							crumb: () => 'Виды материалов',
						},
					},
					{
						path: '/dictionaries/measures',
						element: <Measure.Page />,
						loader: Measure.loader(queryClient),
						action: Measure.action(queryClient),
						handle: {
							crumb: () => 'Единицы измерения',
						},
					},
					{
						path: '/dictionaries/staff-statuses',
						element: <StaffStatus.Page />,
						loader: StaffStatus.loader(queryClient),
						action: StaffStatus.action(queryClient),
						handle: {
							crumb: () => 'Статусы персонала',
						},
					},
					{
						path: '/dictionaries/raw-mat-groups',
						element: <RawMatGroup.Page />,
						loader: RawMatGroup.loader(queryClient),
						action: RawMatGroup.action(queryClient),
						handle: {
							crumb: () => 'Группы сырья',
						},
					},
					{
						path: '/dictionaries/role-types',
						handle: {
							crumb: () => <Link to="/dictionaries/role-types">Типы ролей</Link>,
						},
						children: [
							{
								index: true,
								element: <RoleType.RoleTypeList />,
								loader: RoleType.listLoader(queryClient),
							},
							{
								path: '/dictionaries/role-types/:id',
								element: <RoleType.RoleTypeDict />,
								loader: RoleType.loader(queryClient),
								action: RoleType.action(queryClient),
								handle: {
									crumb: (roleType: RoleTypeEntity) => roleType?.label || 'Новая',
								},
							},
						],
					},
				],
			},
		],
	},
	{
		path: '/auth',
		element: <Index />,
		children: [
			{
				path: 'sign-in',
				element: <SignIn />,
			},
			{
				path: 'change-password',
				element: <ChangePassword />,
			},
			{
				path: 'forgot-password',
				element: <ForgotPassword />,
			},
		],
	},
]

export default createBrowserRouter(routes)
