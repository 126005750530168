import { useLoaderData } from 'react-router-dom'
import { formItems } from './constants'
import { CustomFormWithTabs } from '../../../components'
import { useForm } from 'antd/es/form/Form'
import { Manufacturer as ManufacturerType } from '@isf/backend/src/modules/dictionaries/modules/manufacturer/entities/manufacturer.entity'
import { BrandTab } from './tabs'
import { Card, Space } from 'antd'
import Bar from '../../../layout/bar/Bar'

const Manufacturer = () => {
	const data = useLoaderData() as ManufacturerType
	const [form] = useForm()
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar form={form} />
			<Card style={{ margin: '0 16px' }}>
				<CustomFormWithTabs
					form={form}
					colCount={1}
					rowCount={1}
					items={formItems}
					initialValues={data}
					tabs={[{ key: 'brands', children: <BrandTab />, label: 'Бренды' }]}
				/>
			</Card>
		</Space>
	)
}

export default Manufacturer
