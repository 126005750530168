const protocol = window?._env_?.PROTOCOL === 'HTTPS' ? 'https' : 'http'
const localURL = 'http://localhost:49153'

const host =
	process.env.NODE_ENV === 'development'
		? localURL
		: protocol + '://' +
		process.env.REACT_APP_HOST +
		':' +
		process.env.REACT_APP_API_PORT

const reactAppStand = window._env_?.REACT_APP_STAND || process.env.REACT_APP_STAND
const reactAppHost = window._env_?.REACT_APP_HOST || process.env.REACT_APP_HOST

const services = {
	backend:
		process.env.NODE_ENV === 'production'
			? `${protocol}://backend.${reactAppStand}.${reactAppHost}`
			: localURL,
}
export { host, services }
