import React from 'react'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { RawMatNomenclature } from '@isf/backend/src/modules/raw-mat-nomenclature/entities/raw-mat-nomenclature.entity'
import { getColumns, searchFields } from './constants'
import ListTemplate from '../ListTemplate'
import { Button, Card, Layout, Space, Typography } from 'antd'
import { SelectFetch } from '../../components'
import { useList } from '../../contexts'
import { MultipleTableFilter } from '../../contexts/list/types'
import { ResetFilter } from '../../assets/svg'
import Bar from '../../layout/bar/Bar'
import { useColumnSearchProps } from '../../utils/hooks'

const RawMatNomenclatureList = () => {
	const data = useLoaderData() as RawMatNomenclature[]
	const navigate = useNavigate()
	const location = useLocation()
	const {
		state: { filters },
		setFilters,
		resetFilters,
	} = useList()
	const onRow = (record: RawMatNomenclature) => ({
		onClick: () => navigate(`${location.pathname}/${record.id}`),
	})

	const filteredData = data.filter(
		(el) =>
			!Object.keys(filters).length ||
			Object.keys(filters).some(
				(key) =>
					!(filters as MultipleTableFilter)[key].length ||
					(filters as MultipleTableFilter)[key].some(
						(v) => v.label === el[key]?.['origin'] || v.value === el[key]?.id
					)
			)
	)
	const { getColumnSearchProps } = useColumnSearchProps(filteredData)

	const prefix = (
		<Space size="middle">
			<Typography.Text>Категория</Typography.Text>
			<SelectFetch
				mode="multiple"
				style={{ width: 200 }}
				url={`/dictionaries/raw-mat-categories`}
				value={filters.rawMatCategory}
				onSelect={(value) =>
					setFilters({
						...filters,
						rawMatCategory: [...((filters as MultipleTableFilter).rawMatCategory || []), value],
					})
				}
				onDeselect={({ value }) =>
					setFilters({
						...filters,
						rawMatCategory: (filters as MultipleTableFilter).rawMatCategory.filter(
							(f) => f.value !== value
						),
					})
				}
			/>
			<Typography.Text>Происхождение</Typography.Text>
			<SelectFetch
				mode="multiple"
				style={{ width: 200 }}
				url={`/dictionaries/raw-mat-types`}
				label="origin"
				value={filters.rawMatType}
				onSelect={(value) =>
					setFilters({
						...filters,
						rawMatType: [...((filters as MultipleTableFilter).rawMatType || []), value],
					})
				}
				onDeselect={({ value }) =>
					setFilters({
						...filters,
						rawMatType: ((filters as MultipleTableFilter).rawMatType || []).filter(
							(f) => f.value !== value
						),
					})
				}
			/>
			<Button
				type="text"
				icon={<ResetFilter className="pictogram" style={{ fontSize: 24 }} />}
				onClick={() => resetFilters()}
			/>
		</Space>
	)

	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<ListTemplate
						prefixRow={prefix}
						searchFields={searchFields}
						columns={getColumns(getColumnSearchProps)}
						dataSource={filteredData}
						onRow={onRow}
						noRowButtons
					/>
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default RawMatNomenclatureList
