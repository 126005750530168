import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import { SelectFetch } from '../../../components'
const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['tmcCategoryLabel', 'label']

const TmcGroup = () => {
	const data = useLoaderData()
	const submit = useSubmit()
	const columns: ColumnsType<{
		categoryLabel: string
		group: string
		id: number
	}> = [
		{
			title: 'Категория',
			dataIndex: ['tmcCategory', 'label'],
		},
		{
			title: 'Группа',
			dataIndex: 'group',
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'tmcCategory',
			label: 'Категория',
			required: true,
			rules: [{ required: true }],
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
				id: value,
				label,
			}),
			component: <SelectFetch url="dictionaries/tmc-categories" />,
			...layout,
		},
		{
			name: 'group',
			label: 'Группа',
			rules: [{ required: true }],
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default TmcGroup
