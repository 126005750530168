import { Input, Radio, Typography } from 'antd'
import { SelectFetch, StatusCell } from '../../components'
import { SorterTypes, sorter } from '../../utils/helpers'
import { ColumnType } from 'antd/es/table'
import { RawMatNomenclature } from '@isf/backend/src/modules/raw-mat-nomenclature/entities/raw-mat-nomenclature.entity'
import { CompareFn } from 'antd/es/table/interface'

const searchFields = [
	'id',
	['rawMatType', 'label'],
	['manufacturer', 'label'],
	['brand', 'label'],
	'packageAndQuantity',
	'status',
]

const layout = { labelCol: { span: 8 }, wrapperCol: { offset: 0, span: 14 } }
const layout2 = { labelCol: { span: 8 }, wrapperCol: { offset: 0, span: 14 } }
const layout3 = { labelCol: { offset: 0, span: 11 }, wrapperCol: { offset: 0, span: 14 } }

const getFormItems = (data, form, rawMatType) => {
	return [
		{
			name: 'articul',
			label: 'Артикул',
			component: <Typography.Text>{data.articul}</Typography.Text>,
			...layout,
		},
		{
			name: 'rawMatType',
			label: 'Вид',
			required: true,
			rules: [{ required: true }],
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => {
				return {
					id: value,
					label,
				}
			},
			component: (
				<SelectFetch
					url="dictionaries/raw-mat-types"
					field="rawMatType"
					onChange={(_, { data: rawMatType }) =>
						form.setFieldValue('rawMatCategory', rawMatType.rawMatCategory)
					}
				/>
			),
			...layout,
		},
		{
			name: 'rawMatCategory',
			label: 'Категория',
			required: true,
			rules: [{ required: true }],
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => {
				return {
					id: value,
					label,
				}
			},
			component: (
				<SelectFetch
					url="dictionaries/raw-mat-categories"
					onChange={() => form.setFieldValue('rawMatType', undefined)}
				/>
			),
			...layout,
		},
		{
			key: 'first-col-gap',
			...layout,
			component: null,
		},
		{
			name: 'packageAndQuantity',
			required: true,
			rules: [{ required: true }],
			label: 'Упаковка + Кол-во',
			...layout2,
		},
		{
			name: 'manufacturer',
			label: 'Производитель',
			rules: [{ required: true }],
			required: true,
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => {
				return {
					id: value,
					label,
				}
			},
			component: (
				<SelectFetch
					url={`dictionaries/manufacturers`}
					onChange={() => form.setFieldValue('brand', undefined)}
				/>
			),
			...layout2,
		},
		{
			name: 'brand',
			label: 'Бренд',
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => {
				return {
					id: value,
					label,
				}
			},
			component: (
				<SelectFetch
					url={
						data.manufacturer?.id
							? `dictionaries/manufacturers/brands/${data.manufacturer.id}`
							: `dictionaries/manufacturers/brands`
					}
					field="brand"
					onChange={(_, { data: brand }) => form.setFieldValue('manufacturer', brand.manufacturer)}
				/>
			),
			...layout2,
		},
		{
			key: 'second-col-gap',
			...layout2,
			component: null,
		},
		{
			name: 'status',
			label: 'Статус',
			required: true,
			component: <Typography.Text>{data.status ?? 'Новая'}</Typography.Text>,
			...layout3,
			readOnly: true,
		},
		{
			name: 'product',
			label: 'Товар',
			required: true,
			rules: [{ required: true }],
			component: (
				<Radio.Group>
					<Radio value="Весовой">Весовой</Radio>
					<Radio value="Штучный">Штучный</Radio>
				</Radio.Group>
			),
			...layout3,
		},
		{
			name: 'mainProdMeasure',
			label: 'Выход основного продукта ЕИ',
			required: true,
			rules: [{ required: true }],
			component: <Input type="number" addonAfter={rawMatType?.measure?.labelShort} />,
			...layout3,
		},
		{
			label: 'Происхождение',
			component: <Typography.Text>{rawMatType?.origin}</Typography.Text>,
			...layout3,
		},
	]
}

const getColumns: (getColumnSearchProps) => ColumnType<RawMatNomenclature>[] = (
	getColumnSearchProps
) => [
	{
		title: 'Артикул',
		dataIndex: 'articul',
		sorter: sorter(SorterTypes.string, 'articul') as CompareFn<RawMatNomenclature>,
		...getColumnSearchProps('articul'),
	},
	{
		title: 'Вид',
		dataIndex: ['rawMatType', 'label'],
		sorter: sorter(SorterTypes.string, ['rawMatType', 'label']) as CompareFn<RawMatNomenclature>,
		...getColumnSearchProps(['rawMatType', 'label']),
	},
	{
		title: 'Производитель',
		dataIndex: ['manufacturer', 'label'],
		...getColumnSearchProps(['manufacturer', 'label']),
	},
	{
		title: 'Бренд',
		dataIndex: ['brand', 'label'],
		...getColumnSearchProps(['brand', 'label']),
	},

	{
		title: 'Упаковка + Количество',
		dataIndex: 'packageAndQuantity',
		...getColumnSearchProps('packageAndQuantity'),
	},

	{
		title: 'Статус',
		dataIndex: 'status',
		...getColumnSearchProps('status'),
		render: (status: string) => <StatusCell status={status} />,
	},
]

export { getFormItems, getColumns, searchFields }
