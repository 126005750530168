const cardBody = {
	height: 64,
	display: 'flex',
	alignItems: 'center',
}

export const withoutUserMenu = {
	padding: '10px 20px',
	...cardBody,
}

export const withUserMenu = {
	padding: '0 20px 0 0',
	...cardBody,
	backgroundColor: '#001529',
}
