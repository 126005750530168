import * as React from 'react'
import type { PickerTimeProps } from 'antd/es/date-picker/generatePicker'
import type { DateTime } from 'luxon'

import DatePicker from '../date-picker/DatePicker'

export interface TimePickerProps extends Omit<PickerTimeProps<DateTime>, 'picker'> {}

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
	<DatePicker format="HH:mm" {...props} picker="time" ref={ref} />
))

TimePicker.displayName = 'TimePicker'

export default TimePicker
