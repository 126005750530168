import { useActionData, useLoaderData, useLocation, useSubmit } from 'react-router-dom'
import { Equipment as EquipmentEntity } from '@isf/backend/src/modules/dictionaries/modules/equipment/entities/equipment.entity'
import React, { useEffect, useRef, useState } from 'react'
import { CustomForm, TableRowButtons } from '../../../components'
import { Card, Form, Layout, Modal, Space, Table, Typography } from 'antd'
import { getColumns, getItems } from './constants'
import { modes } from '../../../utils/constants'
import { useList, useMode } from '../../../contexts'
import Bar from '../../../layout/bar/Bar'
import { applyFilterForList } from '../../../utils/helpers/for-provider'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useColumnSearchProps } from '../../../utils/hooks'

const searchFields = [
	'id',
	{ equipmentType: ['model'] },
	{ equipmentType: ['type'] },
	{ workCenter: ['label'] },
	'status',
]

const initialValues = { status: 'В работе', equipmentType: undefined, workCenter: undefined }

const Equipment = () => {
	const data = useLoaderData() as EquipmentEntity[]
	const submit = useSubmit()
	const location = useLocation()
	const [form] = Form.useForm()

	const {
		state: { mode },
		setMode,
	} = useMode()

	const {
		state: { textFilter },
	} = useList()

	const [record, setRecord] = useState<EquipmentEntity | null>(null)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const equipmentType = Form.useWatch('equipmentType', { form, preserve: true })

	const actionData = useActionData()

	const modalRef = useRef<{
		destroy: () => void
		update: (configUpdate) => void
	} | null>(null)

	useEffect(() => {
		if (mode === modes.create) {
			form.setFieldsValue(initialValues)
			setIsModalOpen(true)
		}
		if (mode === modes.view) {
			setIsModalOpen(false)
		}
	}, [form, mode])

	const onEdit = (e: EquipmentEntity) => {
		setRecord(e)
		form.setFieldsValue(e)
		setMode(modes.edit)
		setIsModalOpen(true)
	}

	useEffect(() => {
		if (actionData?.err?.isReadable) {
			if (!modalRef.current) {
				const {
					err: { error, url, urlText },
				} = actionData
				const content = (
					<>
						<Typography.Text>{error}</Typography.Text>
						{urlText && (
							<>
								<br />
								<Typography.Link href={url} target="_blank">
									{urlText}
								</Typography.Link>
							</>
						)}
					</>
				)
				const modal = Modal.confirm({
					content,
					centered: true,
					icon: <ExclamationCircleOutlined />,
					cancelButtonProps: { style: { display: 'none' } },
					onOk: (close) => {
						modalRef.current = null
						close()
					},
				})
				modalRef.current = modal
			}
		}
	}, [actionData])

	const filteredData = applyFilterForList(data, {
		searchFields,
		search: textFilter,
	})

	const { getColumnSearchProps } = useColumnSearchProps(filteredData)

	const onDelete = (e: EquipmentEntity) => {
		Modal.confirm({
			icon: <ExclamationCircleOutlined />,
			centered: true,
			width: '40%',
			maskClosable: false,
			content: 'Вы действительно хотите удалить этот элемент?',
			onOk: (close) => {
				if (e.id) {
					submit(
						{ id: e.id },
						{
							method: 'delete',
							action: location.pathname,
							encType: 'application/json',
						}
					)
					close()
				}
			},
		})
	}

	const title =
		mode === modes.create
			? 'Создание нового элемента'
			: mode === modes.edit
			? 'Редактирование элемента'
			: null
	return (
		<>
			<Space direction="vertical">
				<Bar form={form} showSearch />
				<Layout.Content>
					<Card style={{ margin: '0 16px' }} size="small">
						<Table
							dataSource={filteredData}
							columns={[
								...getColumns(getColumnSearchProps),
								{
									title: null,
									width: '5%',
									render: (item) => (
										<TableRowButtons onEdit={onEdit} onDelete={onDelete} record={item} />
									),
								},
							]}
							pagination={{ hideOnSinglePage: true, pageSize: 18 }}
							rowKey="id"
							size="small"
						/>
					</Card>
				</Layout.Content>
			</Space>
			<Modal
				className="modal-style"
				open={isModalOpen}
				onCancel={() => {
					if (form.isFieldsTouched()) {
						return Modal.confirm({
							icon: <ExclamationCircleOutlined />,
							centered: true,
							width: '25%',
							content: 'Выйти без сохранения изменений?',
							okText: 'Выйти',
							onOk: (close) => {
								if (form) {
									form.resetFields()
								}
								close()
								setMode(modes.view)
							},
						})
					}
					setMode(modes.view)
				}}
				afterClose={() => setRecord(null)}
				okButtonProps={{ htmlType: 'submit', form: 'dict' }}
				destroyOnClose
				centered
				width="40%"
				title={title}
				maskClosable={false}
				cancelButtonProps={{ style: { display: 'none' } }}
				styles={{ body: { borderRadius: '2px 2px 0 0', width: '100%' } }}
			>
				<CustomForm
					preserve={false}
					form={form}
					name="dict"
					items={getItems(equipmentType)}
					initialValues={record ?? initialValues}
					onFinish={(values) => {
						submit(
							{ ...values, id: record?.id },
							{
								method: mode === modes.create ? 'post' : 'patch',
								action: location.pathname,
								encType: 'application/json',
							}
						)
						setRecord(null)
						setMode(modes.view)
					}}
				/>
			</Modal>
		</>
	)
}

export default Equipment
