import React from 'react'
import { Layout as LayoutAntd, Card, Row } from 'antd'
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { menuPositions } from '../../contexts'
import { withUserMenu, withoutUserMenu } from './styles'
import Info from './Info'

const { Header: HeaderAntd } = LayoutAntd

const Header = ({ position, collapsed, toggleCollapsed, colorBgContainer }) => {
	const showUserMenu = position === menuPositions.top
	return (
		<HeaderAntd style={{ padding: 0, background: colorBgContainer }}>
			<Card bordered={false} bodyStyle={showUserMenu ? withUserMenu : withoutUserMenu}>
				<Row align="middle" justify="space-between" wrap={false} style={{ width: '100%' }}>
					<Icon style={{ fontSize: 24 }} onClick={toggleCollapsed}>
						{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
					</Icon>
					<Info />
				</Row>
			</Card>
		</HeaderAntd>
	)
}

export default Header
