import { RawMatType } from '@isf/backend/src/modules/dictionaries/modules/raw-mat-type/entities/raw-mat-type.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { type LoaderFunction, type ActionFunction, redirect } from 'react-router-dom'

const url = 'dictionaries/raw-mat-types'
const queryKey = ['dictionaries', 'raw-mat-type']

const staffListQuery = () => ({
	queryKey,
	queryFn: (): Promise<RawMatType[]> => axios.get(url).then((response) => response.data),
})

const staffQuery = (id: RawMatType['id']) => ({
	queryKey: [...queryKey, id],
	queryFn: (): Promise<RawMatType[]> => axios.get(`${url}/${id}`).then((response) => response.data),
})

export const listLoader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = staffListQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const loader: (arg0: QueryClient) => LoaderFunction =
	(queryClient) =>
	async ({ params }) => {
		if (params.id && !isNaN(+params.id)) {
			const query = staffQuery(+params.id)
			return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
		}
		return {}
	}

export const action: (arg0: QueryClient) => ActionFunction =
	(queryClient) =>
	async ({ request, params }) => {
		try {
			const data = await request.json()
			const response = await axios({
				method: request.method,
				url: request.method === 'POST' ? url : `${url}/${params.id}`,
				data,
			})
			let redirectUrl = `/${url}`
			if (request.method === 'DELETE') {
				if (params.id) {
					queryClient.removeQueries({ queryKey: [...queryKey, +params.id], exact: true })
				}
			} else {
				redirectUrl += `/${response.data.id}`
			}
			await queryClient.invalidateQueries({ queryKey, refetchType: 'all' }, { throwOnError: true })
			return redirect(redirectUrl)
		} catch (err) {
			if (err.isReadable) {
				return { err }
			}
			console.log(err)
			return null
		}
	}
