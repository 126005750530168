import React from 'react'
import { TabItem, TableForm } from '../../../../components'
import { getColumnSearchProps, SorterTypes, sorter } from '../../../../utils/helpers'
import { Form } from 'antd'

const getColumns = (data: object[] = []) => [
	{
		title: 'Табельный номер',
		dataIndex: 'id',
		sorter: sorter(SorterTypes.number, 'id'),
	},
	{
		title: 'ФИО',
		dataIndex: 'name',
		...getColumnSearchProps(data, 'name'),
	},
	{
		title: 'Должность',
		...getColumnSearchProps(data, 'position'),
		dataIndex: 'position',
	},
	{
		title: 'Роль',
		...getColumnSearchProps(data, 'role'),
		dataIndex: 'role',
	},
	{
		title: 'Статус',
		dataIndex: 'status',
		...getColumnSearchProps(data, 'status'),
	},
]

const StaffTab = () => {
	const form = Form.useFormInstance()

	const staff = (Form.useWatch('staff', { form, preserve: true }) || []) as []
	return (
		<TabItem>
			<TableForm columns={getColumns(staff)} field="staff" canEdit={false} form={form} />
		</TabItem>
	)
}

export default StaffTab
