import React, { useEffect, useState } from 'react'
import { Modal, Space, Table, TableColumnsType } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMode } from '../contexts/mode/mode'
import { modes } from '../utils/constants'
import { axios } from '../utils/helpers'
import { TableRowButtons } from '../components'
import { useList } from '../contexts'
import { ISearchFields, applyFilterForList } from '../utils/helpers/for-provider'
import { AnyObject } from 'antd/es/_util/type'

const ListTemplate = <T extends AnyObject>({
	prefixRow,
	columns,
	dataSource,
	onRow = () => ({}),
	noRowButtons,
	searchFields = [],
}: {
	prefixRow?: React.JSX.Element
	columns: TableColumnsType<T>
	dataSource: T[]
	onRow?: (arg: T) => {}
	noRowButtons?: boolean
	searchFields?: ISearchFields<T>
}) => {
	const {
		setMode,
		state: { mode },
	} = useMode()
	const {
		state: { textFilter, key, page, pageSize },
		setPage,
		setPageSize,
	} = useList()
	const navigate = useNavigate()
	const location = useLocation()
	const [record, setRecord] = useState<T | null>(null)
	const url = [location.pathname, '/', record?.id].join('')
	const filteredData = applyFilterForList(dataSource, {
		searchFields,
		search: textFilter,
	})

	useEffect(() => {
		if (mode === modes.create && !location.state?.modalView) {
			navigate(`${location.pathname}/new`)
		}
	}, [location.pathname, location.state?.modalView, mode, navigate])

	const onEdit = (e: T) => {
		setRecord(e)
		setMode(modes.edit)
	}
	const onDelete = () => {
		Modal.confirm({
			content: 'Вы действительно хотите удалить данный элемент?',
			icon: null,
			onOk: async (close) => {
				await axios['delete'](url)
				close()
			},
		})
	}
	return (
		<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
			{prefixRow && prefixRow}
			<Table
				key={key}
				dataSource={filteredData}
				columns={[
					...columns,
					...(noRowButtons
						? []
						: [
								{
									title: null,
									width: '5%',
									render: (item: T) => (
										<TableRowButtons onEdit={onEdit} onDelete={onDelete} record={item} />
									),
								},
						  ]),
				]}
				style={{ cursor: 'pointer' }}
				pagination={{
					hideOnSinglePage: true,
					onChange: (page, pageSize) => {
						setPage(page)
						setPageSize(pageSize)
					},
					current: page,
					pageSize,
				}}
				rowKey="id"
				size="small"
				onRow={onRow}
			/>
		</Space>
	)
}

export default ListTemplate
