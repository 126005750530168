import React, { useState } from 'react'
import { Form, Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import { axios } from '../../utils/helpers'
import { NamePath } from 'antd/es/form/interface'
import lodash from 'lodash'

interface ISelectFetch extends SelectProps {
	url: string
	label?: string | string[]
	field?: NamePath
}

const SelectFetch: (arg: ISelectFetch) => React.JSX.Element = ({
	url,
	label: customLabel,
	field,
	...props
}) => {
	const [fetching, setFetching] = useState(false)
	const [options, setOptions] = useState<SelectProps['options']>([])
	const form = Form.useFormInstance()
	const fetchOptions = async (open: SelectProps['open']) => {
		if (!open) {
			return setOptions([])
		}
		try {
			setFetching(true)
			const result = await axios(url).then((res) => res.data)
			if (result) {
				setOptions(
					(Array.isArray(result) ? result : [result])
						.map((el: { id: number; label: string }) => ({
							value: el.id,
							data: el,
							label: customLabel ? lodash.get(el, customLabel) : el.label,
						}))
						.filter((el, index, self) => self.findIndex((e) => e.label === el.label) === index)
				)
			}
		} catch (err) {
			console.log(err)
		} finally {
			setFetching(false)
		}
	}

	return (
		<Select
			labelInValue
			notFoundContent={fetching ? <Spin size="small" /> : 'Не найдено'}
			onDropdownVisibleChange={fetchOptions}
			options={options}
			style={{ width: '100%' }}
			showSearch
			filterOption={(input, option) =>
				(String(option?.label) ?? '').toLowerCase().includes(input.toLowerCase())
			}
			onSelect={(_, option) => {
				if (field) {
					form.setFieldValue(field, option.data)
				}
			}}
			{...props}
		/>
	)
}

export default SelectFetch
