import { Organization } from '@isf/backend/src/modules/dictionaries/modules/organization/entities/organization.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { LoaderFunction } from 'react-router-dom'
import { getAction } from '../dictionary-utils'

const url = 'dictionaries/organizations'
const queryKey = ['dictionaries', 'organizations']

export const organizationsQuery = () => ({
	queryKey,
	queryFn: async (): Promise<Organization[]> => {
		const response = await axios.get(url)
		return response.data
	},
})

export const loader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = organizationsQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const action = getAction(url, queryKey)
