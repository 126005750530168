import React, { useEffect, useReducer } from 'react'
import { UserDataAction, UserDataContextType, UserDataProviderProps, UserDataState } from './types'
import { useAuth } from '../auth/auth'
import { axios } from '../../utils/helpers'

const userDataReducer = (state: UserDataState, action: UserDataAction) => {
	switch (action.type) {
		case 'startLoading':
			return { ...state, loading: true }
		case 'endLoading':
			return { ...state, loading: false }
		case 'setUserData':
			return { ...state, loading: false, init: true, rights: action.payload.rights }
		default: {
			throw new Error(`Неизвестный action type: ${action.type}`)
		}
	}
}

const url = '/auth/user-data'

const initialState: UserDataState = { loading: false, rights: {}, init: false }

const UserDataContext = React.createContext<UserDataContextType>({ state: initialState })
UserDataContext.displayName = 'UserDataContext'

const UserDataProvider: React.FC<UserDataProviderProps> = ({ children }) => {
	const {
		state: { authenticated },
	} = useAuth()
	const [state, dispatch] = useReducer(userDataReducer, initialState)

	useEffect(() => {
		const getUserData = async () => {
			try {
				dispatch({ type: 'startLoading' })
				const { data } = await axios(url)
				dispatch({ type: 'setUserData', payload: data })
			} catch (err) {
			} finally {
				dispatch({ type: 'endLoading' })
			}
		}
		if (authenticated) {
			getUserData()
		}
	}, [authenticated])

	const value = {
		state,
	}
	return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>
}

const useUserData = () => {
	const context = React.useContext(UserDataContext)
	if (context === undefined) {
		throw new Error('UserDataProvider для useUserData не найден')
	}
	return context
}

export { UserDataProvider, useUserData }
