import { ActionFunction } from 'react-router-dom'
import { axios } from '../../utils/helpers'
import { QueryClient } from '@tanstack/react-query'

export const getAction: (
	url: string,
	queryKey: string[]
) => (arg0: QueryClient) => ActionFunction =
	(url, queryKey) =>
	(queryClient) =>
	async ({ request }) => {
		try {
			const updates = await request.json()
			await axios({
				method: request.method,
				url: request.method === 'POST' ? url : `${url}/${updates.id}`,
				data: updates,
			})
			await queryClient.invalidateQueries({ queryKey, refetchType: 'all' }, { throwOnError: true })
			return null
		} catch (err) {
			if (err.isReadable) {
				return { err }
			}
			console.log(err)
			return null
		}
	}
