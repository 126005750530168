import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { type LoaderFunction, type ActionFunction, redirect } from 'react-router-dom'
import { WorkCenter } from '@isf/backend/src/modules/dictionaries/modules/work-center/entities/work-center.entity'

const url = '/dictionaries/work-centers'
const queryKey = ['dictionaries', 'work-centers']

const workCenterListQuery = () => ({
	queryKey,
	queryFn: (): Promise<WorkCenter[]> => axios.get(url).then((response) => response.data),
})

const workCenterQuery = (id: WorkCenter['id']) => ({
	queryKey: [...queryKey, id],
	queryFn: (): Promise<WorkCenter[]> => axios.get(`${url}/${id}`).then((response) => response.data),
})

export const listLoader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = workCenterListQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const loader: (arg0: QueryClient) => LoaderFunction =
	(queryClient) =>
	async ({ params }): Promise<WorkCenter> => {
		if (params.id && !isNaN(+params.id)) {
			const query = workCenterQuery(+params.id)
			return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
		}
		return {
			slidingOrFixShifts: 'Фиксированные',
			fixShifts: [],
			slidingShifts: [],
			staff: [],
			equipment: [],
		}
	}

export const action: (arg0: QueryClient) => ActionFunction =
	(queryClient) =>
	async ({ request, params }) => {
		try {
			const values = await request.json()
			const response = await axios({
				method: request.method,
				url: request.method === 'POST' ? url : `${url}/${params.id}`,
				...(values && { data: values }),
			})
			let redirectUrl = url
			if (request.method === 'DELETE') {
				if (params.id) {
					queryClient.removeQueries({ queryKey: [...queryKey, +params.id] })
				}
			} else {
				redirectUrl += `/${response.data.id}`
			}
			await queryClient.invalidateQueries({ queryKey, refetchType: 'all' }, { throwOnError: true })
			return redirect(redirectUrl)
		} catch (err) {
			if (err.isReadable) {
				return { err }
			}
			console.log(err)
			return null
		}
	}
