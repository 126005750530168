import { Checkbox, Typography } from 'antd'
import lodash from 'lodash'

const Row = ({ data, index, selectedKeys, setSelectedKeys }) => {
	const onChange = (e, item) => {
		let newKeys = lodash.cloneDeep(selectedKeys)
		if (e.target.checked) {
			newKeys.push(item.value)
		} else {
			lodash.remove(newKeys, (e) => {
				return e === item?.value
			})
		}
		setSelectedKeys(newKeys)
	}
	return (
		<>
			<Checkbox
				style={{ marginLeft: 0 }}
				value={data[index].value}
				checked={selectedKeys.findIndex((e) => e === data[index].value) !== -1}
				onChange={(e) => onChange(e, data[index])}
			>
				{data[index].text}
			</Checkbox>
			<Typography.Text
				style={{ maxWidth: '100%' }}
				ellipsis={{ tooltip: !!data[index].text.props?.children }}
			></Typography.Text>
		</>
	)
}

export default Row
