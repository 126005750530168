import { Select } from 'antd'
import { SelectFetch } from '../../../components'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import { ColumnsType, CompareFn } from 'antd/es/table/interface'
import { Equipment } from '@isf/backend/src/modules/dictionaries/modules/equipment/entities/equipment.entity'
import { SorterTypes, sorter } from '../../../utils/helpers'
import { EquipmentType } from '@isf/backend/src/modules/dictionaries/modules/equipment-type/entities/equipment-type.entity'

const layout = { labelCol: { span: 8 } }

const getColumns: (getColumnSearchProps) => ColumnsType<Equipment> = (getColumnSearchProps) => [
	{
		title: 'Номер',
		dataIndex: 'id',
		sorter: sorter(SorterTypes.number, 'id') as CompareFn<Equipment>,
	},
	{
		title: 'Тип оборудования',
		dataIndex: ['equipmentType', 'type'],
		sorter: sorter(SorterTypes.string, ['equipmentType', 'type']) as CompareFn<Equipment>,
		...getColumnSearchProps(['equipmentType', 'type']),
	},
	{
		title: 'Модель оборудования',
		dataIndex: ['equipmentType', 'model'],
		sorter: sorter(SorterTypes.string, ['equipmentType', 'model']) as CompareFn<Equipment>,
		...getColumnSearchProps(['equipmentType', 'model']),
	},
	{
		title: 'Рабочий центр',
		dataIndex: ['workCenter', 'label'],
		sorter: sorter(SorterTypes.string, 'workCenterLabel') as CompareFn<Equipment>,
		...getColumnSearchProps(['workCenter', 'label']),
	},
	{
		title: 'Статус',
		dataIndex: 'status',
		sorter: sorter(SorterTypes.string, 'status') as CompareFn<Equipment>,
		...getColumnSearchProps('status'),
	},
]
const getItems: (equipmentType: EquipmentType | undefined) => CustomFormItemProps[] = (
	equipmentType
) => [
	{
		label: 'Номер',
		disabled: true,
		name: 'id',
		...layout,
	},
	{
		name: ['equipmentType'],
		valuePropName: 'selected',
		label: 'Тип оборудования',
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			type: label,
		}),
		component: (
			<SelectFetch
				value={equipmentType?.type}
				url={`dictionaries/equipment-types?sort=type:ASC`}
				label="type"
			/>
		),
		required: true,
		rules: [
			{ required: true },
			({ setFieldValue }) => ({
				validator() {
					setFieldValue(['equipmentType', 'model'], undefined)
					return Promise.resolve()
				},
			}),
		],
		...layout,
	},
	{
		valuePropName: 'selected',
		name: ['equipmentType'],
		label: 'Модель оборудования',
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			type: equipmentType?.type,
			label: label,
		}),
		component: (
			<SelectFetch
				value={equipmentType?.model}
				url={
					equipmentType
						? `dictionaries/equipment-types?sort=model:ASC&filterBy=equipmentType.type:${encodeURIComponent(
								equipmentType.type
						  )}`
						: `dictionaries/equipment-types?sort=model:ASC`
				}
				label="model"
			/>
		),
		required: true,
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'workCenter',
		label: 'Рабочий центр',
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: <SelectFetch url="dictionaries/work-centers" />,
		required: true,
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'status',
		label: 'Статус',
		rules: [{ required: true }],
		component: (
			<Select notFoundContent={'Не найдено'}>
				{['В работе', 'Недоступно'].map((it: string) => (
					<Select.Option key={it} value={it}>
						{it}
					</Select.Option>
				))}
			</Select>
		),
		...layout,
	},
]

export { getColumns, getItems }
