import React, { createContext, useEffect, useReducer } from 'react'
import { ListAction, ListContextType, ListProviderProps, ListState, TableFilter } from './types'
import { useLocation, useParams } from 'react-router-dom'

const initialState = {
	key: 0,
	filters: {},
	inStock: true,
	textFilter: null,
	page: 1,
	pageSize: 18,
}

const ListContext = createContext<
	| {
			state: ListState
			setPage: ListContextType['setPage']
			setPageSize: ListContextType['setPageSize']
			setInStock: ListContextType['setInStock']
			resetFilters: ListContextType['resetFilters']
			setFilters: ListContextType['setFilters']
			setTextFilter: ListContextType['setTextFilter']
	  }
	| undefined
>(undefined)

ListContext.displayName = 'ListContext'

const listReducer = (state: ListState, action: ListAction): ListState => {
	switch (action.type) {
		case 'reset': {
			return initialState
		}
		case 'resetFilters': {
			return { ...state, filters: {}, key: state.key + 1 }
		}
		case 'setPage': {
			return { ...state, page: action.payload }
		}
		case 'setPageSize': {
			return { ...state, pageSize: action.payload }
		}
		case 'setFilters': {
			return { ...state, filters: action.payload }
		}
		case 'setTextFilter': {
			return { ...state, textFilter: action.payload }
		}
		case 'setInStock': {
			return { ...state, inStock: action.payload }
		}
		default: {
			throw new Error(`Неизвестный action type: ${action.type}`)
		}
	}
}

const Provider = ({ children }: ListProviderProps) => {
	const [state, dispatch] = useReducer(listReducer, initialState)

	const location = useLocation()
	const params = useParams()

	const changedLocation = location.pathname.replace(`/${params.id}`, '')

	const setTextFilter = (value: string | null) =>
		dispatch({ type: 'setTextFilter', payload: value })
	const resetFilters = () => dispatch({ type: 'resetFilters' })
	const setPage = (page: number) => dispatch({ type: 'setPage', payload: page })
	const setPageSize = (pageSize: number) => dispatch({ type: 'setPageSize', payload: pageSize })
	const setFilters = (filters: TableFilter) => dispatch({ type: 'setFilters', payload: filters })
	const setInStock = (value: boolean) => dispatch({ type: 'setInStock', payload: value })

	useEffect(() => {
		setPage(1)
		resetFilters()
		setTextFilter(null)
		setInStock(true)
	}, [changedLocation])

	const value = {
		state,
		setPage,
		setPageSize,
		setFilters,
		resetFilters,
		setInStock,
		setTextFilter,
	}
	return <ListContext.Provider value={value}>{children}</ListContext.Provider>
}

const useList = () => {
	const context = React.useContext(ListContext)
	if (context === undefined) {
		throw new Error('ListProvider для useList не найден')
	}
	return context
}
export { useList, Provider }
