const inputStyle = {
	width: 500,
	height: 50,
	borderRadius: 5,
}

const errInputStyle = {
	...inputStyle,
	border: '1px solid red',
}

export { inputStyle, errInputStyle }
