import React from 'react'
import { Checkbox, FormInstance, Image, Radio, TableColumnsType, Typography } from 'antd'
import { TechCard } from '@isf/backend/src/modules/tech-card/entities/tech-card.entity'
import { MaskedInput, SelectFetch } from '../../components'
import { SorterTypes, sorter, getImgUrl } from '../../utils/helpers'
import { CompareFn } from 'antd/es/table/interface'
import { TechCardComposition } from '@isf/backend/src/modules/tech-card/entities/composition.entity'
import { TechCardManufProd } from '@isf/backend/src/modules/tech-card/entities/manuf-prod.entity'
import { DateTime } from 'luxon'

const url = 'tech-cards'

const searchFields = [
	'type',
	'recipeType',
	'id',
	'manufProd',
	'articul',
	{ techCard: ['recipeType'] },
	(val: TechCardManufProd) => val.manufProd,
	(val: TechCardManufProd) => val.techCard.workCenter?.label,
	(val: TechCardManufProd) => val.techCard.latestVersion.status,
]

const layout1 = {
	labelCol: { span: 10 },
	wrapperCol: { xl: { span: 14, pull: 4 }, xxl: { pull: 2 } },
}
const layout2 = {
	labelCol: { span: 10, offset: 2 },
	wrapperCol: { xl: { span: 10, offset: 2 }, xxl: { span: 8, pull: 2 } },
}
const layout3 = { labelCol: { span: 14 }, wrapperCol: { span: 10 } }

export enum RecipeTypeEnum {
	cooking = 'Приготовление',
	cut = 'Разруб',
	package = 'Упаковка',
}

export enum TechCardVersionStatus {
	draft = 'Черновик',
	notApproved = 'Не утверждена',
	approved = 'Утверждена',
	archive = 'В архиве',
}

const getFormItems = (
	type: string,
	shipmentWave: TechCard['shipmentWave'],
	data: TechCard = {} as TechCard,
	form: FormInstance
) => [
	{
		key: 'file',
		component: data.manufProds[0]?.photos?.length ? (
			<Image.PreviewGroup items={data.manufProds[0]?.photos.map((p) => getImgUrl(p.photoPath))}>
				<Image src={getImgUrl(data.manufProds[0]?.photos?.[0]?.photoPath)} />
			</Image.PreviewGroup>
		) : null,
	},
	{ name: 'parent', hidden: true },
	{ component: null },
	{ component: null },
	...(type === RecipeTypeEnum['package'] ? [{ component: null }] : []),
	{
		label: 'Номер рецептуры',
		name: 'recipeNumber',
		valuePropName: 'children',
		component: <Typography.Text disabled={false} />,
		...layout1,
	},
	{
		label: 'Тип рецептуры',
		name: 'type',
		component: (
			<Radio.Group style={{ display: 'flex' }}>
				<Radio value={RecipeTypeEnum['cooking']}>{RecipeTypeEnum['cooking']}</Radio>
				<Radio value={RecipeTypeEnum['cut']}>{RecipeTypeEnum['cut']}</Radio>
				<Radio value={RecipeTypeEnum['package']}>{RecipeTypeEnum['package']}</Radio>
			</Radio.Group>
		),
		...layout1,
	},
	{
		label: 'Выпуск. продукт',
		name: ['manufProds', 0, 'manufProd'],
		readOnly: true,
		...layout1,
	},
	...(type === RecipeTypeEnum['package']
		? [
				{
					label: 'Группа продуктов',
					name: 'productGroup',
					required: true,
					getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
						id: value,
						label,
					}),
					rules: [{ required: true }],
					component: <SelectFetch url="dictionaries/product-groups" />,
					...layout1,
				},
				{
					label: 'Тип блюда',
					name: 'readyMealsType',
					required: true,
					getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
						id: value,
						label,
					}),
					rules: [{ required: true }],
					component: <SelectFetch url="dictionaries/ready-meals-types" />,
					...layout1,
				},
		  ]
		: [{ component: null }]),
	{
		label: 'Срок годности, ч',
		name: 'shelfLife',
		required: true,
		rules: [{ required: true }],
		component: <MaskedInput max={99999.99} allowNegative={false} />,
		...layout2,
	},
	{
		label: 'Рабочий центр',
		name: 'workCenter',
		required: true,
		rules: [{ required: true }],
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: (
			<SelectFetch
				url="dictionaries/work-centers"
				onSelect={() => {
					const cookTechnologies = form.getFieldValue('cookTechnologies')
					for (const ctIndex of cookTechnologies.keys()) {
						form.setFieldValue(['cookTechnologies', ctIndex, 'equipment'], undefined)
					}
				}}
			/>
		),
		...layout2,
	},
	{
		label: 'Не ограничено сменой',
		name: 'isNotLimitedByShift',
		valuePropName: 'checked',
		component: <Checkbox />,
		...layout2,
	},
	...(type === RecipeTypeEnum['package']
		? [
				{
					label: 'Номер волны отгрузки',
					name: 'shipmentWave',
					key: 'shipmentWave',
					required: true,
					valuePropName: 'id',
					rules: [{ required: true }],
					component: (
						<SelectFetch
							url="system-settings/shipment-waves"
							label="id"
							field="shipmentWave"
							defaultValue={shipmentWave?.id}
						/>
					),
					...layout2,
				},
				{
					label: 'Срок годности до волны отгрузки, ч',
					name: 'shelfLifeBeforeShipmentWave',
					required: true,
					rules: [{ required: true }],
					component: <MaskedInput max={99999.99} allowNegative={false} />,
					...layout2,
				},
		  ]
		: [{ component: null }]),
	{
		label: 'Статус',
		valuePropName: 'children',
		name: 'status',
		component: <Typography.Text disabled={false} />,
		...layout3,
	},
	{
		label: 'Дата смены статуса',
		name: 'date',
		readOnly: true,
		valuePropName: 'children',
		disabled: true,
		getValueProps: (i) => ({
			children: i === undefined ? undefined : DateTime.fromISO(i).toFormat('dd.MM.yyyy'),
		}),
		component: <Typography.Text disabled={false} />,
		...layout3,
	},
	{
		label: 'Версия',
		name: 'version',
		readOnly: true,
		...layout3,
	},
	...(type === RecipeTypeEnum['package']
		? [
				{
					label: 'СТМ',
					name: 'ownBrand',
					required: true,
					getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
						id: value,
						label,
					}),
					rules: [{ required: true }],
					component: <SelectFetch url="dictionaries/own-brands" />,
					...layout3,
				},
				{
					label: 'Подотчетная Меркурию продукция',
					name: 'isMercuryAccountableProds',
					valuePropName: 'checked',
					preserve: true,
					component: <Checkbox />,
					...layout3,
				},
		  ]
		: [
				{
					label: 'Происхождение',
					name: 'origin',
					readOnly: true,
					...layout3,
				},
				{ component: null },
		  ]),
]

const getColumns: (
	getColumnSearchProps: (column: string | string[]) => any,
	form?: FormInstance<TechCard>,
	fieldName?: Pick<TechCardManufProd, keyof TechCardManufProd>,
	values?: TechCardComposition[]
) => TableColumnsType<TechCardManufProd> = (getColumnSearchProps, form, fieldName, values = []) => [
	...(form
		? [
				{
					key: 'select',
					render: (record: TechCardManufProd) => {
						const checked = !!values?.find((v) => v.techCardManufProd?.id === record.id)
						return (
							<Checkbox
								checked={checked}
								onChange={(e) =>
									e.target.checked
										? form?.setFieldValue(fieldName, [
												...values,
												{ techCardManufProd: record, priority: 1 },
										  ])
										: form?.setFieldValue(
												fieldName,
												values.filter(
													(v: TechCardComposition) => v.techCardManufProd?.id !== record.id
												)
										  )
								}
							/>
						)
					},
				},
		  ]
		: []),
	{
		title: 'Тип',
		dataIndex: ['type'],
		...getColumnSearchProps('type'),
	},
	{
		title: 'Тип рецептуры',
		dataIndex: ['techCard', 'type'],
		...getColumnSearchProps(['techCard', 'type']),
	},
	{
		title: 'Артикул',
		dataIndex: 'articul',
		...getColumnSearchProps('articul'),
		sorter: sorter(SorterTypes.string, 'articul') as CompareFn<TechCardManufProd>,
	},
	{
		title: 'Наименование',
		dataIndex: ['manufProd'],
		...getColumnSearchProps(['manufProd']),
		sorter: (a, b) =>
			!a.manufProd ? 1 : !b.manufProd ? -1 : a.manufProd?.localeCompare(b.manufProd),
	},
	{
		title: 'Выход',
		dataIndex: ['release'],
	},
	{
		title: 'ЕИ',
		dataIndex: ['measure'],
	},
	{
		title: 'Рабочий центр',
		dataIndex: ['techCard', 'workCenter', 'label'],
		...getColumnSearchProps(['techCard', 'workCenter', 'label']),
	},
	{
		title: 'Статус',
		dataIndex: ['techCard', 'latestVersion', 'status'],
		// render: (status: string) => <StatusCell status={status} />,
		...getColumnSearchProps(['techCard', 'latestVersion', 'status']),
	},
]

export { url, searchFields, getFormItems, getColumns }
