import React from 'react'
import { Button, Checkbox, Col, FormListFieldData, Row, TimePicker } from 'antd'
import { TabItem, TableForm } from '../../../../components'
import { ColumnProps } from 'antd/es/table'

interface FixShiftTabColumnType extends FormListFieldData {}

const columns: ColumnProps<FixShiftTabColumnType>[] = [
	{
		title: 'Смена',
		dataIndex: 'label',
	},
	{
		title: 'Начало смены',
		dataIndex: 'startTime',
		valuePropName: 'time',
		component: <TimePicker format="HH:mm" />,
	},
	{
		title: 'Окончание смены',
		dataIndex: 'endTime',
		valuePropName: 'time',
		component: <TimePicker format="HH:mm" />,
	},
	{
		title: 'ПН',
		dataIndex: 'isMonday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ВТ',
		dataIndex: 'isTuesday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'СР',
		dataIndex: 'isWednesday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ЧТ',
		dataIndex: 'isThursday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ПТ',
		dataIndex: 'isFriday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'СБ',
		dataIndex: 'isSaturday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ВС',
		dataIndex: 'isSunday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
]

const FixShiftTab = () => {
	const getTableHeaderRow = (add) => (
		<Row>
			<Col>
				<Button onClick={() => add()}>Добавить новую строку</Button>
			</Col>
		</Row>
	)
	return (
		<TabItem>
			<TableForm getTableHeaderRow={getTableHeaderRow} columns={columns} field="fixShifts" />
		</TabItem>
	)
}

export default FixShiftTab
