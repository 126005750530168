import { NdsRate } from '@isf/backend/src/modules/dictionaries/modules/nds-rate/entities/nds-rate.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { LoaderFunction } from 'react-router-dom'
import { getAction } from '../dictionary-utils'

const url = 'dictionaries/nds-rates'
const queryKey = ['dictionaries', 'nds-rates']

export const ndsRatesQuery = () => ({
	queryKey,
	queryFn: async (): Promise<NdsRate[]> => {
		const response = await axios.get(url)
		return response.data
	},
})

export const loader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = ndsRatesQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const action = getAction(url, queryKey)
