export enum MenuPositionsEnum {
	Left = 'left',
	Top = 'top',
}

type MenuPositions = {
	left: MenuPositionsEnum.Left
	top: MenuPositionsEnum.Top
}

type MenuContextType = {
	state: MenuState
	toggleCollapsed: () => void
	togglePosition: (pos: MenuPositionsEnum) => void
}
type MenuState = {
	collapsed: boolean
	position: MenuPositionsEnum
}

type MenuAction = { type: 'toggleCollapsed' } | { type: 'togglePosition' }

type MenuProviderProps = { children: React.ReactNode }

export type { MenuState, MenuAction, MenuProviderProps, MenuPositions, MenuContextType }
