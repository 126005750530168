import React, { createContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	AuthState,
	AuthAction,
	AuthData,
	ChangePasswordType,
	ForgotPasswordType,
	AuthContextType,
	AuthProviderProps,
} from './types'
import { axios } from '../../utils/helpers'
import { usePrevious } from '../../utils/hooks'

const initialState = {
	authenticated: false,
	loading: false,
	errMsg: null,
}

const getInitialState: () => AuthState = () => {
	const authCtx = localStorage.getItem('authCtx')
	if (authCtx) {
		const prevState = JSON.parse(authCtx)
		if (Object.values(prevState).every((val) => !!val)) {
			return prevState
		}
	}
	return initialState
}

const authReducer = (state: AuthState, action: AuthAction) => {
	switch (action.type) {
		case 'startLoading':
			return { ...state, loading: true }
		case 'endLoading':
			return { ...state, loading: false }
		case 'authenticate':
			return { ...state, authenticated: action.value }
		case 'setError':
			return { ...state, authenticated: false, errMsg: action.errMsg }
		case 'reset':
			return initialState
		default: {
			throw new Error(`Неизвестный action type: ${action.type}`)
		}
	}
}

const AuthContext = createContext<
	| {
			state: AuthState
			signIn: AuthContextType['signIn']
			signOut: AuthContextType['signOut']
			changePwd: AuthContextType['changePwd']
			forgotPwd: AuthContextType['forgotPwd']
			resetError: AuthContextType['resetError']
	  }
	| undefined
>(undefined)

AuthContext.displayName = 'AuthContext'

const AuthProvider = ({ children }: AuthProviderProps) => {
	const initialState = getInitialState()
	const [state, dispatch] = useReducer(authReducer, initialState)
	const currentAuthStatus = usePrevious(state.authenticated)
	const navigate = useNavigate()

	useEffect(() => {
		const isTokenValid = async () => {
			const token = localStorage.getItem('token')
			if (!token) {
				return navigate('/auth/sign-in')
			}
			dispatch({ type: 'startLoading' })
			try {
				const result = await axios.get('/auth/is-token-valid')
				if (result) {
					dispatch({ type: 'authenticate', value: true })
				}
			} catch (err) {
				if (err instanceof Error) {
					dispatch({ type: 'setError', errMsg: err.message })
				}
			} finally {
				dispatch({ type: 'endLoading' })
			}
		}
		if (!state.loading && !state.authenticated && !state.errMsg) {
			isTokenValid()
		}
	}, [state.authenticated, state.loading, state.errMsg, navigate])

	useEffect(() => {
		if (currentAuthStatus && !state.authenticated) {
			navigate('/auth/sign-in', { replace: true })
		}
	}, [navigate, currentAuthStatus, state.authenticated])

	useEffect(() => {
		const token = localStorage.getItem('token')
		if (!token) {
			dispatch({ type: 'reset' })
		}

		const handleStorageChange = (event: StorageEvent) => {
			if (event.key === 'token' && !event.newValue) {
				dispatch({ type: 'reset' })
			}
		}

		window.addEventListener('storage', handleStorageChange)

		return () => {
			window.removeEventListener('storage', handleStorageChange)
		}
	}, [navigate])

	const signIn = async (data: AuthData) => {
		dispatch({ type: 'startLoading' })
		try {
			const res = await axios('/auth/sign-in', {
				method: 'POST',
				data,
			})
			if (res.data) {
				localStorage.setItem('token', res.data.accessToken)
				localStorage.setItem('refresh', res.data.refreshToken)
				dispatch({ type: 'authenticate', value: true })
				navigate('/')
			}
		} catch (err) {
			if (err) {
				dispatch({ type: 'setError', errMsg: err.response.data.error || err.message })
			}
		}
	}

	const signOut = async () => {
		try {
			localStorage.removeItem('token')
			localStorage.removeItem('refresh')
			window.name = ''
			dispatch({ type: 'authenticate', value: false })
			navigate('/auth/sign-in')
		} catch (err) {
			if (err instanceof Error) {
				dispatch({ type: 'setError', errMsg: err.message })
			}
		}
	}

	const changePwd = async (data: ChangePasswordType) => {
		dispatch({ type: 'startLoading' })
		try {
			const res = await axios('/auth/change-password', {
				method: 'PATCH',
				data,
			})
			if (res.data) {
				navigate('/auth/sign-in')
			}
		} catch (err) {
			if (err) {
				dispatch({ type: 'setError', errMsg: err.response.data.error })
			}
		} finally {
			dispatch({ type: 'endLoading' })
		}
	}

	const forgotPwd = async (data: ForgotPasswordType) => {
		try {
			const res = await axios('/auth/forgot-password', {
				method: 'PATCH',
				data,
			})
			if (res.data) {
				navigate('/auth/sign-in')
			}
		} catch (err) {
			if (err) {
				dispatch({ type: 'setError', errMsg: err.response.data.error })
			}
		}
	}

	const resetError = async () => {
		dispatch({ type: 'setError', errMsg: null })
	}

	const value = {
		state,
		signIn,
		signOut,
		changePwd,
		forgotPwd,
		resetError,
	}
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
	const context = React.useContext(AuthContext)
	if (context === undefined) {
		throw new Error('AuthProvider для useAuth не найден')
	}
	return context
}

export { useAuth, AuthProvider }
