import React from 'react'
import { TabItem, TableForm } from '../../../../components'
import { getColumnSearchProps } from '../../../../utils/helpers'

const getColumns = (data: any = []) => [
	{
		title: 'Тип оборудования',
		dataIndex: ['equipmentType', 'type'],
		...getColumnSearchProps(data.equipment, ['equipmentType', 'type']),
	},
	{
		title: 'Модель оборудования',
		dataIndex: ['equipmentType', 'model'],
		...getColumnSearchProps(data.equipment, ['equipmentType', 'model']),
	},
	{
		title: 'Статус',
		dataIndex: 'status',
		...getColumnSearchProps(data.equipment, 'status'),
	},
]

const EquipmentTab = ({ data }) => {
	return (
		<TabItem>
			<TableForm disabled columns={getColumns(data)} field="equipment" canEdit={false} />
		</TabItem>
	)
}

export default EquipmentTab
