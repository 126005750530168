import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import './index.css'
import ruRu from 'antd/lib/locale/ru_RU'
import { ConfigProvider } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import reportWebVitals from './reportWebVitals'
import router from './router/router'
import { queryClient } from './query-client'

const root = document.getElementById('root')
if (root) {
	const rootEl = ReactDOM.createRoot(root)
	rootEl.render(
		<React.StrictMode>
			<ConfigProvider
				locale={ruRu}
				form={{ validateMessages: { required: 'Поле обязательно для заполнения!' } }}
			>
				<QueryClientProvider client={queryClient}>
					<RouterProvider router={router} future={{ v7_startTransition: true }} />
				</QueryClientProvider>
			</ConfigProvider>
		</React.StrictMode>
	)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
