import { ShipmentWave } from '@isf/backend/src/modules/system-settings/modules/shipment-wave/entities/shipment-wave.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { LoaderFunction } from 'react-router-dom'
import { getAction } from '../../dictionaries/dictionary-utils'

const url = 'system-settings/shipment-waves'
const queryKey = ['system-settings', 'shipment-waves']

export const shipmentWavesQuery = () => ({
	queryKey,
	queryFn: async (): Promise<ShipmentWave[]> => {
		const response = await axios.get(url)
		return response.data
	},
})

export const loader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = shipmentWavesQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const action = getAction(url, queryKey)
