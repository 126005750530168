import { RoleType } from '@isf/backend/src/modules/dictionaries/modules/role-type/entities/role-type.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { ActionFunction, LoaderFunction, redirect } from 'react-router-dom'

const url = 'dictionaries/role-types'
const queryKey = ['dictionaries', 'role-types']

const roleTypeListDictQuery = () => ({
	queryKey,
	queryFn: (): Promise<RoleType[]> => axios.get(url).then((response) => response.data),
})

const roleTypeDictQuery = (id: RoleType['id']) => ({
	queryKey: [...queryKey, id],
	queryFn: (): Promise<RoleType[]> => axios.get(`${url}/${id}`).then((response) => response.data),
})

export const listLoader = (queryClient: QueryClient) => async () => {
	const query = roleTypeListDictQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const loader: (arg0: QueryClient) => LoaderFunction | never =
	(queryClient) =>
	async ({ params }) => {
		if (!params.id) {
			throw Error('Params.id is undefined')
		}
		if (+params.id) {
			const query = roleTypeDictQuery(+params.id)
			return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
		}
		return {}
	}

export const action: (arg0: QueryClient) => ActionFunction =
	(queryClient) =>
	async ({ request, params }) => {
		try {
			const values = await request.json()
			const response = await axios({
				method: request.method,
				url: request.method === 'POST' ? url : `${url}/${params.id}`,
				...(values && { data: values }),
			})
			await queryClient.invalidateQueries(
				{ queryKey, exact: true, refetchType: 'all' },
				{ throwOnError: true }
			)
			if (request.method === 'DELETE') {
				if (params.id) {
					queryClient.removeQueries({ queryKey: [...queryKey, +params.id] })
				}
				return redirect(url)
			}
			return redirect(`${url}/${response.data.id}`)
		} catch (err) {
			if (err.isReadable) {
				return { err }
			}
			console.log(err)
			return null
		}
	}
