import React from 'react'
import { useLoaderData } from 'react-router-dom'
import { Card, Form, Modal, Space } from 'antd'
import { formItems, getComponent } from './constants'
import { RoleType } from '@isf/backend/src/modules/dictionaries/modules/role-type/entities/role-type.entity'
import { CustomForm, CustomFormWithTabs } from '../../../components'
import { dictionaries, menuEntries } from '../../../utils/constants'
import { useMode } from '../../../contexts'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Bar from '../../../layout/bar/Bar'

const RoleTypeDict = () => {
	const data = useLoaderData() as RoleType
	const [form] = Form.useForm()
	const isUser = Form.useWatch('isUser', form)
	const {
		state: { mode },
	} = useMode()
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar form={form} />
			<Card style={{ margin: '0 16px' }}>
				{isUser ? (
					<CustomFormWithTabs
						onFinishFailed={(info) => {
							if (info.errorFields.some((errField) => errField.name.includes('rights'))) {
								return Modal.warning({
									width: '25%',
									icon: <ExclamationCircleOutlined />,
									content:
										'У этой роли нет доступа ни к одному объекту системы. Снимите флаг "Пользователь" или настройте доступ.',
								})
							}
						}}
						form={form}
						items={formItems}
						layout="inline"
						colCount={2}
						initialValues={data}
						tabs={[
							{
								key: 'cards',
								children: getComponent(menuEntries, ['rights'], mode),
								label: 'Карточки',
							},
							{
								key: 'dicts',
								children: getComponent(dictionaries, ['rights', 'Справочники'], mode),
								label: 'Справочники',
							},
						]}
					/>
				) : (
					<CustomForm
						form={form}
						items={formItems}
						layout="inline"
						colCount={2}
						initialValues={data}
					/>
				)}
			</Card>
		</Space>
	)
}

export default RoleTypeDict
