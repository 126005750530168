import React, { createContext, useEffect, useReducer } from 'react'
import type {
	MenuAction,
	MenuContextType,
	MenuPositions,
	MenuProviderProps,
	MenuState,
} from './types'
import { MenuPositionsEnum } from './types'

const MenuContext = createContext<
	| {
			state: MenuState
			toggleCollapsed: MenuContextType['toggleCollapsed']
			togglePosition: MenuContextType['togglePosition']
	  }
	| undefined
>(undefined)

MenuContext.displayName = 'MenuContext'

const menuPositions: MenuPositions = {
	left: MenuPositionsEnum.Left,
	top: MenuPositionsEnum.Top,
}

const menuReducer = (state: MenuState, action: MenuAction): MenuState => {
	switch (action.type) {
		case 'toggleCollapsed': {
			return { ...state, collapsed: !state.collapsed }
		}
		case 'togglePosition': {
			return {
				...state,
				position: state.position === menuPositions.left ? menuPositions.top : menuPositions.left,
			}
		}
		default: {
			throw new Error(`Неизвестный action type: ${action}`)
		}
	}
}
const initialState = {
	position: menuPositions.left,
	collapsed: false,
}

const getInitialState = (): MenuState => {
	const savedState = localStorage.getItem('theme')
	if (!savedState) {
		return initialState
	}
	return JSON.parse(savedState)
}

const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
	const initialState = getInitialState()
	const [state, dispatch] = useReducer(menuReducer, initialState)

	useEffect(() => {
		localStorage.setItem('theme', JSON.stringify(state))
	}, [state])

	const toggleCollapsed = () => dispatch({ type: 'toggleCollapsed' })

	const togglePosition = () => dispatch({ type: 'togglePosition' })

	const value = {
		state,
		toggleCollapsed,
		togglePosition,
	}

	return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

const useMenu = () => {
	const context = React.useContext(MenuContext)
	if (context === undefined) {
		throw new Error('MenuProvider для useMenu не найден')
	}
	return context
}

export { useMenu, MenuProvider, menuPositions }
