import React from 'react'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { RoleType } from '@isf/backend/src/modules/dictionaries/modules/role-type/entities/role-type.entity'
import { columns } from './constants'
import ListTemplate from '../../ListTemplate'
import { Card, Layout, Space } from 'antd'
import Bar from '../../../layout/bar/Bar'

const RoleTypeList = () => {
	const data = useLoaderData()
	const navigate = useNavigate()
	const location = useLocation()
	const onRow = (record: RoleType) => ({
		onClick: () => navigate(`${location.pathname}/${record.id}`),
	})
	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<ListTemplate
						columns={columns}
						dataSource={data}
						onRow={onRow}
						noRowButtons
						searchFields={['label']}
					/>
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default RoleTypeList
