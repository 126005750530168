import React from 'react'
import './App.css'
import Layout from './layout/Layout'
import {
	AuthProvider,
	ListProvider,
	MenuProvider,
	ModeProvider,
	UserDataProvider,
} from './contexts'

const App = () => {
	return (
		<AuthProvider>
			<ModeProvider>
				<ListProvider>
					<MenuProvider>
						<UserDataProvider>
							<Layout />
						</UserDataProvider>
					</MenuProvider>
				</ListProvider>
			</ModeProvider>
		</AuthProvider>
	)
}

export default App
