import { Form, Input, Button, Space, Typography, Col, Row } from 'antd'
import { useAuth } from '../../contexts'
import AuthErrorMsg from './AuthErrorMsg'
import { useNavigate } from 'react-router-dom'
import { inputStyle } from './shared'

const ForgotPassword = () => {
	const {
		forgotPwd,
		resetError,
		state: { errMsg },
	} = useAuth()

	const navigate = useNavigate()

	const styleBtn = {
		fontWeight: 700,
		width: 500,
		height: 50,
		alignSelf: 'center',
		borderRadius: 5,
	}

	return (
		<Row>
			<Col span={6} offset={9}>
				<Space
					direction="vertical"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<Typography.Title style={{ fontWeight: 700, color: 'rgb(3 52 97)', fontSize: '25px' }}>
						ВОССТАНОВЛЕНИЕ ПАРОЛЯ
					</Typography.Title>
					<Typography.Text
						style={{
							display: 'flex',
							fontSize: 22,
							fontWeight: 700,
							textAlign: 'center',
							paddingBottom: 30,
							color: 'rgb(3 52 97)',
							maxWidth: '500px',
							lineHeight: 1,
						}}
					>
						Введите почту указанную при создании учетной записи
					</Typography.Text>
					<Form colon={false} onFinish={forgotPwd}>
						<Form.Item name="email" rules={[{ required: true, message: 'Введите email' }]}>
							<Input
								style={inputStyle}
								type="text"
								onKeyDown={(e) => {
									if (isCyrillics(e.key)) {
										e.preventDefault()
									}
								}}
								placeholder="Ваша эл. почта"
							/>
						</Form.Item>
						{errMsg && <AuthErrorMsg errMsg={errMsg} />}
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Button
								htmlType="submit"
								style={{
									...styleBtn,
									background: 'rgb(3 52 97)',
									color: 'white',
									marginBottom: 20,
								}}
							>
								ПРОДОЛЖИТЬ
							</Button>
							<Button
								onClick={() => {
									resetError()
									navigate('/auth/sign-in')
								}}
								style={{
									...styleBtn,
									border: '2px solid rgb(3, 52, 97)',
								}}
							>
								ОТМЕНИТЬ
							</Button>
						</div>
					</Form>
				</Space>
			</Col>
		</Row>
	)
}

export default ForgotPassword

export const isCyrillics = (val: string) => {
	return /[А-Яа-я]/.test(val)
}
