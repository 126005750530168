import React from 'react'
import { Card, Layout, Space, Table, Typography } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { dictionaries } from '../../utils/constants'
import { applyFilterForList } from '../../utils/helpers/for-provider'
import { useList, useUserData } from '../../contexts'
import Icon from '@ant-design/icons/lib/components/Icon'
import { NotEditableIcon } from '../../assets'
import Bar from '../../layout/bar/Bar'
import { useColumnSearchProps } from '../../utils/hooks'
import { checkRole } from '@isf/shared/functions/role'
const { Text } = Typography

const DictionaryList = () => {
	const navigate = useNavigate()
	const {
		state: { textFilter },
	} = useList()
	const {
		state: { rights: currentRights },
	} = useUserData()

	const filteredData = applyFilterForList(
		dictionaries.filter((voc) =>
			checkRole(
				{
					Справочники: {
						[voc.label]: {
							view: true,
						},
					},
				},
				currentRights
			)
		),
		{
			searchFields: ['label'],
			search: textFilter || '',
		}
	)

	const { getColumnSearchProps } = useColumnSearchProps(filteredData)

	const columns = [
		{
			title: 'Наименование',
			dataIndex: 'label',
			render: getVocLabel,
			...getColumnSearchProps('label'),
		},
	]

	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<Table
						columns={columns}
						dataSource={filteredData}
						onRow={(record) => ({
							onClick: () => navigate(record.url),
						})}
						style={{ cursor: 'pointer' }}
						pagination={{ pageSize: 18 }}
						size="small"
					/>
					<Outlet />
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default DictionaryList

const getVocLabel = (label, record) => (
	<Space>
		<Icon
			style={{
				fontSize: 18,
				visibility: record.editable === false ? 'visible' : 'hidden',
			}}
			component={NotEditableIcon}
		/>
		<Text>{label}</Text>
	</Space>
)
