import React, { Key, ReactNode } from 'react'
import {
	Button,
	Form,
	FormInstance,
	FormListFieldData,
	FormListOperation,
	Input,
	Space,
	Table,
	Typography,
} from 'antd'
import { ColumnType } from 'antd/es/table'
import { modes } from '../../utils/constants'
import { useMode } from '../../contexts'

const { Item, List } = Form

export interface ITableFormColumnType<T> extends ColumnType<T> {
	component?: React.JSX.Element
	components?: React.JSX.Element[]
	readOnly?: boolean
	children?: ITableFormColumnType<T>[]
}

type TableFormProps<T> = {
	columns?: ITableFormColumnType<T>[]
	getColumns?: (fn: FormListOperation['remove'], form: FormInstance) => ITableFormColumnType<T>[]
	field: string
	defaultValue?: Partial<T>
	getTableHeaderRow?: (fn: FormListOperation['add']) => React.JSX.Element | null
	canEdit?: boolean
	required?: boolean
	disabled?: boolean
}

const TableForm = <T extends FormListFieldData>({
	columns,
	getColumns,
	field,
	getTableHeaderRow,
	canEdit = true,
	required,
	disabled: disabledProp,
}: TableFormProps<T>) => {
	const {
		state: { mode },
	} = useMode()

	const viewMode = mode === modes.view
	const disabled = disabledProp || viewMode

	const renderFormItem: (
		column: ITableFormColumnType<T>,
		field: FormListFieldData
	) => (React.JSX.Element | null)[] = (
		{ dataIndex, component, components, readOnly, render, formItemName, ...props },
		f
	) => {
		if (components) {
			return (
				<Space size="small" style={{ display: 'flex' }}>
					{components.map((el, index) => renderFormItem(el, f))}
				</Space>
			)
		}
		const name = [
			...(f.name === undefined ? [] : Array.isArray(f.name) ? f.name : [f.name]),
			...(Array.isArray(formItemName ?? dataIndex)
				? formItemName ?? dataIndex
				: [formItemName ?? dataIndex]),
		]
		if (render) {
			const fieldValue =
				form.getFieldValue(name) ?? dataIndex !== undefined
					? f[formItemName ?? dataIndex]
					: undefined
			const recordValue = form.getFieldValue([field, f.name])
			const el = render(
				fieldValue,
				typeof recordValue === 'object' ? recordValue : f,
				f.name
			) as ReactNode
			return (
				<Item noStyle name={name} key={dataIndex as Key} {...props}>
					{React.isValidElement(el) ? React.cloneElement(el, { disabled }) : el}
				</Item>
			)
		}
		return (
			<Item noStyle name={name} key={dataIndex as Key} {...props}>
				{readOnly ? (
					<Typography.Text>{form.getFieldValue([field, ...name])}</Typography.Text>
				) : !component ? (
					<Input disabled={disabled} />
				) : (
					React.cloneElement(component, { disabled })
				)}
			</Item>
		)
	}

	const form = Form.useFormInstance()
	const fieldValue = Form.useWatch(field, form)
	return (
		<List
			name={field}
			rules={[
				{
					validator: async () => {
						if (required) {
							if (!fieldValue?.length) {
								return Promise.reject('Не добавлена ни одна строка в табличной вкладке')
							}
						}
						return Promise.resolve()
					},
				},
			]}
		>
			{(fields, { add, remove }) => (
				<Space direction="vertical" style={{ width: '100%' }}>
					{canEdit &&
						!viewMode &&
						(getTableHeaderRow ? (
							getTableHeaderRow(add)
						) : (
							<Button onClick={() => add()}>Добавить</Button>
						))}
					<Table
						pagination={false}
						rowKey={(record) => (Array.isArray(record.name) ? record.name.join('_') : record.name)}
						size="small"
						dataSource={fields.map((f) => {
							const value = form.getFieldValue([field, f.name])
							if (!value) {
								return f
							}
							return {
								...value,
								...f,
								analogs: value.analogs?.map((a, index) => ({
									...a,
									...f,
									name: [f.name, 'analogs', index],
								})),
							}
						})}
						// expandable={{
						// 	rowExpandable: (record) =>
						// 		fieldValue.find((el) => el.id === record.id)?.analogs?.length < 0,
						// 	childrenColumnName: 'analogs',
						// 	defaultExpandAllRows: true,
						// 	indentSize: 10,
						// }}
					>
						{(getColumns ? getColumns(remove, form) : columns ?? []).map((c, index) =>
							c.children ? (
								<Table.ColumnGroup title={c.title} key={index}>
									{c.children.map((el, i) => (
										<Table.Column
											key={`${index}_${i}`}
											{...el}
											render={(_, record) => renderFormItem(el, record)}
										/>
									))}
								</Table.ColumnGroup>
							) : (
								<Table.Column
									key={index}
									{...c}
									render={(_, record) => renderFormItem(c, record)}
								/>
							)
						)}
					</Table>
				</Space>
			)}
		</List>
	)
}

export default TableForm
