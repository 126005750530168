import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import { RawMatCategory as RawMatCategoryEntity } from '@isf/backend/src/modules/dictionaries/modules/raw-mat-category/entities/raw-mat-category.entity'
import type { ColumnsType } from 'antd/es/table'
import { Select, Col } from 'antd'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['categoryLabel', 'group']

const RawMatGroup = () => {
	const { data, selectors } = useLoaderData()
	const submit = useSubmit()
	const columns: ColumnsType<{
		categoryLabel: string
		group: string
		id: number
	}> = [
		{
			title: 'Категория',
			dataIndex: 'categoryLabel',
		},
		{
			title: 'Группа',
			dataIndex: 'group',
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'rawMatCategoryId',
			label: 'Категория',
			required: true,
			rules: [{ required: true }],
			component: (
				<Select notFoundContent={<Col>Не найдено</Col>}>
					{selectors.rawMatCategory.map((it: RawMatCategoryEntity) => (
						<Select.Option key={it.id} value={it.id}>
							{it.label}
						</Select.Option>
					))}
				</Select>
			),
			...layout,
		},
		{
			name: 'group',
			label: 'Группа',
			rules: [{ required: true }],
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default RawMatGroup
