import { TechCard } from '@isf/backend/src/modules/tech-card/entities/tech-card.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../utils/helpers'
import { type LoaderFunction, type ActionFunction, redirect } from 'react-router-dom'
import { RecipeTypeEnum } from './constants'
import { TechCardVersion } from '@isf/backend/src/modules/tech-card/entities/tech-card-version.entity'

const url = 'tech-cards'
export const queryKey = ['tech-cards']

const techCardListQuery = (searchParams) => {
	const queryWithParamsKey = queryKey
	for (const key in searchParams.keys()) {
		queryWithParamsKey.push(key)
	}
	return {
		queryKey: queryWithParamsKey,
		queryFn: (): Promise<TechCard[]> =>
			axios.get(`${url}?${searchParams}`).then((response) => response.data),
	}
}

const techCardQuery = (id: TechCard['id']) => ({
	queryKey: [...queryKey, { id }],
	queryFn: (): Promise<TechCard[]> =>
		axios.get(`${url}/${id}`).then((response) => {
			return response.data
		}),
})

const techCardRelatedQuery = (id: TechCard['id']) => ({
	queryKey: [...queryKey, 'related', { id }],
	queryFn: (): Promise<TechCard[]> =>
		axios.get(`${url}/related/${id}`).then((response) => {
			return response.data
		}),
})

export const listLoader: (arg0: QueryClient) => LoaderFunction =
	(queryClient) =>
	async ({ request }) => {
		const url = new URL(request.url)
		const query = techCardListQuery(url.searchParams)
		return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
	}

export const loader: (arg0: QueryClient) => LoaderFunction =
	(queryClient) =>
	async ({ params }) => {
		const storedValue = localStorage.getItem('techCard')
		if (storedValue) {
			localStorage.removeItem('techCard')
			return JSON.parse(storedValue)
		}
		if (params.id && !isNaN(+params.id)) {
			const query = techCardQuery(+params.id)
			const relatedQuery = techCardRelatedQuery(+params.id)
			const data: TechCard & TechCardVersion =
				queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
			const related =
				queryClient.getQueryData(relatedQuery.queryKey) ??
				(await queryClient.fetchQuery(relatedQuery))

			return { data, related }
		}
		return {
			data: {
				type: RecipeTypeEnum['cooking'],
				recipeNumber: null,
				isNotLimitedByShift: false,
				isMercuryAccountableProds: false,
				version: 1,
				manufProds: [{}],
				cookTechnologies: [],
				compositions: [],
			},
			related: [],
		}
	}

export const action: (arg0: QueryClient) => ActionFunction =
	(queryClient) =>
	async ({ request, params }) => {
		try {
			let data
			if (request.headers.get('Content-Type') === 'application/json') {
				data = await request.json()
			} else {
				const initialFormData = await request.formData()
				data = Object.fromEntries(initialFormData)
			}
			const response = await axios({
				method: request.method,
				url: request.method === 'POST' ? url : `${url}/${params.id}`,
				data: { data },
				// headers: { 'Content-Type': 'multipart/form-data' },
			})
			let redirectUrl = `/${url}`
			if (request.method === 'DELETE') {
				if (params.id) {
					queryClient.removeQueries({ queryKey: [...queryKey, { id: +params.id }], exact: true })
					queryClient.removeQueries({
						queryKey: [...queryKey, 'related', { id: +params.id }],
						exact: true,
					})
				}
			} else {
				redirectUrl += `/${response.data.id}`
			}
			await queryClient.invalidateQueries({ queryKey, refetchType: 'all' }, { throwOnError: true })
			return redirect(redirectUrl)
		} catch (err) {
			if (err.isReadable) {
				return { err }
			}
			console.log(err)
			return null
		}
	}
