import { useState, useEffect } from 'react'

export function useSearchFilter(initialData) {
	const [searchValue, setSearchValue] = useState('')
	const [filters, setFilters] = useState(initialData)

	const onSearch = (e) => {
		setSearchValue(e.target.value)
	}

	useEffect(() => {
		const resetFilters = () => {
			setFilters(initialData)
		}
		const filterData = () => {
			return initialData.filter((filterValue) =>
				String(filterValue.text.props.children)
					.toLowerCase()
					.includes(String(searchValue).toLowerCase())
			)
		}
		searchValue ? setFilters(filterData()) : resetFilters()
	}, [initialData, searchValue])

	return {
		filters,
		searchValue,
		onSearch,
		setSearchValue,
	}
}
