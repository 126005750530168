import React from 'react'
import { Button, FormInstance, Modal, Space } from 'antd'
import { CloseIcon } from '../../assets'
import { modes } from '../../utils/constants'
import { useMode } from '../../contexts'
import {
	useLoaderData,
	useLocation,
	useMatch,
	useMatches,
	useNavigate,
	useParams,
	useSubmit,
} from 'react-router-dom'
import { showSend1cButton } from './utils'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const send1cPaths = ['nomenclatures/raw-mat-nomenclatures']

interface IControlButtonsProps {
	form: FormInstance
	buttons?: React.JSX.Element[]
	canEdit?: boolean
	canDelete?: boolean
}

const ControlButtons = ({
	form,
	buttons,
	canEdit = true,
	canDelete = true,
}: IControlButtonsProps) => {
	const {
		setMode,
		state: { mode },
	} = useMode()
	const params = useParams()
	const matches = useMatches()
	const submit = useSubmit()
	const isList = !params.id
	const isStocksPage = matches.some((m) => m.pathname === '/stocks')
	const lastPathName = matches.at(-1)?.pathname
	const isShowAddBtn = lastPathName !== '/dictionaries/' && lastPathName !== '/'
	const match = useMatch(`${send1cPaths}/:id`)
	const entity = useLoaderData()
	const show1cButton = showSend1cButton(match?.pathname, entity)
	const location = useLocation()
	const navigate = useNavigate()
	const getButtons = () => {
		return [
			...(mode === modes.view && isList && !isStocksPage && isShowAddBtn
				? [
						<Button type="primary" onClick={() => setMode(modes.create)} key="add">
							Добавить
						</Button>,
				  ]
				: []),
			...(isStocksPage && isList
				? [
						<Button type="primary" onClick={() => undefined} key="edit">
							Провести массовый Вх. контроль
						</Button>,
				  ]
				: []),
			...(!isList
				? mode === modes.view
					? [
							show1cButton
								? [
										<Button type="primary" onClick={() => undefined} key="sendTo1c">
											Отправить в 1С
										</Button>,
								  ]
								: [],
							canEdit
								? [
										<Button type="primary" onClick={() => setMode(modes.edit)} key="edit">
											Редактировать
										</Button>,
								  ]
								: [],
							canDelete
								? [
										<Button
											type="primary"
											key="delete"
											form={location.pathname}
											onClick={() =>
												Modal.confirm({
													content: 'Вы действительно хотите удалить данный элемент?',
													centered: true,
													icon: <ExclamationCircleOutlined />,
													onOk: (close) => {
														submit(
															{ id: params.id },
															{
																method: 'DELETE',
																action: location.pathname,
																encType: 'application/json',
															}
														)
														close()
													},
												})
											}
										>
											Удалить
										</Button>,
								  ]
								: [],
							<Button
								key="close"
								type="text"
								style={{ lineHeight: 0.8 }}
								icon={<CloseIcon />}
								onClick={() => navigate(location.pathname.replace(`/${params.id}`, ''))}
							/>,
					  ]
					: [
							<Button type="primary" htmlType="submit" form={location.pathname} key="save">
								Сохранить
							</Button>,
							<Button
								type="text"
								style={{ lineHeight: 0.8 }}
								icon={<CloseIcon />}
								onClick={() => {
									const reset = () => {
										if (params?.id === 'new') {
											navigate(location.pathname.replace(`/${params.id}`, ''))
										}
										return setMode(modes.view)
									}
									if (form?.isFieldsTouched && !form?.isFieldsTouched()) {
										return reset()
									}
									return Modal.confirm({
										icon: <ExclamationCircleOutlined />,
										centered: true,
										width: '25%',
										content: 'Выйти без сохранения изменений?',
										okText: 'Выйти',
										onOk: (close) => {
											if (form) {
												form.resetFields()
											}
											close()
											reset()
										},
									})
								}}
								key="close"
							/>,
					  ]
				: []),
		]
	}

	const controlButtons = buttons ?? getButtons()
	return <Space style={{ alignSelf: 'flex-end' }}>{controlButtons}</Space>
}

export default ControlButtons
