import React from 'react'
import { Button, Col, Form, FormListFieldData, FormListOperation, Row } from 'antd'
import { TabItem, TableForm } from '../../../../components'
import { useMode } from '../../../../contexts'
import { modes } from '../../../../utils/constants'
import { getColumns } from './utils'

interface CookTechnologyTabColumnType extends FormListFieldData {}

const CookTechnologyTab = () => {
	const {
		state: { mode },
	} = useMode()
	const form = Form.useFormInstance()
	const workCenter = Form.useWatch('workCenter', form)
	const manufProds = Form.useWatch('manufProds', form)
	const getTableHeaderRow = (add: FormListOperation['add']) =>
		mode === modes.view ? null : (
			<Row>
				<Col>
					<Button
						onClick={() =>
							add({
								priority: 1,
								...(manufProds[0].measure === 'кг' && { productivityMeasure: 'кг' }),
							})
						}
					>
						Добавить новую строку
					</Button>
				</Col>
			</Row>
		)
	return (
		<TabItem>
			<TableForm
				getTableHeaderRow={getTableHeaderRow}
				getColumns={(...args) => getColumns(...args, workCenter, manufProds)}
				field="cookTechnologies"
				required
			/>
		</TabItem>
	)
}

export default CookTechnologyTab
