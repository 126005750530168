import { Card, Form, Space } from 'antd'
import { Supply as SupplyType } from '@isf/backend/src/modules/supply/entities/supply.entity'
import { useLoaderData } from 'react-router-dom'
import { formItems } from './constants'
import { CustomFormWithTabs } from '../../components'
import { NomenclatureTab, EventHistoryTab } from './tabs'
import React from 'react'
import Bar from '../../layout/bar/Bar'

const Supply = () => {
	const data = useLoaderData() as SupplyType
	const [form] = Form.useForm()
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar />
			<Card style={{ margin: '0 16px' }}>
				<CustomFormWithTabs
					draft
					form={form}
					colCount={3}
					rowCount={4}
					items={formItems}
					initialValues={data}
					tabs={[
						{ key: 'nomenclature', children: <NomenclatureTab />, label: 'Номенклатура' },
						{ key: 'history', children: <EventHistoryTab />, label: 'История событий' },
					]}
				/>
			</Card>
		</Space>
	)
}

export default Supply
