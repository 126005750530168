import { RawMatType as RawMatTypeEntity } from '@isf/backend/src/modules/dictionaries/modules/raw-mat-type/entities/raw-mat-type.entity'
import { useLoaderData } from 'react-router-dom'
import { getFormItems } from './constants'
import { CustomForm } from '../../../components'
import { useForm } from 'antd/es/form/Form'
import { Card, Form, Space } from 'antd'
import Bar from '../../../layout/bar/Bar'

const RawMatType = () => {
	const data = useLoaderData() as RawMatTypeEntity
	const [form] = useForm()
	const origin = Form.useWatch('origin', form)
	const measure = Form.useWatch('measure', form)
	const isHasNutritionalValue = Form.useWatch('isHasNutritionalValue', form)
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar form={form} />
			<Card style={{ margin: '0 16px' }}>
				<CustomForm
					form={form}
					colCount={3}
					rowCount={4}
					items={getFormItems(data, { isHasNutritionalValue, origin, measure })}
					initialValues={data}
				/>
			</Card>
		</Space>
	)
}

export default RawMatType
