import { Form as FormAntd, Input, Modal, TableColumnsType } from 'antd'
import { useMode } from '../../contexts/mode/mode'
import { modes } from '../../utils/constants'
// import { TableRowButtons } from '..'
import React, { useState } from 'react'
import { CustomFormItemProps } from '../../types/antd-custom-types'

const TabItem = <T,>({
	columns = [],
	dataSource = [],
	items = [],
	onRow = () => ({}),
	noRowButtons,
	children,
}: {
	columns?: TableColumnsType<T>
	dataSource?: T[]
	items?: CustomFormItemProps[]
	onRow?: (arg: T) => {}
	noRowButtons?: boolean
	children: React.JSX.Element
}) => {
	const {
		state: { mode },
		// setMode,
	} = useMode()
	const [record, setRecord] = useState<T | undefined>(undefined)
	// const onEdit = (e: T) => {
	// 	setRecord(e)
	// 	setMode(modes.edit)
	// }

	// const onDelete = (e: T) => {
	// Modal.confirm({
	// 	content: 'Вы действительно хотите удалить данный элемент?',
	// 	icon: null,
	// 	onOk: async (close) => {
	// 		await axios['delete'](`${url}${e.id}`)
	// 		close()
	// 	},
	// })
	// }
	return (
		<>
			{children}
			{/* <Table
				dataSource={dataSource}
				columns={[
					...columns,
					...(noRowButtons || mode !== modes.edit
						? []
						: [
								{
									title: null,
									width: '5%',
									render: (item: T) => (
										<TableRowButtons onEdit={onEdit} onDelete={onDelete} record={item} />
									),
								},
						  ]),
				]}
				pagination={{ hideOnSinglePage: true }}
				rowKey="id"
				size="small"
				onRow={onRow}
			/> */}
			<Modal
				open={record && mode !== modes.view}
				onCancel={() => setRecord(undefined)}
				okButtonProps={{ htmlType: 'submit', form: 'dict' }}
				destroyOnClose
			>
				<FormAntd
					// name="dict"
					initialValues={record ?? { label: null, isUser: false }}
					onFinish={async (values) => {
						// const method = record?.id ? 'patch' : 'post'
						// await axios[method](url, values)
						// setMode(modes.view)
					}}
				>
					{items.map((item) => (
						<FormAntd.Item
							labelCol={{ span: 6 }}
							wrapperCol={{ offset: 12, span: 4 }}
							{...item}
							key={item.name}
						>
							{item.component ?? <Input />}
						</FormAntd.Item>
					))}
				</FormAntd>
			</Modal>
		</>
	)
}

export default TabItem
