import { Row, Col, Typography } from 'antd'

type IProps = {
	errMsg: string
}

const AuthErrorMsg = ({ errMsg }: IProps) => (
	<Row
		justify="center"
		align="middle"
		style={{
			position: 'relative',
			height: 20,
			bottom: 15,
			textAlign: 'center',
			fontWeight: 700,
		}}
	>
		<Col style={{ width: 500 }}>
			<Typography.Text type="danger">{errMsg}</Typography.Text>
		</Col>
	</Row>
)

export default AuthErrorMsg
