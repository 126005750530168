import React from 'react'
import { Button, Col, FormListFieldData, FormListOperation, Input, Row, TimePicker } from 'antd'
import { DatePicker, TabItem, TableForm } from '../../../../components'
import { DateTime } from 'luxon'
import { ColumnProps } from 'antd/es/table'
import { viewDateFormat } from '@isf/shared/constants/constants'

interface FixShiftTabColumnType extends FormListFieldData {}

const columns: ColumnProps<FixShiftTabColumnType>[] = [
	{
		title: 'Смена',
		dataIndex: 'label',
	},
	{
		title: 'Начало смены',
		dataIndex: 'startTime',
		valuePropName: 'time',
		component: <TimePicker format="HH:mm" />,
	},
	{
		title: 'Окончание смены',
		dataIndex: 'endTime',
		valuePropName: 'time',
		component: <TimePicker format="HH:mm" />,
	},
	{
		title: 'Дата начала работы',
		dataIndex: 'startWorkDate',
		valuePropName: 'date',
		component: (
			<DatePicker
				format={viewDateFormat}
				disabledDate={(date) => DateTime.now().startOf('day') > date}
			/>
		),
	},
	{
		title: 'Рабочие дни',
		dataIndex: 'workingDays',
		component: <Input type="number" />,
	},
	{
		title: 'Выходные дни',
		dataIndex: 'weekendDays',
		component: <Input type="number" />,
	},
]

const SlidingShiftTab = () => {
	const getTableHeaderRow = (add: FormListOperation['add']) => (
		<Row>
			<Col>
				<Button onClick={() => add()}>Добавить новую строку</Button>
			</Col>
		</Row>
	)
	return (
		<TabItem>
			<TableForm
				getTableHeaderRow={getTableHeaderRow}
				columns={columns}
				field="slidingShifts"
				defaultValue={{ prodDate: DateTime.now() }}
			/>
		</TabItem>
	)
}

export default SlidingShiftTab
