import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { Supply as SupplyEntity } from '@isf/backend/src/modules/supply/entities/supply.entity'
import { getColumns, searchFields } from './constants'
import ListTemplate from '../ListTemplate'
import React from 'react'
import { Button, Card, Layout, Space, Typography } from 'antd'
import { SelectFetch } from '../../components'
import { useList } from '../../contexts'
import { ResetFilter } from '../../assets/svg'
import { MultipleTableFilter } from '../../contexts/list/types'
import Bar from '../../layout/bar/Bar'
import { useColumnSearchProps } from '../../utils/hooks'

const SupplyList = () => {
	const data = useLoaderData() as SupplyEntity[]
	const navigate = useNavigate()
	const location = useLocation()
	const {
		setFilters,
		resetFilters,
		state: { filters },
	} = useList()
	const onRow = (record: SupplyEntity) => ({
		onClick: () => navigate(`${location.pathname}/${record.id}`),
	})
	const filteredData = data.filter(
		(el) =>
			!Object.keys(filters).length ||
			Object.keys(filters).some((key) => !(filters as MultipleTableFilter)[key].length === el[key])
	)
	const { getColumnSearchProps } = useColumnSearchProps(filteredData)
	const prefix = (
		<Space size="small">
			<Typography.Text strong>Организация заказчик</Typography.Text>
			<SelectFetch
				mode="multiple"
				style={{ width: 200 }}
				url={`/dictionaries/organizations`}
				onSelect={(value) =>
					setFilters({
						...filters,
						organization: [...((filters as MultipleTableFilter).organization || []), value],
					})
				}
				onDeselect={({ value }) =>
					setFilters({
						...filters,
						organization: (filters as MultipleTableFilter).organization.filter(
							(f) => f.value !== value
						),
					})
				}
				value={filters.organization}
			/>
			<Button
				type="text"
				icon={<ResetFilter className="pictogram" style={{ fontSize: 24 }} />}
				onClick={() => {
					resetFilters()
				}}
			/>
		</Space>
	)
	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<ListTemplate
						searchFields={searchFields}
						prefixRow={prefix}
						columns={getColumns(getColumnSearchProps)}
						dataSource={filteredData}
						onRow={onRow}
						noRowButtons
					/>
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default SupplyList
