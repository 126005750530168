import React, { useEffect, useMemo, useState } from 'react'
import { Menu } from 'antd'
// import { EllipsisOutlined } from '@ant-design/icons'
import { Link, useLocation, useParams } from 'react-router-dom'
import { dictionaries, modes } from '../../utils/constants'
import { useMode, menuPositions, useMenu, useUserData } from '../../contexts'
import { checkRole } from '@isf/shared/functions/role'

const items = [
	{
		label: 'Запасы',
		key: 'stocks',
		children: [
			{
				label: 'Сырье',
				url: '/stocks/raw-mats',
				key: 'stocks/raw-mats',
				right: {
					'АРМ офиса': {
						Сырье: {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Операции с запасами',
		key: 'stock-operations',
		children: [
			{
				label: 'Поступление',
				key: 'stock-operations/incoming',
				children: [
					{
						label: 'Поставки',
						url: '/stock-operations/incoming/supplies',
						key: 'stock-operations/incoming/supplies',
						right: {
							'АРМ офиса': {
								Поставки: {
									view: true,
								},
							},
						},
					},
				],
			},
		],
	},
	{
		label: 'Персонал',
		key: 'staff',
		url: '/staff',
		right: {
			'АРМ офиса': {
				Персонал: {
					view: true,
				},
			},
		},
	},
	{
		label: 'Тех. карты',
		key: 'tech-cards',
		url: '/tech-cards',
		right: {
			'АРМ офиса': {
				'Тех. карты': {
					view: true,
				},
			},
		},
	},
	{
		label: 'Номенклатура',
		key: 'nomenclatures',
		children: [
			{
				label: 'Номенклатура сырья',
				key: 'nomenclatures/raw-mat-nomenclatures',
				url: '/nomenclatures/raw-mat-nomenclatures',
				right: {
					'АРМ офиса': {
						'Номенклатура сырья': {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Справочники',
		url: '/dictionaries',
		key: 'dictionaries',
		right: {
			'АРМ офиса': {
				Справочники: {
					view: true,
				},
			},
		},
	},
	{
		label: 'Настройки системы',
		key: 'system-settings',
		children: [
			{
				label: 'Волны отгрузки',
				url: '/system-settings/shipment-waves',
				key: 'system-settings/shipment-waves',
				right: {
					'АРМ офиса': {
						'Настройки системы': {
							view: true,
						},
					},
				},
			},
		],
	},
]

// const style = {
// 	display: 'flex',
// 	alignItems: 'center',
// }

const Items = () => {
	const params = useParams()
	const location = useLocation()
	const {
		state: { mode },
	} = useMode()
	const {
		state: { position, collapsed },
	} = useMenu()
	const {
		state: { rights: currentRights },
	} = useUserData()
	const [selected, setSelected] = useState<string[]>([])
	const [opened, setOpened] = useState([`/${location.pathname.split('/').slice(1)[0]}`])
	const clickable = mode === modes.view
	const leftMenu = position === menuPositions.left

	useEffect(() => {
		if (collapsed || !leftMenu) {
			setOpened([])
		} else {
			setOpened([`/${location.pathname.split('/').slice(1)[0]}`])
		}
	}, [collapsed, leftMenu, location.pathname])

	useEffect(() => {
		if (Object.keys(params).length) {
			setSelected(location.pathname.split('/').slice(0, location.pathname.split('/').length - 1))
		} else {
			setSelected([location.pathname])
		}
	}, [location.pathname, params])

	const filteredByRights = useMemo(() => {
		const filterItemsByRights = () => {
			if (typeof currentRights === 'object' && !Object.keys(currentRights).length) {
				return []
			}
			return items.reduce((acc, item) => {
				if (item.key === 'dictionaries') {
					return dictionaries.some(
						(dict) =>
							currentRights['Справочники'] &&
							Object.keys(currentRights['Справочники']).includes(dict.label)
					)
						? [
								...acc,
								clickable
									? { ...item, label: <Link to={item.url as string}>{item.label}</Link> }
									: item,
						  ]
						: acc
				}
				if (item.children?.length) {
					const filteredData = item.children.filter((d) => checkRole(d.right, currentRights))
					if (filteredData.length) {
						return [
							...acc,
							clickable
								? {
										...item,
										children: filteredData.map((el) =>
											clickable ? { label: <Link to={el.url}>{el.label}</Link> } : item
										),
								  }
								: item,
						]
					}
					return acc
				}

				return checkRole(item.right, currentRights)
					? [
							...acc,
							clickable
								? { ...item, label: <Link to={item.url as string}>{item.label || 111}</Link> }
								: item,
					  ]
					: acc
			}, [])
		}
		return filterItemsByRights()
	}, [currentRights, clickable])

	return (
		<Menu
			mode={leftMenu ? 'inline' : 'horizontal'}
			items={filteredByRights}
			triggerSubMenuAction={collapsed || !leftMenu ? 'hover' : 'click'}
			style={{
				width: '100%',
				height: leftMenu ? 'auto' : '100%',
			}}
			theme="dark"
			openKeys={opened}
			selectedKeys={selected}
			disabled={!clickable}
			onOpenChange={(e) => setOpened(e)}
			// overflowedIndicator={
			// 	<EllipsisOutlined
			// 		style={{
			// 			display: 'flex',
			// 			alignItems: 'center',
			// 			height: 64,
			// 		}}
			// 	/>
			// }
		/>
	)
}

export default Items
