import { TableColumnsType } from 'antd'
import { SorterTypes, sorter } from '../../../utils/helpers'
import { CompareFn } from 'antd/es/table/interface'
import { RawMatStorage } from '@isf/backend/src/modules/stocks/modules/raw-mat/entities/raw-mat-storage.entity'

const layout = { labelCol: { span: 12 }, wrapperCol: { offset: 2, span: 8 } }

const searchFields = [
	['rawMat', 'partyNum'],
	['rawMat', 'articul'],
	'nomenclature',
	['provider', 'label'],
	'expirationDate',
	'storage',
	'quantity',
	['measure', 'label'],
	'status',
]

const formItems = [
	{
		label: '№ партии',
		...layout,
	},
	{
		label: 'Номенклатура',
		...layout,
	},
	{
		label: 'Артикул',
		...layout,
	},
	{
		label: 'Дата производства',
		...layout,
	},
	{
		label: 'Годен до',
		...layout,
	},
	{
		label: 'Количество доступное',
		...layout,
	},
	{
		label: 'ЕИ',
		...layout,
	},
	{
		label: '№ поставки',
		...layout,
	},
	{
		label: 'Дата поставки',
		...layout,
	},
	{
		label: 'Организация заказчик',
		...layout,
	},
	{
		label: 'Поставщик / Закупщик',
		...layout,
	},
]

const getColumns: (getColumnSearchProps) => TableColumnsType<RawMatStorage> = (
	getColumnSearchProps
) => [
	{
		title: '№ партии',
		dataIndex: ['rawMat', 'partyNum'],
		...getColumnSearchProps(['rawMat', 'partyNum']),
		sorter: sorter(SorterTypes.string, ['rawMat', 'partyNum']) as CompareFn<RawMatStorage>,
	},
	{
		title: 'Артикул',
		dataIndex: 'articul',
		...getColumnSearchProps(['rawMat', 'articul']),
	},
	{
		title: 'Номенклатура',
		dataIndex: 'nomenclature',
		...getColumnSearchProps('nomenclature'),
		sorter: sorter(SorterTypes.string, 'nomenclature') as CompareFn<RawMatStorage>,
	},
	{
		title: 'Поставщик / Закупщик',
		render: (record) => record.provider,
		...getColumnSearchProps('provider'),
	},
	{
		dataIndex: 'expirationDate',
		title: 'Годен до',
		sorter: sorter(SorterTypes.date, 'expirationDate') as CompareFn<RawMatStorage>,
	},
	{
		dataIndex: 'storage',
		title: 'Помещение хранения',
		...getColumnSearchProps('storage'),
	},
	{
		title: 'Количество',
	},
	{
		title: 'ЕИ',
	},
	{ dataIndex: 'status', title: 'Статус', ...getColumnSearchProps('status') },
]

export { formItems, getColumns, searchFields }
