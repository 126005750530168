import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useMode } from '../../contexts'
import { modes } from '../constants'
import { axios } from '../helpers'
import { AxiosRequestConfig } from 'axios'
import { useNavigate } from 'react-router-dom'
import { FormInstance } from 'antd'

type Props = {
	url: string
	id: number
	queryKey: (string | { id: number })[]
	method?: AxiosRequestConfig['method']
	redirectUrl?: string
	form: FormInstance
}

export const useAsyncAction = <T extends { id?: number }>({
	url,
	redirectUrl: propsRedirectUrl,
	id,
	queryKey,
	method: propsMethod,
	form,
}: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)
	const {
		state: { mode },
		setMode,
	} = useMode()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { mutate } = useMutation(
		(data: T) => {
			const method = propsMethod ?? mode === modes.create ? 'POST' : 'PATCH'
			const headers = data instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : {}
			return axios({
				method,
				url: method === 'POST' ? url : `${url}/${id}`,
				data,
				headers,
			})
		},
		{
			onMutate: () => setIsLoading(true),
			onSettled: () => setIsLoading(false),
			onSuccess: ({ data }, variables) => {
				let redirectUrl = propsRedirectUrl || url
				if (propsMethod === 'DELETE') {
					if (variables.id) {
						queryClient.removeQueries({ queryKey: [...queryKey, +variables.id] })
					}
				} else {
					redirectUrl += `/${variables.id}`
				}
				queryClient
					.invalidateQueries({ queryKey, refetchType: 'all' }, { throwOnError: true })
					.then(() => {
						for (const key of Object.keys(variables)) {
							form.setFieldValue(key, variables[key])
						}
						navigate(`/${redirectUrl}`, { state: data })
					})
				setMode(modes.view)
			},
			onError: (err) => {
				if (err instanceof Error) {
					setError(err)
				}
			},
		}
	)
	return { isLoading, mutate, error }
}
