import React from 'react'
import { Card, Space, Typography } from 'antd'
import { Link, useNavigate, useRouteError } from 'react-router-dom'

const ErrorPage = () => {
	const navigate = useNavigate()
	const error = useRouteError()
	console.log(error)
	return (
		<Card>
			<Typography.Title>Страница ошибки</Typography.Title>
			<Space direction="vertical">
				<Link
					to=".."
					onClick={(e) => {
						e.preventDefault()
						navigate(-1)
					}}
				>
					Вернуться назад
				</Link>
				<Link
					to="/"
					onClick={(e) => {
						e.preventDefault()
						navigate('/')
					}}
				>
					Вернуться на главную
				</Link>
			</Space>
		</Card>
	)
}

export default ErrorPage
