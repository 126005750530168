import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../contexts'

const Index = () => (
	<AuthProvider>
		<Outlet />
	</AuthProvider>
)

export default Index
