import { TableColumnsType, Typography } from 'antd'
import { Brand } from '@isf/backend/src/modules/dictionaries/modules/manufacturer/entities/brand.entity'
import { Manufacturer } from '@isf/backend/src/modules/dictionaries/modules/manufacturer/entities/manufacturer.entity'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { CompareFn } from 'antd/es/table/interface'

const layout = { labelCol: { span: 3 }, wrapperCol: { span: 5 } }

const formItems = [
	{
		name: 'label',
		label: 'Производитель',
		required: true,
		rules: [{ required: true }],
		...layout,
	},
]

const getColumns: (data: Manufacturer[], getColumnSearchProps) => TableColumnsType<Manufacturer> = (
	data,
	getColumnSearchProps
) => [
	{
		title: 'Производитель',
		dataIndex: 'label',
		key: 'label',
		sorter: sorter(SorterTypes.string, 'label') as CompareFn<Manufacturer>,
		...getColumnSearchProps('label'),
		width: '50%',
	},
	{
		title: 'Брэнды',
		key: 'brands',
		render: (record) => (
			<Typography.Text
				style={{ width: '120ch' }}
				ellipsis={{
					tooltip: true,
				}}
			>
				{record.brands.map((b: Brand) => b.label).join(', ')}
			</Typography.Text>
		),
		width: '50%',
		...getColumnSearchProps(
			['brandLabel'],
			data.flatMap((record) => record.brands.map((b) => ({ brandLabel: b.label })))
		),
		filters: data.flatMap((record) => record.brands.map((b) => ({ text: b.label, value: b.id }))),
		onFilter: (value, record) => record.brands.some((b) => b.label === value),
	},
]

const brandsColumns: TableColumnsType<Brand> = [
	{
		title: 'Брэнды',
		dataIndex: 'label',
	},
]

export { formItems, getColumns, brandsColumns }
