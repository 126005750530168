import React from 'react'
import { Staff as StaffType } from '@isf/backend/src/modules/staff/entities/staff.entity'
import { useLoaderData } from 'react-router-dom'
import { getFormItems } from './constants'
import { CustomForm } from '../../components'
import { useForm } from 'antd/es/form/Form'
import { Card, Form, Space } from 'antd'
import { useMode } from '../../contexts'
import Bar from '../../layout/bar/Bar'

const Staff = () => {
	const data = useLoaderData() as StaffType
	const {
		state: { mode },
	} = useMode()
	const [form] = useForm()
	const noPatronymic = Form.useWatch('noPatronymic', form)
	const isWorkWithMercury = Form.useWatch('isWorkWithMercury', form)
	const { isUser } = Form.useWatch('roleType', form) || {}
	const formItems = getFormItems(noPatronymic, isWorkWithMercury, isUser, mode)
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar form={form} />
			<Card style={{ margin: '0 16px' }}>
				<CustomForm form={form} colCount={3} rowCount={6} items={formItems} initialValues={data} />
			</Card>
		</Space>
	)
}

export default Staff
