import lodash from 'lodash'
import { DateTime } from 'luxon'

export enum SorterTypes {
	string = 'string',
	number = 'number',
	date = 'date',
	boolean = 'boolean',
}
export const sorter = (
	inType: SorterTypes,
	inKey: string | string[],
	dateFormat: string = 'hh:mm'
) => {
	if (
		![SorterTypes.string, SorterTypes.number, SorterTypes.date, SorterTypes.boolean].includes(
			inType
		)
	)
		throw Error('неправильно переданы параметры')

	if (inType === SorterTypes.number) {
		return (recA: object, recB: object) => {
			if (Number(lodash.get(recA, inKey)) > Number(lodash.get(recB, inKey))) return -1
			if (Number(lodash.get(recA, inKey)) < Number(lodash.get(recB, inKey))) return 1
			return 0
		}
	}
	if (inType === SorterTypes.string)
		return (recA: object, recB: object, order: string) => {
			const val1 = lodash.get(recA, inKey)
			const val2 = lodash.get(recB, inKey)
			if ([null, undefined, ''].includes(val1)) return order === 'ascend' ? 1 : -1
			if ([null, undefined, ''].includes(val2)) return order === 'ascend' ? -1 : 1
			return String(val1)?.localeCompare(String(val2))
		}
	if (inType === SorterTypes.date)
		return (recA: object, recB: object) => {
			const result =
				(lodash.get(recA, inKey)
					? DateTime.fromISO(lodash.get(recA, inKey))
					: DateTime.now()
				).toUnixInteger() -
				(lodash.get(recB, inKey)
					? DateTime.fromISO(lodash.get(recB, inKey))
					: DateTime.now()
				).toUnixInteger()
			return result
		}

	if (inType === SorterTypes.boolean)
		return (recA: object, recB: object) => {
			const valA = !!lodash.get(recA, inKey)
			const valB = !!lodash.get(recB, inKey)
			if (valA === valB) return 0
			if (valA === true && valB === false) return -1
			if (valA === false && valB === true) return 1
			return 0
		}
	return () => 0
}
