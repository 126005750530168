import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { getColumns } from './constants'
import ListTemplate from '../../ListTemplate'
import { Manufacturer } from '@isf/backend/src/modules/dictionaries/modules/manufacturer/entities/manufacturer.entity'
import { Card, Layout, Space } from 'antd'
import Bar from '../../../layout/bar/Bar'
import { useColumnSearchProps } from '../../../utils/hooks'

const ManufacturersList = () => {
	const data = useLoaderData() as Manufacturer[]
	const { getColumnSearchProps } = useColumnSearchProps(data)
	const navigate = useNavigate()
	const location = useLocation()
	const onRow = (record: Manufacturer) => ({
		onClick: () => navigate(`${location.pathname}/${record.id}`),
	})
	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<ListTemplate
						columns={getColumns(data, getColumnSearchProps)}
						dataSource={data}
						onRow={onRow}
						noRowButtons
						searchFields={['label', { brands: ['label'] }]}
					/>
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default ManufacturersList
