import { RawMatGroup } from '@isf/backend/src/modules/dictionaries/modules/raw-mat-group/entities/raw-mat-group.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { LoaderFunction } from 'react-router-dom'
import { getAction } from '../dictionary-utils'

const url = '/dictionaries/raw-mat-groups'
const queryKey = ['dictionaries', 'raw-mat-groups']

export const rawMatGroupQuery = () => ({
	queryKey,
	queryFn: async (): Promise<RawMatGroup[]> => {
		const response = await axios.get(url)
		return response.data
	},
})

export const loader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = rawMatGroupQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const action = getAction(url, queryKey)
