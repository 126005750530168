const dictionaries = [
	{ id: 1, label: 'Единицы измерения', key: 'measure', url: 'measures', editable: false },
	{
		id: 2,
		label: 'Категории сырья',
		key: 'rawMatCategory',
		url: 'raw-mat-categories',
		editable: false,
	},
	{ id: 3, label: 'Категории ТМЦ', key: 'tmcCategory', url: 'tmc-categories', editable: false },
	{ id: 4, label: 'Организации', key: 'organization', url: 'organizations', editable: false },
	{ id: 5, label: 'Статусы персонала', key: 'staffStatus', url: 'staff-statuses', editable: false },
	{ id: 6, label: 'Виды материалов', key: 'matType', url: 'material-types' },
	{ id: 7, label: 'Виды сырья', key: 'rawMatType', url: 'raw-mat-types' },
	// { label: 'Группы сырья', key: 'rawMatGroup', url: 'raw-mat-groups' },
	{ id: 8, label: 'Группы ТМЦ', key: 'tmcGroup', url: 'tmc-groups' },
	{ id: 9, label: 'Группы продуктов', key: 'prodGroup', url: 'product-groups' },
	{ id: 10, label: 'Оборудование', key: 'equipment', url: 'equipment' },
	{ id: 11, label: 'Тип / Модель оборудования', key: 'equipment-type', url: 'equipment-types' },
	{ id: 12, label: 'Производители и бренды сырья', key: 'manuf', url: 'manufacturers' },
	{ id: 13, label: 'Производители и бренды ТМЦ', key: 'tmcManuf', url: 'tmc-manufacturers' },
	{ id: 14, label: 'Рабочие центры', key: 'workCenter', url: 'work-centers' },
	{ id: 15, label: 'Ставки НДС', key: 'ndsRate', url: 'nds-rates' },
	{ id: 16, label: 'Склады', key: 'warehouse', url: 'warehouses' },
	{ id: 17, label: 'Собственные торговые марки', key: 'ownBrand', url: 'own-brands' },
	{ id: 18, label: 'Типы ролей', key: 'roleType', url: 'role-types' },
	{ id: 19, label: 'Типы готовых блюд', key: 'readyMealsType', url: 'ready-meals-types' },
	{
		id: 20,
		label: 'Типы упаковки продукции',
		key: 'productPackageType',
		url: 'product-package-types',
	},
	{ id: 21, label: 'Типы обработки', key: 'processType', url: 'process-types' },
]

const menuEntries = {
	'АРМ офиса': [
		{ label: 'Настройки системы', key: 'system-settings', url: 'system-settings' },
		{
			label: 'Номенклатура сырья',
			key: 'raw-mat-nomenclatures',
			url: 'nomenclatures/raw-mat-nomenclatures',
		},
		{ label: 'Персонал', key: 'staff', url: 'staff' },
		{ label: 'Поставки', key: 'supplies', url: 'stock-operations/incoming/supplies' },
		{ label: 'Тех. карты', key: 'tech-card', url: 'tech-card' },
	],
}

export { dictionaries, menuEntries }
