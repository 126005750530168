import lodash, { PropertyPath } from 'lodash'

export type ISearchFields<T> = (
	| string
	| string[]
	| { [key: string]: string[] }
	| ((el: T) => string)
)[]

/**
 * Фильтрует данные на основе поиска и фильров
 * @param {Array<T>} inList - массив полей
 * @param {{search: string,searchFields: Array<PropertyPath> }} params - массив айдишников
 * @returns {Array<Object>}
 */
export const applyFilterForList = <T>(
	inList: Array<T>,
	{
		search = null,
		searchFields,
	}: { search: string | null; searchFields: ISearchFields<T> | (() => ISearchFields<T>)[] } = {
		search: '',
		searchFields: [],
	}
): T[] => {
	if (search !== null) {
		if (searchFields?.length) {
			return inList.filter((e) => {
				return searchFields.some((sf) => {
					if (typeof sf === 'function') {
						return String(sf(e)).toLowerCase().includes(search.toLowerCase())
					}
					if (typeof sf === 'object' && !Array.isArray(sf)) {
						for (const key of Object.keys(sf)) {
							const arr = Array.isArray(e[key]) ? e[key] : [e[key]]
							if (
								applyFilterForList(arr, {
									search,
									searchFields: sf[key],
								}).length
							) {
								return true
							}
						}
					}
					return String(lodash.get(e, sf as PropertyPath))
						.toLowerCase()
						.includes(search.toLowerCase())
				})
			})
		}
	}
	return inList
}
