import React from 'react'
import { Button, Col, FormListOperation, Row } from 'antd'
import { DatePicker, MaskedInput, TabItem, TableForm, TimePicker } from '../../../../components'
import { DateTime } from 'luxon'
import { viewDateFormat } from '@isf/shared/constants/constants'
import { DeleteOutlined } from '@ant-design/icons'
import { modes } from '../../../../utils/constants'
import { useMode } from '../../../../contexts'

const getColumns = (remove) => [
	{
		title: 'Смена',
		dataIndex: 'label',
		required: true,
		rules: [{ required: true }],
	},
	{
		title: 'Начало смены',
		dataIndex: 'startTime',
		getValueFromEvent: (args) => DateTime.fromISO(args).toFormat('HH:mm'),
		getValueProps: (i) => ({
			value: i ? DateTime.fromISO(i) : undefined,
		}),
		component: <TimePicker />,
		required: true,
		rules: [{ required: true }],
	},
	{
		title: 'Окончание смены',
		dataIndex: 'endTime',
		getValueFromEvent: (args) => DateTime.fromISO(args).toFormat('HH:mm'),
		getValueProps: (i) => ({
			value: i ? DateTime.fromISO(i) : undefined,
		}),
		component: <TimePicker />,
		required: true,
		rules: [{ required: true }],
	},
	{
		title: 'Дата начала работы',
		dataIndex: 'startWorkDate',
		getValueProps: (i) => ({
			value: i ? DateTime.fromISO(i) : undefined,
		}),
		required: true,
		rules: [{ required: true }],
		component: (
			<DatePicker
				format={viewDateFormat}
				disabledDate={(date) => DateTime.now().startOf('day') > date}
			/>
		),
	},
	{
		title: 'Рабочие дни',
		dataIndex: 'workingDays',
		required: true,
		rules: [{ required: true }],
		component: <MaskedInput scale={0} allowNegative={false} />,
	},
	{
		title: 'Выходные дни',
		dataIndex: 'weekendDays',
		required: true,
		rules: [{ required: true }],
		component: <MaskedInput scale={0} allowNegative={false} />,
	},
	{
		title: 'Количество операторов',
		dataIndex: 'numberOfOperators',
		required: true,
		rules: [{ required: true }],
		component: <MaskedInput scale={0} allowNegative={false} />,
	},
	{
		title: null,
		key: 'remove',
		render: (_, __, index) => (
			<Button type="text" icon={<DeleteOutlined />} onClick={() => remove(index)} />
		),
	},
]

const SlidingShiftTab = () => {
	const {
		state: { mode },
	} = useMode()
	const getTableHeaderRow = (add: FormListOperation['add']) =>
		mode === modes.view ? null : (
			<Row>
				<Col>
					<Button onClick={() => add()}>Добавить новую строку</Button>
				</Col>
			</Row>
		)
	return (
		<TabItem>
			<TableForm
				getTableHeaderRow={getTableHeaderRow}
				getColumns={getColumns}
				field="slidingShifts"
			/>
		</TabItem>
	)
}

export default SlidingShiftTab
