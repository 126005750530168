import React from 'react'
import { Button, Checkbox, Col, Row, FormListOperation } from 'antd'
import { MaskedInput, TabItem, TableForm, TimePicker } from '../../../../components'
import { DeleteOutlined } from '@ant-design/icons'
import { modes } from '../../../../utils/constants'
import { useMode } from '../../../../contexts'
import { DateTime } from 'luxon'

const getColumns = (remove) => [
	{
		title: 'Смена',
		dataIndex: 'label',
		required: true,
		rules: [{ required: true }],
	},
	{
		title: 'Начало смены',
		dataIndex: 'startTime',
		required: true,
		rules: [{ required: true }],
		getValueFromEvent: (args) => DateTime.fromISO(args).toFormat('HH:mm'),
		getValueProps: (i) => ({
			value: i ? DateTime.fromISO(i) : undefined,
		}),
		component: <TimePicker />,
	},
	{
		title: 'Окончание смены',
		dataIndex: 'endTime',
		required: true,
		getValueFromEvent: (args) => DateTime.fromISO(args).toFormat('HH:mm'),
		getValueProps: (i) => ({
			value: i ? DateTime.fromISO(i) : undefined,
		}),
		rules: [{ required: true }],
		component: <TimePicker />,
	},
	{
		title: 'ПН',
		dataIndex: 'isMonday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ВТ',
		dataIndex: 'isTuesday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'СР',
		dataIndex: 'isWednesday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ЧТ',
		dataIndex: 'isThursday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ПТ',
		dataIndex: 'isFriday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'СБ',
		dataIndex: 'isSaturday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'ВС',
		dataIndex: 'isSunday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		title: 'Количество операторов',
		dataIndex: 'numberOfOperators',
		required: true,
		rules: [{ required: true }],
		component: <MaskedInput scale={0} allowNegative={false} />,
	},
	{
		title: null,
		key: 'remove',
		render: (_, __, index) => (
			<Button type="text" icon={<DeleteOutlined />} onClick={() => remove(index)} />
		),
	},
]

const FixShiftTab = () => {
	const {
		state: { mode },
	} = useMode()
	const getTableHeaderRow = (add: FormListOperation['add']) =>
		mode === modes.view ? null : (
			<Row>
				<Col>
					<Button onClick={() => add()}>Добавить новую строку</Button>
				</Col>
			</Row>
		)
	return (
		<TabItem>
			<TableForm getTableHeaderRow={getTableHeaderRow} getColumns={getColumns} field="fixShifts" />
		</TabItem>
	)
}

export default FixShiftTab
