import React, { useEffect, useReducer } from 'react'
import { ModeState, ModeAction, ModeProviderProps, SetMode } from './types'
import { modes } from '../../utils/constants'
import { useMatches } from 'react-router-dom'

const modeReducer = (state: ModeState, action: ModeAction) => {
	switch (action.type) {
		case 'setMode': {
			return { mode: action.mode }
		}
		default: {
			throw new Error(`Неизвестный action type: ${action.type}`)
		}
	}
}

const ModeContext = React.createContext<{ state: ModeState; setMode: SetMode } | undefined>(
	undefined
)
ModeContext.displayName = 'ModeContext'

const getInitialState = () => {
	const storedValue = localStorage.getItem('mode')
	localStorage.removeItem('mode')
	return { mode: storedValue ? JSON.parse(storedValue) : modes.view }
}

const ModeProvider = ({ children }: ModeProviderProps) => {
	const [state, dispatch] = useReducer(modeReducer, getInitialState())
	const matches = useMatches()

	const setMode: SetMode = (mode) => {
		dispatch({ type: 'setMode', mode })
	}

	useEffect(() => {
		if (matches.slice(-1)[0].params?.id === 'new') {
			setMode(modes.create)
		}
	}, [matches])

	const value = {
		state,
		setMode,
	}
	return <ModeContext.Provider value={value}>{children}</ModeContext.Provider>
}

const useMode = () => {
	const context = React.useContext(ModeContext)
	if (context === undefined) {
		throw new Error('ModeProvider для useMode не найден')
	}
	return context
}
export { ModeProvider, useMode }
