import { Form, Input } from 'antd'
import { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useNavigation } from 'react-router-dom'

const getFieldName = (id: string) => {
	const a = id.split('/').slice(-1)[0].split('_')
	return a.slice(-(a.length - 1))
}

const MaskedInput = ({ min, max = 9999, scale = 2, allowNegative = true, ...rest }) => {
	const form = Form.useFormInstance()
	const fieldName = getFieldName(rest.id)
	const [curValue, setCurValue] = useState<number | undefined>(rest.value)

	const { state } = useNavigation()

	useEffect(() => {
		// здесь значение редактируется напрямую потому что NestJS "" не сохраняет в числовое поле
		if (curValue === undefined) {
			form.setFieldValue(fieldName, null)
		}
	}, [curValue, fieldName, form])

	// temp fix чтобы отрендерить актуальное значение в NumericFormat когда оно поменялось на null
	return state === 'submitting' ? (
		<Input />
	) : (
		<NumericFormat
			allowedDecimalSeparators={[',', '.']}
			isAllowed={(values) => {
				const { formattedValue, floatValue } = values
				if (floatValue == null) {
					setCurValue(undefined)
					return formattedValue === ''
				} else {
					setCurValue(floatValue)
					return floatValue <= max && floatValue >= (min || -max)
				}
			}}
			customInput={Input}
			decimalScale={scale}
			allowNegative={allowNegative}
			{...rest}
		/>
	)
}

export default MaskedInput
