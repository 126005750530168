import { useAtom } from 'jotai'
import { locationAtom, tableFiltersAtom } from '../../../contexts/table-filters/table-filters-state'
import { getFilters } from '../../helpers'
import lodash from 'lodash'
import { FilterDropDown } from '../../../components'
import { useLocation, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { FilterDropdownProps } from 'antd/es/table/interface'

export function useColumnSearchProps<T>(data: T[]) {
	const [tableFilters, setTableFilters] = useAtom(tableFiltersAtom)
	const [changedLocation, setChangedLocation] = useAtom(locationAtom)

	const params = useParams()
	const location = useLocation()

	useEffect(() => {
		setChangedLocation(location.pathname.replace(`/${params.id}`, ''))
	}, [location.pathname, params.id, setChangedLocation])

	useEffect(() => {
		if (location.pathname.replace(`/${params.id}`, '') !== changedLocation) {
			setTableFilters({})
		}
	}, [changedLocation, location.pathname, params.id, setTableFilters])

	const getColumnSearchProps = (
		column: string | string[],
		columnData?: Pick<T, keyof T>[],
		specialKeys?: { true: string; false: string }
	) => {
		const colName = Array.isArray(column) ? column.join('.') : column
		return {
			filterDropdown: (props: FilterDropdownProps) => (
				<FilterDropDown
					{...props}
					setSelectedKeys={(keys: string[]) =>
						setTableFilters((filters) => ({ ...filters, [colName]: keys }))
					}
					selectedKeys={lodash.get(tableFilters, colName) || []}
					filters={getFilters(columnData || data, column, specialKeys)}
				/>
			),
			...(tableFilters && {
				filtered: lodash.get(tableFilters, colName)?.length,
				filteredValue: lodash.get(tableFilters, colName) || [],
			}),
			onFilter: column
				? (value: T[keyof T], record: T) => {
						const recordValue = lodash.get(record, colName)
						if (Array.isArray(recordValue)) {
							return recordValue.filter((v) => lodash.get(v, column).includes(value))
						}
						return recordValue === value
				  }
				: () => true,
		}
	}
	return {
		getColumnSearchProps,
	}
}
