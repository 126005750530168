import { Button, Col, Form, Modal, Row, Space, Tabs, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { CloseIcon } from '../../assets'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Search } from '../../layout/bar/Search'
import { useList } from '../../contexts'

const getQueryParamsString: (params: URLSearchParams | {}) => string = (params = {}) =>
	`?${Object.keys(params)
		.map(
			(key) =>
				`${key}=${encodeURIComponent(
					Object.entries(params[key])
						.map(([key, value]) => `${key}:${value}`)
						.join('&')
				)}`
		)
		.join('&')}`

const TableModal = ({ open, setOpen, url, items, title, field }) => {
	const navigate = useNavigate()
	const params = useParams()
	const form = Form.useFormInstance()
	const {
		state: { textFilter },
		setTextFilter,
	} = useList()

	const memoItems = useMemo(() => items, [items])
	const [activeKey, setActiveKey] = useState(memoItems[0]?.key)
	const [activeItem, setActiveItem] = useState(memoItems[0])

	const handleClose = () => {
		setOpen(false)
		navigate(`/${url}/${params?.id || ''}`)
	}

	useEffect(() => {
		if (open && activeKey) {
			navigate(
				{
					pathname: `/${url}/${params?.id || ''}${activeKey}`,
					search: getQueryParamsString(activeItem.queryParams),
				},
				{
					state: { modalView: true },
				}
			)
		}
	}, [activeItem.queryParams, activeKey, navigate, open, params?.id, url])

	return (
		<Modal
			open={open}
			forceRender
			footer={null}
			closeIcon={<CloseIcon />}
			width="100%"
			centered
			maskClosable={false}
			closable={false}
		>
			<Row
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Col>
					<Typography.Text strong style={{ fontSize: 24 }}>
						{title}
					</Typography.Text>
				</Col>
				<Col style={{ marginLeft: 'auto' }}>
					<Space>
						<Search search={setTextFilter} textFilter={textFilter} show />
						<Button
							type="primary"
							onClick={() => {
								const addedRows = form.getFieldValue(field).filter((el) => !el.id)
								if (!addedRows.length) {
									return Modal.confirm({
										centered: true,
										content: 'Не выбрано значение.',
										icon: <ExclamationCircleOutlined />,
										cancelButtonProps: { style: { display: 'none' } },
									})
								}
								handleClose()
							}}
						>
							Сохранить
						</Button>
						<Button
							type="text"
							style={{ lineHeight: 0.8 }}
							icon={<CloseIcon />}
							onClick={handleClose}
						/>
					</Space>
				</Col>
			</Row>
			<Tabs
				items={memoItems}
				defaultActiveKey={memoItems[0]?.key}
				onChange={(key) => {
					setActiveKey(key)
					setActiveItem(memoItems.find((item) => item.key === key))
					navigate(
						`/${url}/${params?.id || ''}${key}${getQueryParamsString(activeItem.queryParams)}`,
						{
							state: { modalView: true },
						}
					)
				}}
			/>
		</Modal>
	)
}

export default TableModal
