import { Checkbox, Radio, Select, TableColumnsType } from 'antd'
import { WarehouseFixShift } from '@isf/backend/src/modules/dictionaries/modules/warehouse/entities/warehouse-fix-shift.entity'
import { WarehouseSlidingShift } from '@isf/backend/src/modules/dictionaries/modules/warehouse/entities/warehouse-sliding-shift.entity'
import { Warehouse } from '@isf/backend/src/modules/dictionaries/modules/warehouse/entities/warehouse.entity'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { CompareFn } from 'antd/es/table/interface'

const layout = { labelCol: { span: 9 }, wrapperCol: { offset: 0, span: 14 } }

const formItems = [
	{
		name: 'label',
		label: 'Наименование склада',
		required: true,
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'location',
		label: 'Расположение',
		required: true,
		component: (
			<Radio.Group style={{ display: 'flex' }} defaultValue={'Внутренний'}>
				<Radio value={'Внешний'}>Внешний</Radio>
				<Radio value={'Внутренний'}>Внутренний</Radio>
			</Radio.Group>
		),
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'slidingOrFixShifts',
		label: 'Смены',
		required: true,
		component: (
			<Radio.Group style={{ display: 'flex' }} defaultValue={'Фиксированные'}>
				<Radio value={'Фиксированные'}>Фиксированные</Radio>
				<Radio value={'Скользящие'}>Скользящие</Radio>
			</Radio.Group>
		),
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'storedNomenclature',
		label: 'Хранимая номенклатура',
		required: true,
		rules: [{ required: true }],
		component: (
			<Select mode="multiple">
				{['Сырье', 'Полуфабрикат', 'Продукт передела', 'Готовая продукция', 'ТМЦ'].map(
					(val: string) => (
						<Select.Option key={val} value={val}>
							{val}
						</Select.Option>
					)
				)}
			</Select>
		),
		...layout,
	},
	{
		name: 'isWorkshopWarehouse',
		label: 'Цеховой склад',
		valuePropName: 'checked',
		component: <Checkbox />,
		...layout,
	},
	{
		name: '_1cUuid',
		label: 'Идентификатор 1С',
		...layout,
	},
]

const columns: (data: Warehouse[], getColumnSearchProps) => TableColumnsType<Warehouse> = (
	data,
	getColumnSearchProps
) => [
	{
		title: 'Наименование склада',
		dataIndex: 'label',
		sorter: sorter(SorterTypes.string, 'label') as CompareFn<Warehouse>,
		...getColumnSearchProps('label'),
		onFilter: (value, record) => record.id === value,
	},
	{
		title: 'Расположение',
		dataIndex: 'location',
		...getColumnSearchProps(
			'locationLabel',
			data.map((e) => ({ locationLabel: e.location }))
		),
		onFilter: (value, record) => record.location === value,
	},
	{
		title: 'Хранимая номенклатура',
		dataIndex: 'storedNomenclature',
		render: (storedNomenclature) => storedNomenclature.join(', '),
		...getColumnSearchProps(
			'storedNomenclature',
			data.flatMap((e) => e.storedNomenclature).map((e) => ({ storedNomenclature: e }))
		),
		onFilter: (value, record) => record.storedNomenclature.some((n) => n === value),
	},
	{
		title: 'Цеховой склад',
		dataIndex: 'isWorkshopWarehouse',
	},
]

const fixShiftsColumn: TableColumnsType<WarehouseFixShift> = [
	{
		title: 'Смена',
		dataIndex: ['shift', 'label'],
	},
	{
		title: 'Начало смены',
		dataIndex: 'startTime',
	},
	{
		title: 'Начало смены',
		dataIndex: 'endTime',
	},
	{
		title: 'ПН',
		dataIndex: 'isMonday',
		render: (val) => <Checkbox checked={val} />,
	},
	{
		title: 'ВТ',
		dataIndex: 'isTuesday',
		render: (val) => <Checkbox checked={val} />,
	},
	{
		title: 'СР',
		dataIndex: 'isWednesday',
		render: (val) => <Checkbox checked={val} />,
	},
	{
		title: 'ЧТ',
		dataIndex: 'isThursday',
		render: (val) => <Checkbox checked={val} />,
	},
	{
		title: 'ПТ',
		dataIndex: 'isFriday',
		render: (val) => <Checkbox checked={val} />,
	},
	{
		title: 'СБ',
		dataIndex: 'isSaturday',
		render: (val) => <Checkbox checked={val} />,
	},
	{
		title: 'ВС',
		dataIndex: 'isSunday',
		render: (val) => <Checkbox checked={val} />,
	},
	{
		title: 'Активная смена',
		dataIndex: 'isActiveShift',
		render: (val) => <Checkbox checked={val} />,
	},
]

const fixShiftsItems = [
	{
		label: 'Смена',
		name: ['shift', 'label'],
	},
	{
		label: 'Начало смены',
		name: 'startTime',
	},
	{
		label: 'Начало смены',
		name: 'endTime',
	},
	{
		label: 'ПН',
		name: 'isMonday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		label: 'ВТ',
		name: 'isTuesday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		label: 'СР',
		name: 'isWednesday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		label: 'ЧТ',
		name: 'isThursday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		label: 'ПТ',
		name: 'isFriday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		label: 'СБ',
		name: 'isSaturday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		label: 'ВС',
		name: 'isSunday',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
	{
		label: 'Активная смена',
		name: 'isActiveShift',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
]

const slidingShiftsColumn: TableColumnsType<WarehouseSlidingShift> = [
	{
		title: 'Смена',
		dataIndex: ['shift', 'label'],
	},
	{
		title: 'Начало смены',
		dataIndex: 'startTime',
	},
	{
		title: 'Начало смены',
		dataIndex: 'endTime',
	},
	{
		title: 'Дата начала работы',
		dataIndex: 'startWorkDate',
	},
	{
		title: 'Рабочие дни',
		dataIndex: 'workingDays',
	},
	{
		title: 'Выходные дни',
		dataIndex: 'weekendDays',
	},
	{
		title: 'Активная смена',
		dataIndex: 'isActiveShift',
		render: (val) => <Checkbox checked={val} />,
	},
]

const slidingShiftsItems = [
	{
		label: 'Смена',
		name: ['shift', 'label'],
	},
	{
		label: 'Начало смены',
		name: 'startTime',
	},
	{
		label: 'Начало смены',
		name: 'endTime',
	},
	{
		label: 'Дата начала работы',
		name: 'startWorkDate',
	},
	{
		label: 'Рабочие дни',
		name: 'workingDays',
	},
	{
		label: 'Выходные дни',
		name: 'weekendDays',
	},
	{
		label: 'Активная смена',
		name: 'isActiveShift',
		valuePropName: 'checked',
		component: <Checkbox />,
	},
]

const slidingShiftColumn: TableColumnsType<WarehouseSlidingShift> = [
	{
		title: 'Брэнды',
		dataIndex: 'label',
	},
]

export {
	formItems,
	columns,
	fixShiftsColumn,
	fixShiftsItems,
	slidingShiftColumn,
	slidingShiftsColumn,
	slidingShiftsItems,
}
