import React from 'react'
import { RawMat as RawMatType } from '@isf/backend/src/modules/stocks/modules/raw-mat/entities/raw-mat.entity'
import { useLoaderData } from 'react-router-dom'
import { formItems } from './constants'
import { CustomFormWithTabs } from '../../../components'
import { useForm } from 'antd/es/form/Form'
import { CostTab, InputControlTab, StorageTab } from './tabs'
import { Card, Space } from 'antd'
import Bar from '../../../layout/bar/Bar'

const RawMat = () => {
	const data = useLoaderData() as RawMatType
	const [form] = useForm()
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar />
			<Card style={{ margin: '0 16px' }}>
				<CustomFormWithTabs
					form={form}
					colCount={4}
					rowCount={4}
					items={formItems}
					initialValues={data}
					tabs={[
						{ key: 'storage', children: <StorageTab />, label: 'Хранение' },
						{ key: 'input-control', children: <InputControlTab />, label: 'Вх. контроль' },
						{ key: 'cost', children: <CostTab />, label: 'Себестоимость' },
					]}
				/>
			</Card>
		</Space>
	)
}

export default RawMat
