import { Form, Input, Button, Space, Typography, Col, Row } from 'antd'
import { useAuth } from '../../contexts'
import AuthErrorMsg from './AuthErrorMsg'
import { useNavigate } from 'react-router-dom'
import { inputStyle } from './shared'
const ChangePassword = () => {
	const {
		changePwd,
		resetError,
		state: { errMsg },
	} = useAuth()

	const navigate = useNavigate()

	const styleBtn = {
		fontWeight: 700,
		width: 500,
		height: 50,
		alignSelf: 'center',
		borderRadius: 5,
	}

	return (
		<Row>
			<Col span={6} offset={9}>
				<Space
					direction="vertical"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<Typography.Title level={2} style={{ fontWeight: 700, color: 'rgb(3 52 97)' }}>
						ИЗМЕНЕНИЕ ПАРОЛЯ
					</Typography.Title>
					<Typography.Text
						style={{
							display: 'flex',
							fontSize: 22,
							fontWeight: 700,
							textAlign: 'center',
							paddingBottom: 30,
							color: 'rgb(3 52 97)',
							maxWidth: '500px',
							lineHeight: 1,
						}}
					>
						После изменения пароля произойдет выход из аккаунта на всех устройствах, где вошли с
						текущим паролем
					</Typography.Text>
					<Form colon={false} onFinish={changePwd}>
						<Form.Item name="login" rules={[{ required: true, message: 'Введите логин' }]}>
							<Input
								style={inputStyle}
								type="text"
								onKeyDown={(e) => {
									if (isCyrillics(e.key)) {
										e.preventDefault()
									}
								}}
								placeholder="Логин"
							/>
						</Form.Item>
						<Form.Item
							name="oldPwd"
							style={inputStyle}
							rules={[{ required: true, message: 'Введите пароль' }]}
						>
							<Input
								style={inputStyle}
								type="password"
								onKeyDown={(e) => {
									if (isCyrillics(e.key)) {
										e.preventDefault()
									}
								}}
								placeholder="Текущий пароль"
							/>
						</Form.Item>
						<Form.Item
							name="newPassword"
							rules={[
								{ required: true },
								() => ({
									validator(_, value) {
										const error = validatePwdStepByStep(value)
										if (!value) {
											return Promise.reject(new Error('Введите пароль'))
										}
										if (error.length) {
											return Promise.reject(new Error(error))
										}

										return Promise.resolve()
									},
								}),
							]}
						>
							<Input
								type="password"
								style={inputStyle}
								onKeyDown={(e) => {
									if (isCyrillics(e.key)) {
										e.preventDefault()
									}
								}}
								placeholder="Новый пароль"
							/>
						</Form.Item>
						<Form.Item
							name="newPasswordConfirm"
							dependencies={['newPassword']}
							rules={[
								{ required: true, message: 'Введите пароль' },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('newPassword') === value) {
											return Promise.resolve()
										}
										return Promise.reject(new Error('Пароли не совпадают'))
									},
								}),
							]}
						>
							<Input
								type="password"
								style={inputStyle}
								onKeyDown={(e) => {
									if (isCyrillics(e.key)) {
										e.preventDefault()
									}
								}}
								placeholder="Новый пароль еще раз"
							/>
						</Form.Item>
						{errMsg && <AuthErrorMsg errMsg={errMsg} />}
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Button
								htmlType="submit"
								style={{
									...styleBtn,
									background: 'rgb(3 52 97)',
									color: 'white',
									marginBottom: 20,
								}}
							>
								СОХРАНИТЬ
							</Button>
							<Button
								onClick={() => {
									resetError()
									navigate('/auth/sign-in')
								}}
								style={{
									...styleBtn,
									border: '2px solid rgb(3, 52, 97)',
								}}
							>
								ОТМЕНИТЬ
							</Button>
						</div>
					</Form>
				</Space>
			</Col>
		</Row>
	)
}

export default ChangePassword

export const isCyrillics = (val: string) => {
	return /[А-Яа-я]/.test(val)
}

const passwordValidator = require('password-validator')
const pwdSchemaIsMin = new passwordValidator()
const pwdSchemaHasUppercase = new passwordValidator()
const pwdSchemaHasLowercase = new passwordValidator()
const pwdSchemaHasNotSpaces = new passwordValidator()

pwdSchemaIsMin.is().min(9)
pwdSchemaHasUppercase.has().uppercase()
pwdSchemaHasLowercase.has().lowercase()
pwdSchemaHasNotSpaces.has().not().spaces()

const validatePwdStepByStep = (inPwd) => {
	if (!pwdSchemaIsMin.validate(inPwd)) return 'Введите не менее 9 символов'
	if (!pwdSchemaHasUppercase.validate(inPwd))
		return 'Пароль должен содержать хотя бы одну заглавную букву'
	if (!pwdSchemaHasLowercase.validate(inPwd))
		return 'Пароль должен содержать хотя бы одну строчную букву'
	if (!pwdSchemaHasNotSpaces.validate(inPwd)) return 'Пароль не должен содержать пробелы'
	return ''
}
