import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { Measure as MeasureEntity } from '@isf/backend/src/modules/dictionaries/modules/measure/entities/measure.entity'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import React from 'react'
import { MaskedInput } from '../../../components'

const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['label', 'okei', 'labelShort', 'coefficient', 'targetUnit', 'group']

const Measure = () => {
	const data = useLoaderData() as MeasureEntity[]
	const submit = useSubmit()
	const columns: ColumnsType<MeasureEntity> = [
		{
			title: 'Наименование',
			dataIndex: 'label',
		},
		{
			title: ' Краткое наименование',
			dataIndex: 'labelShort',
		},
		{
			title: 'Коэффициент для перевода',
			dataIndex: 'coefficient',
		},
		{
			title: 'Целевая единица',
			dataIndex: 'targetUnit',
		},
		{
			title: 'Группа',
			dataIndex: 'group',
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'label',
			label: 'Наименование',
			rules: [{ required: true }],
			...layout,
		},
		{
			name: 'labelShort',
			label: 'Краткое наименование',
			rules: [{ required: true }],
			...layout,
		},
		{
			name: 'coefficient',
			label: 'Коэффициент для перевода',
			component: <MaskedInput scale={10} max={99999.9999999999} allowNegative={false} />,
			rules: [{ required: true }],
			...layout,
		},
		{
			name: 'targetUnit',
			label: 'Целевая единица',
			rules: [{ required: true }],
			...layout,
		},
		{
			name: 'group',
			label: 'Группа',
			rules: [{ required: true }],
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default Measure
