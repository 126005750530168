import React from 'react'
import { Form, Input, Button, Row, Col, Typography, Space } from 'antd'
import { Link } from 'react-router-dom'
import '../../index.css'
import { useAuth } from '../../contexts'
import AuthErrorMsg from './AuthErrorMsg'
import { inputStyle } from './shared'

const Login = () => {
	const {
		state: { errMsg },
		signIn,
		resetError,
	} = useAuth()

	return (
		<Row
			style={{
				background: 'url(/login.webp) no-repeat center fixed',
				backgroundSize: 'cover',
			}}
		>
			<Col span={6} offset={9}>
				<Space
					direction="vertical"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<Typography.Title
						style={{ fontSize: 25, fontWeight: 700, paddingBottom: 10, color: 'rgb(3 52 97)' }}
					>
						АВТОРИЗАЦИЯ
					</Typography.Title>
					<Typography.Text
						style={{
							fontSize: 20,
							fontWeight: 700,
							textAlign: 'center',
							paddingBottom: 30,
							color: 'rgb(3 52 97)',
						}}
					>
						Введите логин и пароль
					</Typography.Text>
					<Form colon={false} onFinish={signIn}>
						<Form.Item
							name="login"
							rules={[
								{ required: true, message: 'Введите логин' },
								{ pattern: new RegExp(/^[^а-яА-Я]*$/), message: '' },
							]}
						>
							<Input style={inputStyle} placeholder="Логин" />
						</Form.Item>
						<Form.Item name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
							<Input.Password
								className="password-input"
								type="password"
								style={inputStyle}
								placeholder="Пароль"
							/>
						</Form.Item>
						{errMsg && <AuthErrorMsg errMsg={errMsg} />}
						<Button
							htmlType="submit"
							style={{
								fontWeight: 600,
								width: 500,
								height: 50,
								alignSelf: 'center',
								background: 'rgb(3 52 97)',
								color: 'white',
								borderRadius: 5,
							}}
						>
							ВОЙТИ
						</Button>
						<Row>
							<Col
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									height: 80,
									fontWeight: 600,
								}}
							>
								<Link
									style={{ color: 'rgb(3 52 97)' }}
									to="/auth/change-password"
									onClick={resetError}
								>
									Сменить пароль?
								</Link>
								<Link
									style={{ color: 'rgb(3 52 97)' }}
									to="/auth/forgot-password"
									onClick={resetError}
								>
									Забыли пароль?
								</Link>
							</Col>
						</Row>
					</Form>
				</Space>
			</Col>
		</Row>
	)
}

export default Login
