import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../../utils/helpers'
import { type LoaderFunction, type ActionFunction, redirect } from 'react-router-dom'
import { TmcManufacturer } from '@isf/backend/src/modules/dictionaries/modules/tmc-manufacturer/entities/tmc-manufacturer.entity'

const url = 'dictionaries/tmc-manufacturers'
const queryKey = ['dictionaries', 'tmc-manufacturers']

const tmcManufacturerListQuery = () => ({
	queryKey,
	queryFn: (): Promise<TmcManufacturer[]> => axios.get(url).then((response) => response.data),
})

const tmcManufacturerQuery = (id: TmcManufacturer['id']) => ({
	queryKey: [...queryKey, id],
	queryFn: (): Promise<TmcManufacturer[]> =>
		axios.get(`${url}/${id}`).then((response) => response.data),
})

export const listLoader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = tmcManufacturerListQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const loader: (arg0: QueryClient) => LoaderFunction =
	(queryClient) =>
	async ({ params }) => {
		if (params.id && !isNaN(+params.id)) {
			const query = tmcManufacturerQuery(+params.id)
			return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
		}
		return {}
	}

export const action: (arg0: QueryClient) => ActionFunction =
	(queryClient) =>
	async ({ request, params }) => {
		try {
			const values = await request.json()
			const response = await axios({
				method: request.method,
				url: request.method === 'PATCH' ? `${url}/${params.id}` : url,
				data: values,
			})
			await queryClient.invalidateQueries({ queryKey, refetchType: 'all' }, { throwOnError: true })
			return redirect(`${request.url.slice(0, request.url.lastIndexOf('/'))}/${response.data.id}`)
		} catch (err) {
			if (err.isReadable) {
				return { err }
			}
			console.log(err)
			return null
		}
	}
