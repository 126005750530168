import { useLoaderData } from 'react-router-dom'
import { getColumns } from './constants'
import ListTemplate from '../../ListTemplate'
import { RawMatType } from '@isf/backend/src/modules/dictionaries/modules/raw-mat-type/entities/raw-mat-type.entity'
import { Form } from 'antd'
import { useColumnSearchProps } from '../../../utils/hooks'

const RawMatTypeModalList = ({ fieldName }) => {
	const data = useLoaderData() as RawMatType[]
	const { getColumnSearchProps } = useColumnSearchProps(data)
	const form = Form.useFormInstance()
	const values = Form.useWatch(fieldName)
	return (
		<ListTemplate
			columns={getColumns(getColumnSearchProps, form, fieldName, values)}
			dataSource={data}
			noRowButtons
			searchFields={['articul', 'label', 'origin', 'rawMatCategory.label']}
		/>
	)
}

export default RawMatTypeModalList
