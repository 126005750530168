import { Checkbox, FormInstance, Select, Typography } from 'antd'
import { MaskedInput, SelectFetch } from '../../../components'
import { SorterTypes, sorter } from '../../../utils/helpers'
import { RawMatType } from '@isf/backend/src/modules/dictionaries/modules/raw-mat-type/entities/raw-mat-type.entity'
import { ColumnProps } from 'antd/es/table'
import { CompareFn } from 'antd/es/table/interface'

const layout = { labelCol: { span: 8 }, wrapperCol: { offset: 0, span: 12 } }
const layout2 = { labelCol: { span: 12 }, wrapperCol: { offset: 0, span: 12 } }
const layout3 = { labelCol: { offset: 3, span: 8 }, wrapperCol: { offset: 0, span: 12 } }

const getRawMatTypeCalories = ({ carbs, fats, proteins }) =>
	(+(proteins || 0) * 4 + +(fats || 0) * 9 + +(carbs || 0) * 3.75).toFixed(1)

const getFormItems = (data: RawMatType, { isHasNutritionalValue, measure, origin }) => [
	{
		name: 'articul',
		label: 'Артикул',
		disabled: false,
		readOnly: true,
		...layout,
	},
	{
		name: 'label',
		label: 'Наименование',
		required: true,
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'rawMatCategory',
		label: 'Категория',
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: <SelectFetch url="/dictionaries/raw-mat-categories" />,
		required: true,
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'origin',
		label: 'Происхождение',
		required: true,
		rules: [
			{ required: true },
			({ setFieldValue }) => ({
				validator(_, value) {
					if (value === 'Другое') {
						setFieldValue('isHasNutritionalValue', false)
					} else {
						setFieldValue('isHasNutritionalValue', true)
					}
					return Promise.resolve(true)
				},
			}),
		],
		component: (
			<Select
				options={[
					{ value: 'Растительное' },
					{ value: 'Животное' },
					{ value: 'Комбинированное' },
					{ value: 'Другое' },
				]}
			/>
		),
		...layout,
	},
	{
		name: 'measure',
		label: 'Единица измерения',
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: (
			<SelectFetch
				url={`/dictionaries/measures?filterBy=group:${encodeURIComponent('Экономические единицы')}`}
				field="measure"
			/>
		),
		required: true,
		rules: [{ required: true }],
		...layout2,
	},
	{
		name: 'isHasNutritionalValue',
		label: 'Имеет пищевую ценность',
		valuePropName: 'checked',
		disabled: origin === 'Другое',
		component: <Checkbox />,
		...layout2,
	},
	...(measure && measure.labelShort !== 'кг'
		? [
				{
					name: 'kgPerUnit',
					required: true,
					rules: [{ required: measure && measure.labelShort }],
					label: 'В 1 единице измерения, кг',
					component: <MaskedInput max={999.999} scale={3} allowNegative={false} />,
					...layout2,
				},
		  ]
		: []),
	...(isHasNutritionalValue
		? [
				{
					name: 'isMercuryAccountableProds',
					label: 'Подотчетная Меркурию продукция',
					valuePropName: 'checked',
					component: <Checkbox />,
					...layout2,
				},
				...(!(isHasNutritionalValue && measure?.label && measure.labelShort !== 'кг')
					? [
							{
								key: 'kgPerUnit-replacement-gap',
								...layout2,
								component: null,
							},
					  ]
					: []),
				{
					name: 'proteins',
					label: 'Белки, г',
					component: (
						<MaskedInput defaultValue={data.proteins} scale={1} allowNegative={false} max={999.9} />
					),
					required: true,
					rules: [{ required: true }],
					...layout3,
				},
				{
					name: 'fats',
					label: 'Жиры',
					component: (
						<MaskedInput defaultValue={data.fats} scale={1} allowNegative={false} max={999.9} />
					),
					required: true,
					rules: [{ required: true }],
					...layout3,
				},
				{
					name: 'carbs',
					label: 'Углеводы, г',
					component: (
						<MaskedInput defaultValue={data.carbs} scale={1} allowNegative={false} max={999.9} />
					),
					required: true,
					rules: [{ required: true }],
					...layout3,
				},
				{
					label: 'Калорийность, ккал',
					component: <Typography.Text>{getRawMatTypeCalories(data)}</Typography.Text>,
					...layout3,
				},
		  ]
		: []),
]

const getColumns: (
	getColumnSearchProps,
	form?: FormInstance,
	fieldName?: Pick<RawMatType, keyof RawMatType>,
	values?: { rawMatType: RawMatType }[]
) => ColumnProps<RawMatType>[] = (getColumnSearchProps, form, fieldName, values = []) => [
	...(form
		? [
				{
					key: 'select',
					render: (record: RawMatType) => {
						const checked = !!values.find((v) => v.rawMatType?.id === record.id)
						return (
							<Checkbox
								checked={checked}
								onChange={(e) =>
									e.target.checked
										? form?.setFieldValue(fieldName, [
												...values,
												{ rawMatType: record, priority: 1 },
										  ])
										: form?.setFieldValue(
												fieldName,
												values.filter((v) => v.rawMatType?.id !== record.id)
										  )
								}
							/>
						)
					},
				},
		  ]
		: []),
	{
		title: 'Артикул',
		dataIndex: 'articul',
		sorter: sorter(SorterTypes.number, 'id') as CompareFn<RawMatType>,
		...getColumnSearchProps('articul'),
	},
	{
		title: 'Наименование',
		dataIndex: 'label',
		sorter: sorter(SorterTypes.string, 'label') as CompareFn<RawMatType>,
		...getColumnSearchProps('label'),
	},
	{
		title: 'Категория',
		dataIndex: ['rawMatCategory', 'label'],
		...getColumnSearchProps(['rawMatCategory', 'label']),
	},
	{
		title: 'Происхождение',
		dataIndex: 'origin',
		...getColumnSearchProps('origin'),
	},
]

export { getRawMatTypeCalories, getFormItems, getColumns }
