import React from 'react'
import { Form, Input, Radio, Space, TableColumnsType, Typography } from 'antd'
import { Supply } from '@isf/backend/src/modules/supply/entities/supply.entity'
import { viewDateFormat } from '@isf/shared/constants/constants'
import { DatePicker, SelectFetch } from '../../components'
import { DateTime } from 'luxon'
import { CompareFn } from 'antd/es/table/interface'
import { SorterTypes, sorter } from '../../utils/helpers'

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } }

const searchFields = [
	'type',
	'id',
	'date',
	['provider', 'label'],
	['purchaser', 'label'],
	'doc',
	'nomenclature',
	'status',
]

export enum SupplyTypeEnum {
	rawMat = 'Сырье',
	tmc = 'ТМЦ',
}

export enum SupplyDocEnum {
	invoice = 'Накладная',
	check = 'Чек',
	noCheck = 'Без чека',
}

const formItems = [
	{
		label: '№ поставки',
		component: <Typography.Text />,
		...layout,
	},
	{
		label: 'Дата поставки',
		name: 'date',
		component: <DatePicker format={viewDateFormat} />,
		...layout,
	},
	{
		label: 'Тип поставки',
		name: 'type',
		component: (
			<Radio.Group>
				<Space>
					<Radio value={SupplyTypeEnum.rawMat}>Сырье</Radio>
					<Radio value={SupplyTypeEnum.tmc}>ТМЦ</Radio>
				</Space>
			</Radio.Group>
		),
		...layout,
	},
	{
		name: 'organization',
		label: 'Организация заказчик',
		required: true,
		rules: [{ required: true }],
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: (
			<SelectFetch
				url="dictionaries/organizations"
				showSearch
				filterOption={(inputValue, option) =>
					String(option?.label).toLowerCase().includes(String(inputValue).toLowerCase())
				}
			/>
		),
		...layout,
	},
	{
		label: 'Документ приемки',
		name: 'doc',
		component: (
			<Radio.Group>
				<Space>
					<Radio value={SupplyDocEnum.invoice}>Накладная</Radio>
					<Radio value={SupplyDocEnum.check}>Чек</Radio>
					<Radio value={SupplyDocEnum.noCheck}>Без чека</Radio>
				</Space>
			</Radio.Group>
		),
		...layout,
	},
	{
		shouldUpdate: (prev: Supply, next: Supply) => prev.doc !== next.doc,
		conditionalComponent: ({ getFieldValue }) => {
			const doc = getFieldValue(['doc'])
			const isInvoice = doc === SupplyDocEnum.invoice
			const name = isInvoice ? 'provider' : 'purchaser'
			const label = isInvoice ? 'Поставщик' : 'Закупщик'
			const dataUrl = isInvoice
				? getFieldValue(['type']) === SupplyTypeEnum.rawMat
					? 'dictionaries/providers'
					: 'dictionaries/tmc-providers'
				: 'staff'
			return (
				<Form.Item
					name={name}
					label={label}
					required
					rules={[{ required: false }]}
					getValueFromEvent={({ value, label }: { value: number; label: string }) => ({
						id: value,
						label,
					})}
					{...layout}
				>
					<SelectFetch url={dataUrl} />
				</Form.Item>
			)
		},
	},
	{
		noStyle: true,
		shouldUpdate: (prev: Supply, next: Supply) => prev.doc !== next.doc,
		conditionalComponent: ({ getFieldValue }) => {
			const isNoCheck = getFieldValue(['doc']) === SupplyDocEnum.noCheck
			if (!isNoCheck) {
				return (
					<Form.Item
						name="docNum"
						label="№ документа"
						required
						rules={[{ required: true }]}
						children={<Input />}
						{...layout}
					/>
				)
			}
		},
	},
	{
		noStyle: true,
		shouldUpdate: (prev: Supply, next: Supply) => prev.doc !== next.doc,
		conditionalComponent: ({ getFieldValue }) => {
			const isNoCheck = getFieldValue(['doc']) === SupplyDocEnum.noCheck
			if (!isNoCheck) {
				return (
					<Form.Item
						name="date"
						label="Дата документа"
						required
						rules={[{ required: true }]}
						children={<DatePicker format={viewDateFormat} />}
						{...layout}
					/>
				)
			}
		},
	},
	{
		label: 'Статус',
		// readOnly: true,
		name: 'status',
		dataIndex: 'status',
		...layout,
		disabled: true,
	},
	{
		name: 'filledBy',
		label: 'Заполнил',
		required: true,
		rules: [{ required: true }],
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: <SelectFetch url="staff" />,
		...layout,
	},
	{
		label: 'Комментарий',
		dataIndex: 'comment',
		...layout,
	},
]

const getColumns: (getColumnSearchProps) => TableColumnsType<Supply> = (getColumnSearchProps) => [
	{
		title: 'Тип поставки',
		dataIndex: 'type',
		...getColumnSearchProps('type'),
		sorter: sorter(SorterTypes.string, 'type') as CompareFn<Supply>,
	},
	{
		title: '№ поставки',
		dataIndex: 'id',
		...getColumnSearchProps('id'),
		sorter: sorter(SorterTypes.number, 'id') as CompareFn<Supply>,
	},
	{
		title: 'Дата поставки',
		dataIndex: 'date',
		render: (date) => DateTime.fromISO(date).toFormat(viewDateFormat),
		...getColumnSearchProps('date'),
		sorter: sorter(SorterTypes.date, 'date') as CompareFn<Supply>,
	},
	{
		title: 'Документ приемки',
		dataIndex: 'doc',
		...getColumnSearchProps('doc'),
	},
	{
		title: 'Поставщик / Закупщик',
		render: (record) => record.provider?.label || record.purchaser.label,
		...getColumnSearchProps('type'),
	},
	{
		title: 'Номенклатура',
		dataIndex: 'nomenclature',
		...getColumnSearchProps('nomenclature'),
	},
	{
		title: 'Статус',
		dataIndex: 'status',
		...getColumnSearchProps('status'),
		value: 'value',
		name: 'status',
	},
]

export { formItems, getColumns, searchFields }
