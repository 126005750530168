import { FormInstance, FormProps, TabsProps } from 'antd'
import CustomForm from '../custom-form/CustomForm'
import TabList from '../tab-list/TabList'

const CustomFormWithTabs = ({
	tabs,
	modal = null,
	...formProps
}: {
	tabs: TabsProps['items']
	form: FormInstance
	modal: React.JSX.Element | null
	rest: FormProps
}) => {
	return (
		<CustomForm {...formProps}>
			<>
				<TabList tabs={tabs} />
				{modal && React.cloneElement(modal, { forceRender: true })}
			</>
		</CustomForm>
	)
}

export default CustomFormWithTabs
