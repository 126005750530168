import { TableColumnsType, Typography } from 'antd'
import { TmcBrand } from '@isf/backend/src/modules/dictionaries/modules/tmc-manufacturer/entities/tmc-brand.entity'
import { TmcManufacturer } from '@isf/backend/src/modules/dictionaries/modules/tmc-manufacturer/entities/tmc-manufacturer.entity'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { Brand } from '@isf/backend/src/modules/dictionaries/modules/manufacturer/entities/brand.entity'
import { CompareFn } from 'antd/es/table/interface'

const layout = { labelCol: { span: 3 }, wrapperCol: { span: 5 } }

const formItems = [
	{
		name: 'label',
		label: 'Производитель',
		required: true,
		rules: [{ required: true }],
		...layout,
	},
]

const getColumns: (
	arg: TmcManufacturer[],
	getColumnSearchProps
) => TableColumnsType<TmcManufacturer> = (data, getColumnSearchProps) => [
	{
		title: 'Производитель',
		dataIndex: 'label',
		sorter: sorter(SorterTypes.string, 'label') as CompareFn<TmcManufacturer>,
		...getColumnSearchProps('label'),
		width: '50%',
	},
	{
		title: 'Брэнды',
		...getColumnSearchProps(
			'brandLabel',
			data.flatMap((record) => record.tmcBrands.map((b) => ({ brandLabel: b.label })))
		),
		filters: data.flatMap((record) =>
			record.tmcBrands.map((b) => ({ text: b.label, value: b.id }))
		),
		onFilter: (value, record) => record.tmcBrands.some((b) => b.label === value),
		key: 'tmcBrands',
		render: (record) => (
			<Typography.Text
				style={{ width: '120ch' }}
				ellipsis={{
					tooltip: true,
				}}
			>
				{record.tmcBrands.map((b: Brand) => b.label).join(', ')}
			</Typography.Text>
		),
	},
]

const brandsColumns: TableColumnsType<TmcBrand> = [
	{
		title: 'Брэнды',
		dataIndex: 'label',
	},
]

export { formItems, getColumns, brandsColumns }
