import React from 'react'
import { Checkbox, TableColumnsType } from 'antd'
import { Staff } from '@isf/backend/src/modules/staff/entities/staff.entity'
import { SelectFetch, StatusCell } from '../../components'
import { RoleType } from '@isf/backend/src/modules/dictionaries/modules/role-type/entities/role-type.entity'
import { axios, SorterTypes, sorter } from '../../utils/helpers'
import { CompareFn } from 'antd/es/table/interface'
import { Modes, modes } from '../../utils/constants'
import validator from 'validator'
import debounce from 'debounce-promise'

const layout1 = { labelCol: { span: 10 }, wrapperCol: { span: 12 } }
const layout2 = { labelCol: { span: 12 }, wrapperCol: { span: 10 } }
const layout3 = { labelCol: { span: 10 }, wrapperCol: { span: 14 } }

const getType = (record: Staff) => {
	if (record.isAdmin) {
		return 'Администратор системы'
	}
	if (record.roleType.isUser) {
		return 'Пользователь'
	}
	return null
}

const debouncedUniqueEmailCheck = debounce(
	(email) =>
		Promise.resolve(
			axios('/staff/check-unique-email', { params: { email } }).then((result) => result.data)
		),
	500
)

const debouncedUniqueLoginCheck = debounce(
	(login) =>
		Promise.resolve(
			axios('/staff/check-unique-login', { params: { login } }).then((result) => result.data)
		),
	500
)

const getFormItems = (
	noPatronymic: boolean,
	isWorkWithMercury: boolean,
	isUser: RoleType['isUser'],
	mode: Modes
) => [
	{
		label: 'Табельный номер',
		disabled: true,
		dataIndex: 'id',
		readOnly: true,
		...layout1,
	},
	{
		name: 'surname',
		label: 'Фамилия',
		required: true,
		rules: [{ required: true }, { pattern: new RegExp(/^[а-яА-Я]*$/), message: '' }],
		...layout1,
	},
	{
		name: 'name',
		label: 'Имя',
		required: true,
		rules: [{ required: true }, { pattern: new RegExp(/^[а-яА-Я]*$/), message: '' }],
		...layout1,
	},
	{
		name: 'patronymic',
		label: 'Отчество',
		required: !noPatronymic,
		disabled: noPatronymic,
		rules: [{ required: !noPatronymic }, { pattern: new RegExp(/^[а-яА-Я]*$/), message: '' }],
		...layout1,
	},
	{
		name: 'noPatronymic',
		label: 'Нет отчества',
		valuePropName: 'checked',
		component: <Checkbox />,
		rules: [
			({ setFieldValue }) => ({
				validator(_, value: boolean) {
					if (value === true) {
						setFieldValue('patronymic', null)
					}
					return Promise.resolve()
				},
			}),
		],
		...layout1,
	},
	{
		key: 'first-col-gap',
		...layout1,
		component: null,
	},
	{
		name: 'position',
		label: 'Должность',
		required: true,
		rules: [{ required: true }],
		...layout2,
	},
	{
		name: 'roleType',
		label: 'Роль',
		required: true,
		rules: [{ required: true }],
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: <SelectFetch url="dictionaries/role-types" field="roleType" />,
		...layout2,
	},
	{
		name: 'workCenter',
		label: 'Рабочий центр',
		required: true,
		rules: [{ required: true }],
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: <SelectFetch url="dictionaries/work-centers" />,
		...layout2,
	},
	{
		name: 'isWorkWithMercury',
		label: 'Работает с Меркурием',
		valuePropName: 'checked',
		component: <Checkbox />,
		...layout2,
	},
	...(isWorkWithMercury
		? [
				{
					name: 'mercuryLogin',
					label: 'Логин для входа в Меркурий',
					required: true,
					rules: [{ required: true }],
					...layout2,
				},
		  ]
		: [
				{
					key: 'mercuryLogin-replacement-gap',
					...layout2,
					component: null,
				},
		  ]),
	{
		key: 'second-col-gap',
		...layout2,
		component: null,
	},
	{
		name: 'phone',
		label: 'Моб. телефон',
		required: isUser,
		rules: [{ required: isUser }],
		...layout3,
	},
	{
		name: 'email',
		label: 'E-mail',
		required: isUser,
		rules: [
			{ required: isUser },
			() => ({
				async validator(_, value) {
					if (!value) {
						return Promise.resolve()
					}
					if (!validator.isEmail(value)) {
						return Promise.reject()
					}
					const isUnique = await debouncedUniqueEmailCheck(value)
					if (!isUnique) {
						return Promise.reject('Данный E-mail уже используется')
					}
				},
			}),
		],
		...layout3,
	},
	{
		name: 'status',
		label: 'Статус',
		required: true,
		rules: [{ required: true }],
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: <SelectFetch url="dictionaries/staff-statuses" />,
		...layout3,
	},
	{
		name: 'GPH',
		label: 'ГПХ',
		valuePropName: 'checked',
		component: <Checkbox />,
		...layout3,
	},
	...(isUser
		? [
				{
					name: 'login',
					label: 'Логин',
					required: isUser,
					disabled: mode === modes.edit,
					rules: [
						{ required: true },
						() => ({
							async validator(_, value) {
								if (!value) {
									return Promise.resolve()
								}
								const isUnique = await debouncedUniqueLoginCheck(value)
								if (!isUnique) {
									return Promise.reject('Данный логин уже используется')
								}
							},
						}),
					],
					...layout3,
				},
				{
					name: 'isAdmin',
					label: 'Администратор системы',
					valuePropName: 'checked',
					component: <Checkbox />,
					...layout3,
				},
		  ]
		: []),
]

const getColumns: (getColumnSearchProps) => TableColumnsType<Staff> = (getColumnSearchProps) => [
	{
		title: 'Табельный номер',
		dataIndex: 'id',
		width: '12%',
		sorter: sorter(SorterTypes.number, 'id') as CompareFn<Staff>,
	},
	{
		title: 'Фамилия',
		dataIndex: 'surname',
		sorter: sorter(SorterTypes.string, 'surname') as CompareFn<Staff>,
	},
	{
		title: 'Имя',
		dataIndex: 'name',
	},
	{
		title: 'Отчество',
		dataIndex: 'patronymic',
	},
	{
		title: 'Должность',
		dataIndex: 'position',
		...getColumnSearchProps('position'),
	},
	{
		title: 'Роль',
		dataIndex: ['roleType', 'label'],
		key: 'role',
		...getColumnSearchProps(['roleType', 'label']),
	},
	{
		title: 'Рабочий центр',
		dataIndex: ['workCenter', 'label'],
		...getColumnSearchProps(['workCenter', 'label']),
	},
	{
		title: 'Статус',
		dataIndex: ['status', 'label'],
		render: (status) => <StatusCell status={status} />,
		...getColumnSearchProps(['status', 'label']),
	},
	{
		title: 'Тип',
		key: 'type',
		render: getType,
		...getColumnSearchProps(['roleType', 'isUser'], undefined, {
			true: 'Пользователь',
			false: 'Пусто',
		}),
	},
]

export { getFormItems, getColumns, getType }
