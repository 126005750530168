import { Radio, TableColumnsType } from 'antd'
import { WorkCenter } from '@isf/backend/src/modules/dictionaries/modules/work-center/entities/work-center.entity'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { CompareFn } from 'antd/es/table/interface'

const layout = { labelCol: { span: 7 }, wrapperCol: { span: 14 } }

const formItems = [
	{
		name: 'label',
		label: 'Наименование РЦ',
		required: true,
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'slidingOrFixShifts',
		label: 'Смены',
		required: true,
		component: (
			<Radio.Group style={{ display: 'flex' }}>
				<Radio value={'Фиксированные'}>Фиксированные</Radio>
				<Radio value={'Скользящие'}>Скользящие</Radio>
			</Radio.Group>
		),
		rules: [
			{ required: true },
			({ setFieldValue }) => ({
				validator(_, value) {
					value === 'Фиксированные'
						? setFieldValue('slidingShifts', [])
						: setFieldValue('fixShifts', [])
					return Promise.resolve()
				},
			}),
		],
		...layout,
	},
	{
		name: '_1cUuid',
		label: 'Идентификатор 1С',
		...layout,
	},
]

const getColumns: (data, getColumnSearchProps) => TableColumnsType<WorkCenter> = (
	data,
	getColumnSearchProps
) => [
	{
		title: 'Наименование рабочего центра',
		dataIndex: 'label',
		sorter: sorter(SorterTypes.string, 'label') as CompareFn<WorkCenter>,
		...getColumnSearchProps('label'),
	},
	{
		title: 'Смены',
		dataIndex: 'slidingOrFixShifts',
		...getColumnSearchProps(
			'shiftLabel',
			data.map((e) => ({ shiftLabel: e.slidingOrFixShifts }))
		),
		onFilter: (value, record) => record.slidingOrFixShifts === value,
	},
]

export { formItems, getColumns }
