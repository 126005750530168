import React from 'react'
import { Card, Col, FormInstance, Row, Space } from 'antd'
import ControlButtons from './ControlButtons'
import Breadcrumbs from './Breadcrumbs'
import { Search } from './Search'
import { useMode, useList } from '../../contexts'
import { modes } from '../../utils/constants'

const Bar = <T,>({
	form,
	buttons,
	showSearch = true,
}: {
	form?: FormInstance<T>
	buttons?: React.JSX.Element[]
	showSearch?: boolean
}) => {
	const { state } = useMode()
	const {
		state: { textFilter },
		setTextFilter,
	} = useList()
	return (
		<Row>
			<Col flex={1}>
				<Card
					size="small"
					style={{ margin: '8px 16px 0' }}
					bodyStyle={{ display: 'flex', alignItems: 'center' }}
				>
					<Breadcrumbs />
					<Space>
						{state.mode === modes.view && showSearch && (
							<Search search={setTextFilter} textFilter={textFilter} />
						)}
						<ControlButtons form={form} buttons={buttons} />
					</Space>
				</Card>
			</Col>
		</Row>
	)
}

export default Bar
