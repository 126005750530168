import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { CommonObjectPropType } from '../../types/common'

type TableRowButtonsType<T> = {
	onEdit?: (arg0: T) => void
	onDelete?: (arg0: T) => void
	record: T
}

const TableRowButtons = <T extends CommonObjectPropType>({
	record,
	onEdit,
	onDelete,
}: TableRowButtonsType<T>) => {
	return (
		<Space>
			{onEdit && (
				<Button
					type="text"
					icon={<EditOutlined style={{ fontSize: 20 }} />}
					style={{
						display: 'inlineFlex',
						alignItems: 'center',
					}}
					onClick={() => onEdit(record)}
				/>
			)}
			{onDelete && (
				<Button
					type="text"
					icon={<DeleteOutlined style={{ fontSize: 20 }} />}
					style={{
						display: 'inlineFlex',
						alignItems: 'center',
					}}
					onClick={() => onDelete(record)}
				/>
			)}
		</Space>
	)
}

export default TableRowButtons
