import React from 'react'
import { Image, Typography } from 'antd'
import styles from './menu.module.scss'
import { menuPositions, useMenu } from '../../contexts'
import { logo } from '../../assets'

const Logo = () => {
	const {
		state: { collapsed, position },
	} = useMenu()
	return (
		<div className={styles.logoWithText}>
			<Image src={logo} width={60} preview={false} />
			{collapsed && position === menuPositions.left ? null : (
				<Typography.Text className={collapsed ? styles.logoTextCollapsed : styles.logoText}>
					system
				</Typography.Text>
			)}
		</div>
	)
}

export default Logo
