import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../../dictionaries/DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { ShipmentWave as ShipmentWaveEntity } from '@isf/backend/src/modules/system-settings/modules/shipment-wave/entities/shipment-wave.entity'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import { TimePicker } from '../../../components'
import { DateTime } from 'luxon'

const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['label']

const ShipmentWave = () => {
	const data = useLoaderData() as ShipmentWaveEntity[]
	const submit = useSubmit()
	const columns: ColumnsType<ShipmentWaveEntity> = [
		{
			title: 'Волна отгрузки',
			dataIndex: 'id',
		},
		{
			title: 'Начало отгрузки',
			dataIndex: 'startShipmentTime',
			render: (value) => DateTime.fromISO(value).toFormat('HH:mm'),
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'startShipmentTime',
			label: 'Начало отгрузки',
			rules: [{ required: true }],
			getValueFromEvent: (args) => DateTime.fromISO(args).toFormat('HH:mm:ss'),
			getValueProps: (i) => ({
				value: i ? DateTime.fromISO(i) : i,
			}),
			component: <TimePicker />,
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			showSearch={false}
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default ShipmentWave
