import React from 'react'
import { Button, FormInstance, FormListOperation, Input, Select, Typography } from 'antd'
import { MaskedInput, SelectFetch } from '../../../../components'
import { DeleteOutlined } from '@ant-design/icons'
import lodash from 'lodash'
import { TechCardManufProd } from '@isf/backend/src/modules/tech-card/entities/manuf-prod.entity'
import { TechCard } from '@isf/backend/src/modules/tech-card/entities/tech-card.entity'

const getColumns = (
	remove: FormListOperation['remove'],
	form: FormInstance,
	workCenter: TechCard['workCenter'],
	manufProds: TechCardManufProd[]
) => [
	{
		width: '1%',
		title: null,
		readOnly: true,
		key: 'expandIcon',
	},
	{
		title: 'Шаг',
		key: 'step',
		width: '5%',
		readOnly: true,
		render: (_, __, index) => (typeof index == 'number' ? index + 1 : index.slice(0, 1)[0] + 1),
	},
	{
		title: 'Тип обработки',
		width: '10%',
		formItemName: ['processType'],
		dataIndex: ['processType'],
		getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
			id: value,
			label,
		}),
		component: <SelectFetch url="/dictionaries/process-types" />,
	},
	{
		title: 'Описание технологии',
		dataIndex: 'technologyDesc',
		width: '10%',
		component: <Input.TextArea rows={1} />,
	},
	{
		title: 'Приоритет',
		width: '7%',
		dataIndex: 'priority',
		component: (
			<Select
				style={{ width: '100%' }}
				options={[...Array(100 + 1).keys()].slice(1).map((el) => ({ text: el, value: el }))}
			/>
		),
	},
	{
		title: 'Тип оборудования',
		width: '10%',
		dataIndex: ['equipment'],
		valuePropName: ['equipmentType', 'type'],
		getValueFromEvent: (data) =>
			data
				? {
						id: data.value,
						equipmentType: {
							type: data.label,
						},
				  }
				: undefined,
		render: (_, record) => (
			<SelectFetch
				style={{ width: 250 }}
				url={`dictionaries/equipment${
					workCenter ? '?filterBy=workCenter.id:' + workCenter.id : ''
				}`}
				label={['equipmentType', 'type']}
				value={record?.equipment?.equipmentType?.type}
				allowClear
			/>
		),
	},
	{
		title: 'Линия / Модель оборуд.',
		width: '12%',
		dataIndex: ['equipment'],
		valuePropName: ['equipmentType', 'model'],
		getValueFromEvent: (data) =>
			data
				? {
						id: data.value,
						equipmentType: {
							model: data.label,
						},
				  }
				: undefined,
		render: (_, record, index) => {
			let url = 'dictionaries/equipment'
			if (workCenter?.id) {
				url += '?filterBy=workCenter.id:' + workCenter.id
			}
			if (record.equipment?.equipmentType?.type) {
				const sign = workCenter?.id ? '&' : '?'
				url +=
					`${sign}filterBy=equipmentType.type:` +
					encodeURIComponent(record.equipment.equipmentType.type)
			}
			return (
				<SelectFetch
					style={{ width: 150 }}
					url={url}
					label={['equipmentType', 'model']}
					field={['cookTechnologies', index, 'equipment']}
					value={record?.equipment?.equipmentType?.model}
					allowClear
				/>
			)
		},
	},
	{
		title: 'Кол-во операторов',
		width: '10%',
		dataIndex: 'numberOfOperators',
		component: <MaskedInput scale={0} allowNegative={false} />,
	},
	{
		title: 'Производительность',
		width: '25%',
		components: [
			{
				dataIndex: 'productivity',
				component: <MaskedInput max={99999.99} style={{ width: 75 }} allowNegative={false} />,
			},
			{
				dataIndex: 'productivityMeasure',
				render: (value) => {
					if (manufProds?.[0]?.measure) {
						if (manufProds[0].measure === 'шт') {
							return <Select style={{ width: 70 }} options={[{ value: 'шт' }, { value: 'кг' }]} />
						} else {
							return (
								<Typography.Text style={{ width: 20, display: 'block' }}>{value}</Typography.Text>
							)
						}
					}
				},
			},
			{
				dataIndex: 'productivityTime',
				component: <MaskedInput max={999.99} allowNegative={false} style={{ width: 75 }} />,
			},
			{
				dataIndex: ['productivityTimeMeasure', 'labelShort'],
				formItemName: 'productivityTimeMeasure',
				rules: [{ required: true }],
				getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
					id: value,
					label,
				}),
				component: (
					<SelectFetch
						style={{ width: 75 }}
						url={`/dictionaries/measures?filterBy=group:${encodeURIComponent('Время')}`}
						label="labelShort"
					/>
				),
			},
		],
	},
	// {
	// 	title: null,
	// 	key: 'add',
	// 	width: '5%',
	// 	render: (_, record, index) =>
	// 		!record?.parent ? (
	// 			<Button
	// 				onClick={(e) => {
	// 					const fieldName = ['cookTechnologies', index, 'analogs']
	// 					const analog = lodash.cloneDeep(record)
	// 					delete analog.id
	// 					delete analog.analogs
	// 					form.setFieldValue(fieldName, [
	// 						...(form.getFieldValue(fieldName) || []),
	// 						{ ...analog, parent: record },
	// 					])
	// 				}}
	// 			>
	// 				Добавить аналог
	// 			</Button>
	// 		) : null,
	// },
	{
		title: null,
		width: '5%',
		key: 'remove',
		render: (_, __, index) => (
			<Button type="text" icon={<DeleteOutlined />} onClick={() => remove(index)} />
		),
	},
]

export { getColumns }
