import { useLoaderData } from 'react-router-dom'
import { formItems } from './constants'
import { CustomFormWithTabs } from '../../../components'
import { useForm } from 'antd/es/form/Form'
import { WorkCenter as WorkCenterType } from '@isf/backend/src/modules/dictionaries/modules/work-center/entities/work-center.entity'
import { EquipmentTab, FixShiftTab, SlidingShiftTab, StaffTab } from './tabs'
import { Card, Form, Space } from 'antd'
import Bar from '../../../layout/bar/Bar'
import { InternalNamePath } from 'antd/es/form/interface'

const WorkCenter = () => {
	const data = useLoaderData() as WorkCenterType
	const [form] = useForm()
	const slidingOrFixShifts = Form.useWatch('slidingOrFixShifts', form)
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar form={form} />
			<Card style={{ margin: '0 16px' }}>
				<CustomFormWithTabs
					form={form}
					colCount={3}
					rowCount={1}
					items={formItems}
					initialValues={data}
					requiredTabFields={[['fixShifts'], ['slidingShifts']] as InternalNamePath[]}
					tabs={[
						slidingOrFixShifts === 'Фиксированные' || !slidingOrFixShifts
							? { key: 'fixed', children: <FixShiftTab />, label: 'Смены' }
							: { key: 'sliding', children: <SlidingShiftTab />, label: 'Смены' },
						{ key: 'staff', children: <StaffTab />, label: 'Персонал' },
						{ key: 'equipment', children: <EquipmentTab data={data} />, label: 'Оборудование' },
					]}
				/>
			</Card>
		</Space>
	)
}

export default WorkCenter
