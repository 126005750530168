import React from 'react'
import { Layout as LayoutAntd, theme } from 'antd'
import { Outlet } from 'react-router-dom'
import { MenuProvider, useMenu } from '../contexts'
import Menu from './menu/Menu'
import Header from './header/Header'

const Layout = () => {
	const {
		state: { position, collapsed },
		toggleCollapsed,
	} = useMenu()
	const {
		token: { colorBgContainer },
	} = theme.useToken()
	return (
		<MenuProvider>
			<LayoutAntd style={{ minHeight: '100vh' }}>
				<Menu />
				<LayoutAntd>
					<Header
						colorBgContainer={colorBgContainer}
						position={position}
						collapsed={collapsed}
						toggleCollapsed={toggleCollapsed}
					/>
					<Outlet />
				</LayoutAntd>
			</LayoutAntd>
		</MenuProvider>
	)
}

export default Layout
