import { RawMatNomenclature as RawMatNomenclatureType } from '@isf/backend/src/modules/raw-mat-nomenclature/entities/raw-mat-nomenclature.entity'
import { useLoaderData } from 'react-router-dom'
import { getFormItems } from './constants'
import { CustomForm } from '../../components'
import { useForm } from 'antd/es/form/Form'
import { Card, Form, Space } from 'antd'
import Bar from '../../layout/bar/Bar'

const RawMatNomenclature = () => {
	const data = useLoaderData() as RawMatNomenclatureType
	const [form] = useForm()
	const rawMatType = Form.useWatch('rawMatType', form)
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar form={form} />
			<Card style={{ margin: '0 16px' }}>
				<CustomForm
					form={form}
					colCount={3}
					rowCount={4}
					items={getFormItems(data, form, rawMatType)}
					initialValues={data}
				/>
			</Card>
		</Space>
	)
}

export default RawMatNomenclature
