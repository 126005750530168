import { Button, Card, Form, Modal, Space } from 'antd'
import { TechCard as TechCardType } from '@isf/backend/src/modules/tech-card/entities/tech-card.entity'
import { useLoaderData, useLocation, useNavigate, useParams, useSubmit } from 'react-router-dom'
import { RecipeTypeEnum, TechCardVersionStatus, getFormItems, url } from './constants'
import { CustomFormWithTabs } from '../../components'
import React, { useEffect } from 'react'
import lodash from 'lodash'
import { ManufProdTab, CompositionTab, CookTechnologyTab, RelatedRecipesTab } from './tabs'
import Bar from '../../layout/bar/Bar'
import { useMode } from '../../contexts'
import { modes } from '../../utils/constants'
import { CloseIcon } from '../../assets'
import { openNewTab } from '../../utils/helpers'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { queryKey } from './utils'
import { useAsyncAction } from '../../utils/hooks'
import { TechCardVersion } from '@isf/backend/src/modules/tech-card/entities/tech-card-version.entity'
import { TechCardManufProd } from '@isf/backend/src/modules/tech-card/entities/manuf-prod.entity'
import { TechCardComposition } from '@isf/backend/src/modules/tech-card/entities/composition.entity'
import { RawMatType } from '@isf/backend/src/modules/dictionaries/modules/raw-mat-type/entities/raw-mat-type.entity'

const TechCard = () => {
	const { data, related } = useLoaderData() as { data: Partial<TechCardType & TechCardVersion> }
	const [form] = Form.useForm<TechCardType>()
	const type = Form.useWatch('type', form)
	const shipmentWave = Form.useWatch('shipmentWave', form)
	const compositions: TechCardComposition[] = Form.useWatch('compositions', form)
	const {
		state: { mode },
		setMode,
	} = useMode()

	// reset carbs, fats, proteins
	useEffect(() => {
		if (type === RecipeTypeEnum['cut']) {
			const manufProds = form.getFieldValue('manufProds').map((prod) => ({
				...prod,
				carbs: 0,
				fats: 0,
				proteins: 0,
				calorie: null,
			}))
			form.setFieldValue('manufProds', manufProds)
		}
	}, [form, type])

	// set origin
	useEffect(() => {
		if (compositions) {
			const origins = compositions.reduce((acc, cur) => {
				const curOrigin = cur.rawMatType
					? cur.rawMatType.origin
					: cur.techCardManufProd
					? cur.techCardManufProd.origin
					: null
				if (curOrigin && !acc.includes(curOrigin)) {
					return [...acc, curOrigin]
				}
				return acc
			}, [] as (RawMatType['origin'] | TechCardManufProd['origin'])[])

			if (origins.length > 1) {
				return form.setFieldValue('origin', 'Комбинированное')
			}
			form.setFieldValue('origin', origins[0])
		}
	}, [compositions, form])

	const params = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const { isLoading, mutate } = useAsyncAction<Partial<TechCardType & TechCardVersion>>({
		url: `${url}/update-status`,
		redirectUrl: url,
		id: data.id,
		queryKey: [...queryKey, { id: data.id }],
		form,
	})
	const submit = useSubmit()
	return (
		<Space direction="vertical" style={{ display: 'flex' }}>
			<Bar
				form={form}
				buttons={
					mode === modes.view
						? [
								// ...(data.status === 'Утверждена'
								// 	? [
								// 			<Button
								// 				type="primary"
								// 				key="version"
								// 				onClick={() =>
								// 					Modal.confirm({
								// 						icon: <ExclamationCircleOutlined />,
								// 						centered: true,
								// 						content:
								// 							'При клике по кнопке Ок, будет создана новая версия текущей карточки Тех. карты. После утверждения новой версии, старая версия будет отправлена в архив.',
								// 						onOk: (close) => {
								// 							const dataForNewVersion = lodash.cloneDeep(
								// 								data
								// 							) as Partial<TechCardVersion>
								// 							delete dataForNewVersion.id
								// 							dataForNewVersion.status = TechCardVersionStatus['notApproved']
								// 							delete dataForNewVersion.date
								// 							dataForNewVersion.version = dataForNewVersion.version
								// 								? ++dataForNewVersion.version
								// 								: 1
								// 							dataForNewVersion.techCard = data
								// 							delete dataForNewVersion.recipeNumber
								// 							close()
								// 							openNewTab(
								// 								`/${url}/${data.id}`,
								// 								'techCard',
								// 								{ data: dataForNewVersion, related },
								// 								Modes.edit
								// 							)
								// 						},
								// 					})
								// 				}
								// 			>
								// 				Создать новую версию
								// 			</Button>,
								// 	  ]
								// 	: []),
								...(data.status === 'Не утверждена'
									? [
											<Button
												type="primary"
												key="approve"
												onClick={() =>
													mutate({
														id: data.id as TechCardVersion['id'],
														status: TechCardVersionStatus['approved'],
													})
												}
												loading={isLoading}
											>
												Утвердить
											</Button>,
									  ]
									: []),
								...(data.status !== 'Черновик'
									? [
											<Button
												type="primary"
												key="copy"
												onClick={() => {
													const dataForNewVersion = lodash.cloneDeep(data) as Partial<
														TechCardType & TechCardVersion
													>
													dataForNewVersion.compositions = dataForNewVersion.compositions?.map(
														(c) => ({ ...c, id: undefined })
													)
													dataForNewVersion.cookTechnologies =
														dataForNewVersion.cookTechnologies?.map((c) => ({
															...c,
															id: undefined,
														}))
													delete dataForNewVersion.id
													dataForNewVersion.status = TechCardVersionStatus['notApproved']
													delete dataForNewVersion.date
													delete dataForNewVersion.recipeNumber
													dataForNewVersion.manufProds = [{} as TechCardManufProd]
													console.log(dataForNewVersion)
													openNewTab(`/${url}/new?copy`, 'techCard', {
														data: dataForNewVersion,
														related,
													})
												}}
											>
												Создать копию
											</Button>,
									  ]
									: []),
								//...(['Черновик', 'Не утверждена'].includes(data.status)
								...(true
									? [
											<Button type="primary" key="edit" onClick={() => setMode(modes.edit)}>
												Редактировать
											</Button>,
											<Button
												type="primary"
												key="delete"
												onClick={() =>
													Modal.confirm({
														content: 'Вы действительно хотите удалить данный элемент?',
														centered: true,
														icon: <ExclamationCircleOutlined />,
														onOk: (close) => {
															submit(
																{ id: params.id },
																{
																	method: 'DELETE',
																	action: location.pathname,
																	encType: 'application/json',
																}
															)
															close()
														},
													})
												}
											>
												Удалить
											</Button>,
									  ]
									: []),
								...(data.status === 'Утверждена'
									? [
											<Button
												type="primary"
												key="archive"
												onClick={() =>
													mutate({ id: data.id, status: TechCardVersionStatus['archive'] })
												}
												loading={isLoading}
											>
												В архив
											</Button>,
									  ]
									: []),
								<Button
									key="close"
									type="text"
									style={{ lineHeight: 0.8 }}
									icon={<CloseIcon />}
									onClick={() => navigate(location.pathname.replace(`/${params.id}`, ''))}
								/>,
						  ]
						: [
								<Button
									type="primary"
									key="save"
									htmlType="submit"
									form={location.pathname}
									loading={isLoading}
								>
									Сохранить
								</Button>,
								<Button
									key="close"
									type="text"
									style={{ lineHeight: 0.8 }}
									icon={<CloseIcon />}
									onClick={() => {
										const reset = () => {
											if (params?.id === 'new') {
												navigate(location.pathname.replace(`/${params.id}`, ''))
											}
											return setMode(modes.view)
										}
										if (form?.isFieldsTouched && !form?.isFieldsTouched()) {
											return reset()
										}
										return Modal.confirm({
											icon: <ExclamationCircleOutlined />,
											centered: true,
											width: '25%',
											content: 'Выйти без сохранения изменений?',
											okText: 'Выйти',
											onOk: (close) => {
												if (form) {
													form.resetFields()
												}
												close()
												reset()
											},
										})
									}}
								/>,
						  ]
				}
			/>
			<Card style={{ margin: '0 16px' }}>
				<CustomFormWithTabs
					draft={{ draftStatus: 'Черновик', status: 'Не утверждена' }}
					// encType="multipart/form-data"
					form={form}
					colCount={3}
					rowCount={type === RecipeTypeEnum['package'] ? 5 : 4}
					items={getFormItems(type, shipmentWave || data.shipmentWave, data, form)}
					initialValues={data}
					requiredTabFields={[['compositions'], ['manufProds'], ['cookTechnologies']]}
					tabs={[
						{ key: 'manuf-prod', children: <ManufProdTab />, label: 'Выпускаемый продукт' },
						{ key: 'composition', children: <CompositionTab />, label: 'Состав' },
						{
							key: 'cook-technology',
							children: <CookTechnologyTab />,
							label: 'Технология приготовления',
						},
						{ key: 'related', children: <RelatedRecipesTab />, label: 'Связанные рецепты' },
					]}
					colSpan={[3, 8, 8, 5]}
				/>
			</Card>
		</Space>
	)
}

export default TechCard
