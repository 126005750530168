import React from 'react'
import { Button, FormInstance, FormListOperation, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { NamePath } from 'antd/es/form/interface'
import { TechCardComposition } from '@isf/backend/src/modules/tech-card/entities/composition.entity'
import { MaskedInput } from '../../../../components'

export const getGross = (record) =>
	(+record?.amount || 0) *
	(+record?.rawMatType?.kgPerUnit || +record.techCardManufProd?.estimatedWeight || 0)

const getColumns = (remove: FormListOperation['remove'], form: FormInstance) => [
	{
		width: '1%',
		title: null,
		key: 'expandIcon',
		readOnly: true,
	},
	{
		title: 'Артикул',
		dataIndex: 'articul',
		width: '8%',
		render: (articul, record) =>
			articul || record.rawMatType?.articul || record.techCardManufProd?.articul,
		responsive: ['xl'],
	},
	{
		title: 'Наименование',
		width: '20%',
		key: 'label',
		render: (_, record) => record.rawMatType?.label || record.techCardManufProd?.manufProd,
	},
	{
		title: 'Приоритет',
		width: '5%',
		dataIndex: 'priority',
		responsive: ['xl'],
		component: (
			<Select
				options={[...Array(100 + 1).keys()].slice(1).map((el) => ({ text: el, value: el }))}
			/>
		),
	},
	{
		title: 'ЕИ',
		key: 'measure',
		width: '5%',
		responsive: ['xl'],
		readOnly: true,
		render: (_, record) =>
			record.rawMatType?.measure.labelShort ||
			record.techCardManufProd?.techCard?.manufProds[0].measure,
	},
	{
		title: 'Количество',
		dataIndex: 'amount',
		responsive: ['xl'],
		width: '5%',
		render: (_, record) =>
			record.rawMatType?.measure.labelShort === 'кг' ||
			record.techCardManufProd?.measure === 'кг' ? null : (
				<MaskedInput max={9999.99} scale={2} allowNegative={false} />
			),
	},
	{
		title: 'Брутто, кг',
		dataIndex: 'gross',
		width: '15%',
		render: (_, record) =>
			record.rawMatType?.measure.labelShort === 'кг' ||
			record.techCardManufProd?.measure === 'кг' ? (
				<MaskedInput max={999.9999} scale={4} allowNegative={false} />
			) : (
				getGross(record).toFixed(4)
			),
		rules: [
			{
				validator: ({ field }, value: TechCardComposition['gross']) => {
					const netFieldPath = field
						.split('.')
						.toSpliced(-1, 1, 'net')
						.map((el: NamePath) => (isNaN(+el) ? el : +el))
					const net = form.getFieldValue(netFieldPath)
					const recordPath = field.split('.').toSpliced(-1)
					const record = form.getFieldValue(recordPath)
					if ((+value || getGross(record)) < +net) {
						return Promise.reject()
					}
					form.setFields([
						{
							name: netFieldPath,
							errors: net ? [] : form.getFieldError(netFieldPath),
						},
					])
					return Promise.resolve()
				},
			},
			// custom required check: gross.required = record.amount === null
			{
				validator: ({ field }, value: TechCardComposition['gross']) => {
					const recordPath = field.split('.').toSpliced(-1)
					const record = form.getFieldValue(recordPath)
					if (
						record.rawMatType?.measure.labelShort === 'кг' ||
						record.techCardManufProd?.measure === 'кг'
					) {
						if (!getGross(record).toFixed(4)) {
							return Promise.reject('Не заполнены обязательные поля!')
						}
					}
					return Promise.resolve()
				},
			},
		],
	},
	{
		title: 'Нетто, кг',
		dataIndex: 'net',
		width: '15%',
		component: <MaskedInput max={999.9999} scale={4} allowNegative={false} />,
		rules: [
			{
				validator: ({ field }, value: TechCardComposition['net']) => {
					const grossFieldPath = field
						.split('.')
						.toSpliced(-1, 1, 'gross')
						.map((el: NamePath) => (isNaN(+el) ? el : +el))
					const rowFieldPath = field.split('.').toSpliced(-1)
					const record = form.getFieldValue(rowFieldPath)
					const gross = form.getFieldValue(grossFieldPath) || getGross(record)
					if (+value > +gross) {
						return Promise.reject()
					}
					form.setFields([
						{
							name: grossFieldPath,
							errors: +gross ? [] : form.getFieldError(grossFieldPath),
						},
					])
					return Promise.resolve()
				},
			},
			{ required: true, message: 'Не заполнены обязательные поля!' },
		],
	},
	{
		title: 'Тех.потери, кг',
		dataIndex: 'loss',
		responsive: ['xl'],
		width: '5%',
		render: (_, record) =>
			((+record.gross || getGross(record) || 0) - (+record.net || 0)).toFixed(3),
	},
	// {
	// 	title: null,
	// 	dataIndex: 'add',
	// 	key: 'add',
	// 	render: (_, record, index) =>
	// 		!record?.parent ? (
	// 			<Button
	// 				onClick={() => {
	// 					const fieldName = ['compositions', index, 'analogs']
	// 					const analog = lodash.cloneDeep(record)
	// 					delete analog.id
	// 					delete analog.analogs
	// 					form.setFieldValue(fieldName, [
	// 						...(form.getFieldValue(fieldName) || []),
	// 						{
	// 							...analog,
	// 							name: [index, 'analogs', record.analogs?.length],
	// 							parent: { ...record, analogs: null },
	// 						},
	// 					])
	// 				}}
	// 			>
	// 				Добавить аналог
	// 			</Button>
	// 		) : null,
	// },
	{
		title: null,
		key: 'remove',
		width: '5%',
		responsive: ['xl'],
		render: (_, __, index) => (
			<Button type="text" icon={<DeleteOutlined />} onClick={() => remove(index)} />
		),
	},
]

export { getColumns }
