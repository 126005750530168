import React from 'react'
import { Col, Dropdown, Space, Typography } from 'antd'
import Icon from '@ant-design/icons'
import { user, user_dark } from '../../assets'
import { menuPositions, useAuth, useMenu } from '../../contexts'
import { getUserRole } from '../../utils/helpers/user'

const Info = () => {
	const { signOut } = useAuth()
	const {
		state: { position },
	} = useMenu()
	const topMenu = position === menuPositions.top
	return (
		<Col style={{ marginRight: 50, height: 64 }}>
			<Space size="large" style={{ height: '100%' }}>
				<Dropdown
					trigger={['click']}
					menu={{
						items: [
							{
								key: 0,
								style: { width: 144, textAlign: 'center' },
								onClick: signOut,
								label: 'Выйти',
							},
						],
					}}
				>
					<Icon style={{ fontSize: 42 }} component={topMenu ? user_dark : user} />
				</Dropdown>
				<Typography.Text
					style={{
						fontSize: 14,
						color: topMenu ? 'rgba(255, 255, 255, 0.65)' : 'initial',
					}}
				>
					{getUserRole()}
				</Typography.Text>
			</Space>
		</Col>
	)
}

export default Info
