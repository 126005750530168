import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import React from 'react'

const TabList = ({ tabs = [] }: { tabs: TabsProps['items'] }) => (
	<Tabs
		type="card"
		tabBarStyle={{ margin: 0 }}
		items={tabs.map(({ label, children }, index) => ({
			key: String(index),
			label,
			children,
			forceRender: true,
		}))}
	/>
)

export default TabList
