import { DownOutlined } from '@ant-design/icons'
import { Card, Col, Row, Tree } from 'antd'
import React from 'react'
import { useLoaderData } from 'react-router-dom'
import { openNewTab } from '../../../utils/helpers'
import { modes } from '../../../utils/constants'

const RelatedRecipesTab = () => {
	const { related } = useLoaderData()

	return (
		<Card>
			<Row gutter={[16, 16]}>
				<Col span="24">
					<Tree
						switcherIcon={<DownOutlined />}
						treeData={related}
						onSelect={(_, { node }) => openNewTab(node.url, undefined, undefined, modes['view'])}
					/>
				</Col>
			</Row>
		</Card>
	)
}

export default RelatedRecipesTab
