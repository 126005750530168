import React, { useEffect } from 'react'
import { Button, Col, Form, FormListOperation, Row } from 'antd'
import { TabItem, TableForm } from '../../../../components'
import { TechCard } from '@isf/backend/src/modules/tech-card/entities/tech-card.entity'
import { RecipeTypeEnum } from '../../constants'
import { TechCardCookTechnology } from '@isf/backend/src/modules/tech-card/entities/cook-technology.entity'
import { getCarbs, getFats, getProteins, getManufProdCalories, getColumns } from './utils'
import { TechCardComposition } from '@isf/backend/src/modules/tech-card/entities/composition.entity'
import { TechCardManufProd } from '@isf/backend/src/modules/tech-card/entities/manuf-prod.entity'

const ManufProdsTab = () => {
	const form = Form.useFormInstance()
	const type = Form.useWatch('type', form) as TechCard['type']
	const manufProds = Form.useWatch('manufProds', form) as TechCardManufProd[]
	const compositions = Form.useWatch('compositions', form) as TechCardComposition[]
	const cookTechnologies = Form.useWatch('cookTechnologies', form) as TechCardCookTechnology[]

	useEffect(() => {
		if (type !== RecipeTypeEnum['cut']) {
			if (compositions?.some((c) => !c.rawMatType || c.rawMatType.isHasNutritionalValue)) {
				const calcedManufProds = manufProds?.map((e) => {
					const proteins = getProteins(e, compositions, cookTechnologies).toFixed(1)
					const fats = getFats(e, compositions, cookTechnologies).toFixed(1)
					const carbs = getCarbs(e, compositions, cookTechnologies).toFixed(1)
					const calorie = getManufProdCalories(e, compositions, cookTechnologies)
					return {
						...e,
						proteins,
						fats,
						carbs,
						calorie,
					}
				})
				form.setFieldValue('manufProds', calcedManufProds)
			}
		}
	}, [compositions, cookTechnologies, form, manufProds, type])

	const getTableHeaderRow = (add: FormListOperation['add']) =>
		type === RecipeTypeEnum['cut'] ? (
			<Row>
				<Col>
					<Button onClick={() => add()}>Добавить новую строку</Button>
				</Col>
			</Row>
		) : null

	return (
		<TabItem>
			<TableForm
				getTableHeaderRow={getTableHeaderRow}
				getColumns={(...args) => getColumns(...args, type, cookTechnologies)}
				field="manufProds"
				required
			/>
		</TabItem>
	)
}

export default ManufProdsTab
