import { Supply } from '@isf/backend/src/modules/supply/entities/supply.entity'
import { QueryClient } from '@tanstack/react-query'
import { axios } from '../../utils/helpers'
import { type LoaderFunction, type ActionFunction, redirect } from 'react-router-dom'
import { DateTime } from 'luxon'

const url = 'stock-operations/incoming/supplies'
const queryKey = ['supplies']

const supplyListQuery = () => ({
	queryKey,
	queryFn: (): Promise<Supply[]> => axios.get(url).then((response) => response.data),
})

const supplyQuery = (id: Supply['id']) => ({
	queryKey: [...queryKey, id],
	queryFn: (): Promise<Supply[]> => axios.get(`${url}/${id}`).then((response) => response.data),
})

export const listLoader: (arg0: QueryClient) => LoaderFunction = (queryClient) => async () => {
	const query = supplyListQuery()
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const loader: (arg0: QueryClient) => LoaderFunction =
	(queryClient) =>
	async ({ params }) => {
		if (params.id && !isNaN(+params.id)) {
			const query = supplyQuery(+params.id)
			return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
		}
		return {
			date: DateTime.now(),
			type: 'Сырье',
			doc: 'Накладная',
			status: 'Черновик',
			nomenclatures: [],
		}
	}

export const action: (arg0: QueryClient) => ActionFunction =
	(queryClient) =>
	async ({ request, params }) => {
		try {
			const values = await request.json()
			const response = await axios({
				method: request.method,
				url: request.method === 'POST' ? url : `${url}/${params.id}`,
				...(values && { data: values }),
			})
			await queryClient.invalidateQueries(
				{ queryKey, exact: true, refetchType: 'all' },
				{ throwOnError: true }
			)
			if (request.method === 'DELETE') {
				if (params.id) {
					queryClient.removeQueries({ queryKey: [...queryKey, +params.id] })
				}
				return redirect(`/${url}`)
			}
			return redirect(`/${url}/${response.data.id}`)
		} catch (err) {
			if (err.isReadable) {
				return { err }
			}
			console.log(err)
			return null
		}
	}
