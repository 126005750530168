import { RouteObject, useMatches } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import React from 'react'
import { useMode } from '../../contexts'
import { modes } from '../../utils/constants'

const Breadcrumbs = () => {
	const matches = useMatches() as RouteObject[]
	const {
		state: { mode },
	} = useMode()
	const disabled = mode !== modes['view']
	const items = matches
		.filter((match) => Boolean(match.handle?.crumb))
		.map((match) =>
			disabled
				? match.handle.crumb(match.data)?.props?.children || match.handle.crumb(match.data)
				: match.handle.crumb(match.data)
		)
	function itemRender(route, _, items) {
		const item = items.find((i) => (route.props ? i.props?.to === route.props.to : i === route))
		return item
	}
	return <Breadcrumb itemRender={itemRender} items={items} style={{ flexGrow: 1 }} />
}

export default Breadcrumbs
