import { Input } from 'antd'
import { ListContextType } from '../../contexts/list/types'
import { useParams } from 'react-router-dom'

export const Search = ({
	search,
	textFilter,
	show,
}: {
	search: ListContextType['setTextFilter']
	textFilter: ListContextType['state']['textFilter']
	show?: boolean
}) => {
	const params = useParams()
	const isList = !params.id
	return isList || show ? (
		<Input.Search
			style={{ width: 300 }}
			onSearch={search}
			defaultValue={textFilter || ''}
			allowClear
			enterButton
		/>
	) : null
}
