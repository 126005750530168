import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { TabItem, TableForm, TableModal } from '../../../../components'
import { useMode } from '../../../../contexts'
import { modes } from '../../../../utils/constants'
import { Outlet } from 'react-router-dom'
import { url } from '../../constants'
import { getColumns, getGross } from './utils'
import { TechCardComposition } from '@isf/backend/src/modules/tech-card/entities/composition.entity'

const CompositionTab = () => {
	const {
		state: { mode },
	} = useMode()
	const [modalOpen, setModalOpen] = useState(false)
	const form = Form.useFormInstance()
	const compositions = Form.useWatch('compositions') as TechCardComposition[]

	// set losses
	useEffect(() => {
		if (compositions?.length) {
			const newValues = compositions.map((c) => ({
				...c,
				loss:
					c.gross && (c.net || c.amount)
						? (+c.gross - +(isNaN(+c.net) ? getGross(c) : c.net)).toFixed(3)
						: null,
				analogs: (c.analogs || []).map((analog) => ({
					...analog,
					loss:
						analog.gross && (analog.net || analog.amount)
							? (+analog.gross - +(isNaN(+analog.net) ? getGross(analog) : analog.net)).toFixed(3)
							: null,
				})),
			}))
			form.setFieldValue('compositions', newValues)
		}
	}, [compositions, form])

	const getTableHeaderRow = () =>
		mode === modes.view ? null : (
			<Row>
				<Col>
					<Button onClick={() => setModalOpen(true)}>Добавить данные</Button>
				</Col>
			</Row>
		)
	return (
		<TabItem>
			<>
				<TableForm
					getTableHeaderRow={getTableHeaderRow}
					getColumns={getColumns}
					field="compositions"
					required
				/>
				<TableModal
					field="compositions"
					url={url}
					open={modalOpen}
					setOpen={setModalOpen}
					title="Состав"
					items={[
						{
							key: '/tech-cards',
							label: 'Техкарты',
							children: <Outlet />,
							queryParams: {
								filterBy: { 'latestVersion.status': 'Утверждена' },
							},
						},
						{
							key: '/dictionaries/raw-mat-types',
							label: 'Виды сырья',
							children: <Outlet />,
						},
					]}
				/>
			</>
		</TabItem>
	)
}

export default CompositionTab
