import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { ProcessType as ProcessTypeEntity } from '@isf/backend/src/modules/dictionaries/modules/process-type/entities/process-type.entity'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import { Checkbox } from 'antd'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { CompareFn } from 'antd/es/table/interface'
import { getColumnSearchProps } from '../../../utils/helpers'

const layout = { labelCol: { span: 8 } }

const searchFields = ['label', (value) => (value.isHeatProcess ? 'Да' : 'Нет')]

const ProcessType = () => {
	const data = useLoaderData() as ProcessTypeEntity[]
	const submit = useSubmit()
	const columns: ColumnsType<ProcessTypeEntity> = [
		{
			title: 'Тип обработки',
			dataIndex: 'label',
			...getColumnSearchProps(data, 'label'),
			sorter: sorter(SorterTypes.string, 'label') as CompareFn<ProcessTypeEntity>,
		},

		{
			title: 'Тепловая обработка',
			dataIndex: 'isHeatProcess',
			render: (value) => (value ? 'Да' : 'Нет'),
			...getColumnSearchProps(
				Array.from(new Set(data.map((el) => el.isHeatProcess))).map((el) => ({
					label: el ? 'Да' : 'Нет',
				})),
				'label'
			),
			filters: Array.from(new Set(data.map((el) => el.isHeatProcess)))
				.map((el) => ({
					text: el ? 'Да' : 'Нет',
					value: el,
				}))
				.sort((a, b) => a.text.localeCompare(b.text)),
			onFilter: (value, record) => (record.isHeatProcess ? value === 'Да' : value === 'Нет'),
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'label',
			label: 'Тип обработки',
			rules: [{ required: true }],
			...layout,
		},
		{
			name: 'isHeatProcess',
			label: 'Тепловая обработка',
			valuePropName: 'checked',
			component: <Checkbox />,
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default ProcessType
