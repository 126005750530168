import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { getColumns, searchFields } from './constants'
import ListTemplate from '../ListTemplate'
import React from 'react'
import Bar from '../../layout/bar/Bar'
import { Button, Card, Layout, Space, Typography } from 'antd'
import { ResetFilter } from '../../assets'
import { MultipleTableFilter } from '../../contexts/list/types'
import { useList } from '../../contexts'
import { SelectFetch } from '../../components'
import { TechCardManufProd } from '@isf/backend/src/modules/tech-card/entities/manuf-prod.entity'
import { useColumnSearchProps } from '../../utils/hooks'

const TechCardList = () => {
	const data = useLoaderData() as TechCardManufProd[]
	const navigate = useNavigate()
	const location = useLocation()
	const { getColumnSearchProps } = useColumnSearchProps(data)
	const {
		setFilters,
		resetFilters,
		state: { filters },
	} = useList()
	const onRow = (record: TechCardManufProd) => ({
		onClick: () => navigate(`${location.pathname}/${record.techCard.id}`),
	})
	const filteredData = Object.keys(filters).some((key) => Object.keys(filters[key]).length)
		? data.filter((el) =>
				Object.keys(filters)
					.filter((key) => (filters as MultipleTableFilter)[key].length)
					.every((key) =>
						(filters as MultipleTableFilter)[key].every(
							(filter) => !!el['techCard'][key] && filter.label === el['techCard'][key]['label']
						)
					)
		  )
		: data
	const prefix = (
		<Space size="small">
			<Typography.Text strong>Группа продуктов</Typography.Text>
			<SelectFetch
				mode="multiple"
				style={{ width: 200 }}
				url={`/dictionaries/product-groups`}
				onSelect={(value) =>
					setFilters({
						...filters,
						productGroup: [...((filters as MultipleTableFilter).productGroup || []), value],
					})
				}
				onDeselect={({ value }) =>
					setFilters({
						...filters,
						productGroup: (filters as MultipleTableFilter).productGroup.filter(
							(f) => f.value !== value
						),
					})
				}
				value={filters.productGroup}
			/>
			<Typography.Text strong>Тип блюда</Typography.Text>
			<SelectFetch
				mode="multiple"
				style={{ width: 200 }}
				url={`/dictionaries/ready-meals-types`}
				onSelect={(value) =>
					setFilters({
						...filters,
						readyMealsType: [...((filters as MultipleTableFilter).readyMealsType || []), value],
					})
				}
				onDeselect={({ value }) =>
					setFilters({
						...filters,
						readyMealsType: (filters as MultipleTableFilter).readyMealsType.filter(
							(f) => f.value !== value
						),
					})
				}
				value={filters.readyMealsType}
			/>
			<Button
				type="text"
				icon={<ResetFilter className="pictogram" style={{ fontSize: 24 }} />}
				onClick={() => {
					resetFilters()
				}}
			/>
		</Space>
	)
	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<ListTemplate
						prefixRow={prefix}
						columns={getColumns(getColumnSearchProps)}
						dataSource={filteredData}
						onRow={onRow}
						noRowButtons
						searchFields={searchFields}
					/>
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default TechCardList
