import { TechCardComposition } from '@isf/backend/src/modules/tech-card/entities/composition.entity'
import { TechCardCookTechnology } from '@isf/backend/src/modules/tech-card/entities/cook-technology.entity'
import { RecipeTypeEnum } from '../../constants'
import { Button, FormInstance, FormListOperation, Select, Typography } from 'antd'
import { TechCardManufProd } from '@isf/backend/src/modules/tech-card/entities/manuf-prod.entity'
import { TechCard } from '@isf/backend/src/modules/tech-card/entities/tech-card.entity'
import { ColumnProps } from 'antd/es/table'
import { ImagesUpload, MaskedInput } from '../../../../components'
import { DeleteOutlined } from '@ant-design/icons'
import { TechCardVersion } from '@isf/backend/src/modules/tech-card/entities/tech-card-version.entity'

enum TechCardManufProdMeasure {
	kg = 'кг',
	piece = 'шт',
}

const getColumns: (
	remove: FormListOperation['remove'],
	form: FormInstance,
	type: TechCard['type'],
	cookTechnologies: TechCardCookTechnology[]
) => ColumnProps<TechCardManufProd>[] = (
	remove: FormListOperation['remove'],
	form,
	type,
	cookTechnologies
) => [
	{
		title: 'Артикул',
		width: '8%',
		dataIndex: 'articul',
		readOnly: true,
	},
	{
		title: 'Выпускаемый продукт',
		dataIndex: 'manufProd',
		width: '20%',
	},
	{
		title: 'Выход',
		width: '5%',
		dataIndex: 'release',
		component: <MaskedInput max={9999.999} scale={3} allowNegative={false} />,
	},
	{
		title: 'ЕИ',
		dataIndex: 'measure',
		width: '7%',
		rules: [{ required: true }],
		component: (
			<Select
				style={{ width: '100%' }}
				onSelect={(value) => {
					if (value === 'кг') {
						for (const index of cookTechnologies.keys()) {
							form.setFieldValue(['cookTechnologies', index, 'productivityMeasure'], 'кг')
						}
					}
					if (value === 'л') {
						for (const index of cookTechnologies.keys()) {
							form.setFieldValue(['cookTechnologies', index, 'productivityMeasure'], undefined)
						}
					}
				}}
				options={[
					{ value: 'кг' },
					...(type === RecipeTypeEnum['package'] ? [] : [{ value: 'шт' }]),
				]}
			/>
		),
	},
	{
		title: 'Оценочный вес 1 шт., кг',
		dataIndex: 'estimatedWeight',
		width: '7%',
		render: (_, record) =>
			record?.measure === TechCardManufProdMeasure['piece'] ? (
				<MaskedInput max={99.999} scale={3} allowNegative={false} />
			) : null,
	},
	{
		title: 'Пищевая ценность',
		children: [
			{
				title: 'Белки, г',
				dataIndex: 'proteins',
				width: '5%',
				readOnly: type !== RecipeTypeEnum['cut'],
				component: <MaskedInput max={999.9} scale={1} allowNegative={false} />,
				rules: [
					{ required: type === RecipeTypeEnum['cut'] },
					({ getFieldsValue, setFieldValue }) => ({
						validator({ field }) {
							return setManufProdCalorie(field, getFieldsValue, setFieldValue)
						},
					}),
				],
			},
			{
				title: 'Жиры, г',
				width: '5%',
				dataIndex: 'fats',
				readOnly: type !== RecipeTypeEnum['cut'],
				component: <MaskedInput max={999.9} scale={1} allowNegative={false} />,
				rules: [
					{ required: type === RecipeTypeEnum['cut'] },
					({ getFieldsValue, setFieldValue }) => ({
						validator({ field }) {
							return setManufProdCalorie(field, getFieldsValue, setFieldValue)
						},
					}),
				],
			},
			{
				title: 'Углеводы, г',
				width: '5%',
				dataIndex: 'carbs',
				readOnly: type !== RecipeTypeEnum['cut'],
				component: <MaskedInput max={999.9} scale={1} allowNegative={false} />,
				rules: [
					{ required: type === RecipeTypeEnum['cut'] },
					({ getFieldsValue, setFieldValue }) => ({
						validator({ field }) {
							return setManufProdCalorie(field, getFieldsValue, setFieldValue)
						},
					}),
				],
			},
			{
				title: 'Калорийность, ккал',
				width: '5%',
				dataIndex: 'calorie',
				readOnly: true,
				component: <Typography.Text />,
			},
		],
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		rules: [{ required: true }],
		component: (
			<Select
				style={{ width: '100%' }}
				options={
					type === RecipeTypeEnum['package']
						? [{ value: 'ПФ' }, { value: 'ГП' }]
						: [{ value: 'ПП' }, { value: 'ПФ' }]
				}
			/>
		),
	},
	// {
	// 	title: 'Фото',
	// 	dataIndex: 'photos',
	// 	key: 'photoAction',
	// 	width: '20%',
	// 	render: (_, record, index) => {
	// 		const isDisabled = record.photos?.length >= 3
	// 		return <ImagesUpload disabled={isDisabled} path={['manufProds', index, 'photos']} />
	// 	},
	// },
	{
		title: null,
		key: 'remove',
		render: (_, __, index) =>
			index !== 0 ? (
				<Button type="text" icon={<DeleteOutlined />} onClick={() => remove(index)} />
			) : null,
	},
]

const getManufProdCalories = (
	prod: TechCardManufProd,
	compositions: TechCardComposition[],
	cookTechnologies: TechCardCookTechnology[]
) =>
	prod.release
		? compositions
				.reduce((calories, composition) => {
					const { net } = composition
					const {
						proteins = getProteins(prod, compositions, cookTechnologies),
						fats = getFats(prod, compositions, cookTechnologies),
						carbs = getCarbs(prod, compositions, cookTechnologies),
					} = composition.rawMatType || composition.techCardManufProd
					return (
						calories +
						(proteins * getKoef(composition, cookTechnologies, 'proteins') * net * 4 +
							fats * getKoef(composition, cookTechnologies, 'fats') * net * 9 +
							carbs * getKoef(composition, cookTechnologies, 'carbs') * net * 3.75) /
							prod.release
					)
				}, 0)
				.toFixed(1)
		: 0

const setManufProdCalorie = (field, getFieldsValue, setFieldValue) => {
	const idx = parseInt(field.match(/\d+/))
	const data: TechCard & TechCardVersion = getFieldsValue()
	const manufProd = data.manufProds[idx]
	if (manufProd.proteins && manufProd.fats && manufProd.carbs) {
		setFieldValue(['manufProd', idx], {
			...manufProd,
			calorie: getManufProdCalories(manufProd, data.compositions, data.cookTechnologies),
		})
	}
	return Promise.resolve(true)
}

const getKoef = (
	composition: TechCardComposition,
	cookTechnologies: TechCardCookTechnology[],
	type: keyof Pick<TechCardManufProd, 'carbs' | 'fats' | 'proteins'>
) => {
	if (cookTechnologies && cookTechnologies.some((t) => t.processType?.isHeatProcess)) {
		if ((composition.rawMatType || composition.techCardManufProd)?.origin === 'Животное') {
			if (type === 'proteins') {
				return 0.92
			}
			if (type === 'fats') {
				return 0.75
			}
			if (type === 'carbs') {
				return 1
			}
		}
		if ((composition.rawMatType || composition.techCardManufProd)?.origin === 'Растительное') {
			if (type === 'proteins') {
				return 0.95
			}
			if (type === 'fats') {
				return 0.94
			}
			if (type === 'carbs') {
				return 0.91
			}
		}
		if ((composition.rawMatType || composition.techCardManufProd)?.origin === 'Комбинированное') {
			if (type === 'proteins') {
				return 0.94
			}
			if (type === 'fats') {
				return 0.88
			}
			if (type === 'carbs') {
				return 0.91
			}
		}
	}
	return 1
}

const getBJU = (
	prod: TechCardManufProd,
	compositions: TechCardComposition[],
	cookTechnologies: TechCardCookTechnology[],
	kind: keyof Pick<TechCardManufProd, 'carbs' | 'fats' | 'proteins'>
): number =>
	prod.release
		? compositions.reduce(
				(sum, composition) =>
					sum +
					((+(composition.rawMatType || composition.techCardManufProd)?.[kind] || 0) *
						(+composition.net || 0) *
						getKoef(composition, cookTechnologies, kind)) /
						+prod.release,
				0
		  )
		: 0

const getCarbs = (
	prod: TechCardManufProd,
	compositions: TechCardComposition[],
	cookTechnologies: TechCardCookTechnology[]
) => getBJU(prod, compositions, cookTechnologies, 'carbs')

const getFats = (
	prod: TechCardManufProd,
	compositions: TechCardComposition[],
	cookTechnologies: TechCardCookTechnology[]
) => getBJU(prod, compositions, cookTechnologies, 'fats')

const getProteins = (
	prod: TechCardManufProd,
	compositions: TechCardComposition[],
	cookTechnologies: TechCardCookTechnology[]
) => getBJU(prod, compositions, cookTechnologies, 'proteins')

export { getColumns, getCarbs, getFats, getProteins, getManufProdCalories }
