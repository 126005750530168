import React from 'react'
import { Checkbox, Form, TableColumnsType, Table } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { RoleType } from '@isf/backend/src/modules/dictionaries/modules/role-type/entities/role-type.entity'
import { modes } from '../../../utils/constants'
import { CustomFormItemProps } from '../../../types/antd-custom-types'

const layout = { labelCol: { span: 8 }, wrapperCol: { offset: 2, span: 8 } }

const formItems: CustomFormItemProps[] = [
	{
		name: 'label',
		label: 'Роль',
		required: true,
		rules: [{ required: true }],
		...layout,
	},
	{
		name: 'isUser',
		label: 'Пользователь',
		valuePropName: 'checked',
		component: <Checkbox />,
		...layout,
	},
]

const addColumns = (el, mode) => ({
	...el,
	view: (
		<Form.Item
			noStyle
			name={[...(el.pathPrefix || []), el.label, 'view']}
			valuePropName="checked"
			rules={[
				({ setFieldValue }) => ({
					validator(_, value) {
						if (value === false) {
							setFieldValue([...(el.pathPrefix || []), el.label, 'edit'], false)
							setFieldValue([...(el.pathPrefix || []), el.label, 'match'], false)
						}
						return Promise.resolve(true)
					},
				}),
			]}
		>
			<Checkbox disabled={mode === modes.view} />
		</Form.Item>
	),
	edit: (
		<Form.Item
			noStyle
			name={[...(el.pathPrefix || []), el.label, 'edit']}
			valuePropName="checked"
			rules={[
				({ setFieldValue }) => ({
					validator(_, value) {
						if (value === true) {
							setFieldValue([...(el.pathPrefix || []), el.label, 'view'], true)
						}
						return Promise.resolve(true)
					},
				}),
			]}
		>
			<Checkbox disabled={mode === modes.view || el.editable === false} />
		</Form.Item>
	),
	match: (
		<Form.Item
			noStyle
			name={[...(el.pathPrefix || []), el.label, 'match']}
			valuePropName="checked"
			rules={[
				({ setFieldValue }) => ({
					validator(_, value) {
						if (value === true) {
							setFieldValue([...(el.pathPrefix || []), el.label, 'view'], true)
						}
						return Promise.resolve(true)
					},
				}),
			]}
		>
			<Checkbox disabled={mode === modes.view || el.editable === false} />
		</Form.Item>
	),
})

const getComponent = (ds, pathPrefix, mode) => {
	const dataSource = Array.isArray(ds)
		? ds
		: Object.keys(ds).map((key) => ({
				key,
				label: key,
				children: ds[key].map((el) => ({
					...el,
					pathPrefix: [...pathPrefix, key],
				})),
		  }))
	return (
		<Form.Item name="rights" required rules={[{ required: true }]}>
			<Table
				size="small"
				pagination={{ hideOnSinglePage: true }}
				columns={[
					{
						title: pathPrefix.includes('Справочники') ? 'Справочники' : 'Типы карточек',
						dataIndex: 'label',
					},
					{
						title: 'Просмотр',
						dataIndex: 'view',
					},
					{
						title: 'Редактирование',
						dataIndex: 'edit',
					},
					...(!pathPrefix.includes('Справочники')
						? [
								{
									title: 'Согласование',
									dataIndex: 'match',
								},
						  ]
						: []),
				]}
				dataSource={dataSource.map((el) => ({
					key: el.key,
					label: el.label,
					...(el.children
						? { children: el.children.map((child) => addColumns(child, mode)) }
						: addColumns({ ...el, pathPrefix }, mode)),
				}))}
				expandable={{
					defaultExpandAllRows: true,
				}}
			/>
		</Form.Item>
	)
}

const columns: TableColumnsType<RoleType> = [
	{
		title: 'Роль',
		dataIndex: 'label',
	},
	{
		title: 'Пользователь',
		dataIndex: 'isUser',
		render: (isUser: RoleType['isUser']) => isUser && <CheckOutlined />,
	},
]

export { formItems, getComponent, columns }
