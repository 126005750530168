import { useLoaderData } from 'react-router-dom'
import { getColumns, searchFields } from './constants'
import ListTemplate from '../ListTemplate'
import React from 'react'
import { Form } from 'antd'
import { TechCardManufProd } from '@isf/backend/src/modules/tech-card/entities/manuf-prod.entity'
import { useColumnSearchProps } from '../../utils/hooks'

const TechCardModalList = ({ fieldName }) => {
	const data = useLoaderData() as TechCardManufProd[]
	const form = Form.useFormInstance()
	const values = Form.useWatch(fieldName)
	const { getColumnSearchProps } = useColumnSearchProps(data)

	return (
		<ListTemplate
			columns={getColumns(getColumnSearchProps, form, fieldName, values)}
			dataSource={data}
			noRowButtons
			searchFields={searchFields}
		/>
	)
}

export default TechCardModalList
