import React from 'react'
import { Button, Col, FormListFieldData, FormListOperation, Row } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { TabItem, TableForm } from '../../../../components'

interface FixShiftTabColumnType extends FormListFieldData {}

const columns: ColumnProps<FixShiftTabColumnType>[] = [
	{
		title: 'Бренд',
		dataIndex: 'label',
	},
]

const BrandTab = () => {
	const getTableHeaderRow = (add: FormListOperation['add']) => (
		<Row>
			<Col>
				<Button onClick={() => add()}>Добавить новую строку</Button>
			</Col>
		</Row>
	)
	return (
		<TabItem>
			<TableForm getTableHeaderRow={getTableHeaderRow} columns={columns} field="tmcBrands" />
		</TabItem>
	)
}

export default BrandTab
