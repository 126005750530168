import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { StaffStatus as StaffEntity } from '@isf/backend/src/modules/dictionaries/modules/staff-status/entities/staff-status.entity'
import { CustomFormItemProps } from '../../../types/antd-custom-types'

const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['label']

const StaffStatus = () => {
	const data = useLoaderData()
	const submit = useSubmit()
	const columns: ColumnsType<StaffEntity> = [
		{
			title: 'Статусы',
			dataIndex: 'label',
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'label',
			label: 'Статусы',
			rules: [{ required: true }],
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default StaffStatus
