import {
	Col,
	Form,
	FormInstance,
	FormItemProps,
	FormProps,
	Input,
	Modal,
	Row,
	Typography,
} from 'antd'
import React, { useEffect, useRef } from 'react'
import { CustomFormItemProps } from '../../types/antd-custom-types'
import { FormEncType, useActionData, useLocation, useSubmit } from 'react-router-dom'
import { useMode } from '../../contexts'
import { modes } from '../../utils/constants'
import { SubmitTarget } from 'react-router-dom/dist/dom'
import { DateTime } from 'luxon'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { InternalNamePath } from 'antd/es/form/interface'

type ColCount = 1 | 2 | 3 | 4 | 5 | 6

type IColSpan = {
	[key: number]: number
}
interface IProps extends FormProps {
	form: FormInstance
	items: CustomFormItemProps[]
	colCount?: ColCount
	rowCount?: number
	tabs?: []
	draft?: { status: string; draftStatus: string }
	children?: React.JSX.Element | null
	colSpan?: IColSpan
	encType?: FormEncType
	requiredTabFields?: InternalNamePath[]
}

const getFormItem = (
	{
		component,
		conditionalComponent,
		disabled: disabledProp,
		children,
		shouldUpdate,
		readOnly,
		dataIndex,
		date,
		dateFormat,
		...props
	},
	form: FormInstance,
	viewMode: boolean
) => {
	if (component === null) {
		return null
	}
	const disabled = disabledProp ? disabledProp || viewMode : viewMode
	if (conditionalComponent) {
		return (
			<Form.Item noStyle shouldUpdate={shouldUpdate}>
				{conditionalComponent}
			</Form.Item>
		)
	}
	let child: React.JSX.Element
	switch (true) {
		case readOnly:
			const value = form.getFieldValue(props.name)
			child = (
				<Typography.Text>
					{date
						? value
							? DateTime.fromISO(value as string).toFormat(dateFormat ?? 'dd.MM.yyyy')
							: null
						: props.name
						? value
						: ''}
				</Typography.Text>
			)
			break
		case !!component:
			child =
				React.isValidElement(component) &&
				(component.props as React.ReactElement['props']).disabled !== undefined
					? component
					: React.cloneElement(component, { disabled })
			break
		case !!children:
			child = React.cloneElement(typeof children === 'function' ? children(form) : children, {
				disabled,
			})
			break
		default:
			child = <Input disabled={disabled} />
			break
	}
	return <Form.Item {...props}>{child}</Form.Item>
}

const getFormItems: (
	items: CustomFormItemProps[],
	rowCount: number,
	colCount: ColCount,
	inline: boolean,
	viewMode: boolean,
	form: FormInstance,
	colSpan?: IColSpan
) => React.JSX.Element[] = (items, rowCount, colCount, inline, viewMode = true, form, colSpan) =>
	items
		.reduce((acc: Array<FormItemProps[]>, cur: FormItemProps, index: number) => {
			const rowIndex = inline ? Math.floor(index / colCount) : index % rowCount
			if (!acc[rowIndex]) {
				acc[rowIndex] = []
			}
			acc[rowIndex].push(cur)
			return acc
		}, [])
		.map((row: CustomFormItemProps[], index: number) => (
			<Row key={`row-${index}`}>
				{row
					.filter(({ children }) => !children || (typeof children == 'function' && children(form)))
					.map((formItemProps, colIndex: number) => (
						<Col key={`col-${colIndex}`} span={colSpan?.[colIndex] || 24 / colCount}>
							{getFormItem(formItemProps, form, viewMode)}
						</Col>
					))}
			</Row>
		))

const CustomForm = <T,>({
	form,
	items,
	colCount = 1,
	colSpan,
	rowCount,
	layout,
	draft,
	children,
	requiredTabFields,
	initialValues: propValues,
	encType = 'application/json',
	...formProps
}: IProps) => {
	const {
		state: { mode },
		setMode,
	} = useMode()
	const { pathname } = useLocation()

	const formItems = getFormItems(
		items,
		rowCount || items.length,
		colCount,
		layout === 'inline',
		mode === modes.view,
		form,
		colSpan
	)

	const modalRef = useRef<{
		destroy: () => void
		update: (configUpdate) => void
	} | null>(null)
	const actionData = useActionData() as T

	useEffect(() => {
		if (mode === modes['view']) {
			form.setFieldsValue(propValues)
		}
	}, [form, mode, propValues])

	const submit = useSubmit()
	const handleDraftSubmit = (values: T) => {
		try {
			submit(values as SubmitTarget, {
				method: mode === modes.create ? 'post' : 'patch',
				action: pathname,
				encType,
			})
			setMode(modes.view)
			form.setFields(form.getFieldsError().map((f) => ({ name: f.name, errors: [] })))
		} catch (err) {
			if (err instanceof Error) {
				console.log(err.message)
				throw err
			}
		}
	}
	useEffect(() => {
		if (actionData?.err?.isReadable) {
			if (!modalRef.current) {
				const {
					err: { error, url, urlText },
				} = actionData
				const content = (
					<>
						<Typography.Text>{error}</Typography.Text>
						{urlText && (
							<>
								<br />
								<Typography.Link href={url} target="_blank">
									{urlText}
								</Typography.Link>
							</>
						)}
					</>
				)
				const modal = Modal.confirm({
					content,
					centered: true,
					icon: <ExclamationCircleOutlined />,
					cancelButtonProps: { style: { display: 'none' } },
					onOk: (close) => {
						modalRef.current = null
						close()
					},
				})
				modalRef.current = modal
			}
		}
	}, [actionData])

	return (
		<Form
			initialValues={propValues}
			name={pathname}
			form={form}
			labelAlign="left"
			preserve
			onFinishFailed={async ({ values, errorFields }) => {
				if (draft) {
					form.setFields(errorFields.map((f) => ({ ...f, errors: [] })))
					const userReply = await new Promise((resolve) => {
						const message =
							errorFields.find(
								(err) =>
									err.name.length === 1 &&
									requiredTabFields?.some((required) =>
										required.every((path) => err.name.includes(path))
									)
							)?.errors[0] || 'Не заполнены обязательные поля.'
						Modal.confirm({
							width: 600,
							centered: true,
							icon: <ExclamationCircleOutlined />,
							content: (
								<>
									<Typography.Text>{message}</Typography.Text>
									<br />
									<Typography.Text>
										При сохранении карточка будет сохранена со статусом "Черновик". При отмене
										продолжится редактирование карточки.
									</Typography.Text>
								</>
							),
							onOk: () => {
								resolve(true)
							},
							onCancel: () => {
								resolve(false)
							},
						})
					})
					if (userReply) {
						handleDraftSubmit(values)
					} else {
						form.setFields(errorFields)
					}
				}
			}}
			onFinish={(values) => {
				// if (
				// 	requiredTabFields.length &&
				// 	errorFields.some((field) =>
				// 		requiredTabFields.some((required) =>
				// 			required.every((rf) => field.name.every((part) => part === rf))
				// 		)
				// 	)
				// ) {
				// 	return Modal.confirm({
				// 		icon: <ExclamationCircleOutlined />,
				// 		centered: true,
				// 		width: '25%',
				// 		content: 'Не добавлена ни одна строка в табличной вкладке',
				// 		cancelButtonProps: { style: { display: 'none' } },
				// 	})
				// }
				try {
					if (draft) {
						values.status = draft.status
					}
					submit(values, {
						method: mode === modes.create ? 'post' : 'patch',
						action: pathname,
						encType,
					})
					setMode(modes.view)
				} catch (err) {
					if (err instanceof Error) {
						console.log(err.message)
						throw err
					}
				}
			}}
			{...formProps}
		>
			{formItems}
			{(
				<div
					className="tab-list"
					style={{
						background: 'white',
						position: 'absolute',
						top: '100%',
						marginTop: '20px',
						left: 0,
						width: '100%',
					}}
				>
					{children}
				</div>
			) || null}
		</Form>
	)
}

export default CustomForm
