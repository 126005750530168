import React, { useEffect, useRef, useState } from 'react'
import { Card, Form, Layout, Modal, Space, Table, TableColumnsType, Typography } from 'antd'
import { SubmitFunction, useActionData, useLocation } from 'react-router-dom'
import { useMode, useList } from '../../contexts'
import { modes } from '../../utils/constants'
import { TableRowButtons, CustomForm } from '../../components'
import { ISearchFields, applyFilterForList } from '../../utils/helpers/for-provider'
import { CustomFormItemProps } from '../../types/antd-custom-types'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { CommonObjectPropType } from '../../types/common'
import Bar from '../../layout/bar/Bar'

const DictionaryTemplate = <T extends CommonObjectPropType>({
	columns,
	dataSource,
	items = [],
	onRow = () => ({}),
	noRowButtons,
	searchFields = [],
	submit = () => {},
	initialValues,
	showSearch,
}: {
	columns: TableColumnsType<T>
	dataSource: T[]
	items?: CustomFormItemProps[]
	onRow?: (arg: T) => {}
	noRowButtons?: boolean
	searchFields?: ISearchFields<T>
	submit: SubmitFunction
	initialValues?: Partial<T>
	showSearch?: boolean
}) => {
	const [form] = Form.useForm()
	const {
		state: { mode },
		setMode,
	} = useMode()
	const listCtx = useList()
	const location = useLocation()
	const [record, setRecord] = useState<T | null>(null)
	const [initialFormValues, setInitialFormValues] = useState<Partial<T> | undefined>(initialValues)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const actionData = useActionData()

	const modalRef = useRef<{
		destroy: () => void
		update: (configUpdate) => void
	} | null>(null)

	useEffect(() => {
		if (!initialFormValues) {
			const emptyDictItem = {}
			for (const key of Object.keys(dataSource[0] || {})) {
				emptyDictItem[key] = undefined
			}
			setInitialFormValues(emptyDictItem)
		}
	}, [dataSource, initialFormValues])

	useEffect(() => {
		if (mode === modes.create) {
			form.setFieldsValue(initialFormValues)
			setIsModalOpen(true)
		}
		if (mode === modes.view) {
			setIsModalOpen(false)
		}
	}, [form, initialFormValues, mode])

	useEffect(() => {
		if (actionData?.err?.isReadable) {
			if (!modalRef.current) {
				const {
					err: { error, url, urlText },
				} = actionData
				const content = (
					<>
						<Typography.Text>{error}</Typography.Text>
						{urlText && (
							<>
								<br />
								<Typography.Link href={url} target="_blank">
									{urlText}
								</Typography.Link>
							</>
						)}
					</>
				)
				const modal = Modal.confirm({
					content,
					centered: true,
					icon: <ExclamationCircleOutlined />,
					cancelButtonProps: { style: { display: 'none' } },
					onOk: (close) => {
						modalRef.current = null
						close()
					},
				})
				modalRef.current = modal
			}
		}
	}, [actionData])

	const onEdit = (e: T) => {
		setRecord(e)
		form.setFieldsValue(e)
		setMode(modes.edit)
		setIsModalOpen(true)
	}

	const filteredData = applyFilterForList(dataSource, {
		searchFields,
		search: listCtx.state.textFilter || '',
	})

	const onDelete = (e: T) => {
		Modal.confirm({
			icon: <ExclamationCircleOutlined />,
			centered: true,
			width: '40%',
			maskClosable: false,
			content: 'Вы действительно хотите удалить этот элемент?',
			onOk: (close) => {
				if (e.id) {
					submit(
						{ id: e.id },
						{
							method: 'delete',
							action: location.pathname,
							encType: 'application/json',
						}
					)
					close()
				}
			},
		})
	}
	const title =
		mode === modes.create
			? 'Создание нового элемента'
			: mode === modes.edit
			? 'Редактирование элемента'
			: null
	return (
		<>
			<Space direction="vertical">
				<Bar form={form} showSearch={showSearch} />
				<Layout.Content>
					<Card style={{ margin: '0 16px' }} size="small">
						<Table
							dataSource={filteredData}
							columns={[
								...columns,
								...(noRowButtons
									? []
									: [
											{
												title: null,
												width: '5%',
												render: (item: T) => (
													<TableRowButtons onEdit={onEdit} onDelete={onDelete} record={item} />
												),
											},
									  ]),
							]}
							pagination={{ hideOnSinglePage: true, pageSize: 18 }}
							rowKey="id"
							size="small"
							onRow={onRow}
						/>
					</Card>
				</Layout.Content>
			</Space>
			<Modal
				className="modal-style"
				open={isModalOpen}
				onCancel={() => {
					if (form.isFieldsTouched()) {
						return Modal.confirm({
							icon: <ExclamationCircleOutlined />,
							centered: true,
							width: '25%',
							content: 'Выйти без сохранения изменений?',
							okText: 'Выйти',
							onOk: (close) => {
								if (form) {
									form.resetFields()
								}
								close()
								setMode(modes.view)
							},
						})
					}
					setMode(modes.view)
				}}
				afterClose={() => setRecord(null)}
				okButtonProps={{ htmlType: 'submit', form: 'dict' }}
				destroyOnClose
				centered
				width="40%"
				title={title}
				maskClosable={false}
				cancelButtonProps={{ style: { display: 'none' } }}
				styles={{ body: { borderRadius: '2px 2px 0 0', width: '100%' } }}
			>
				<CustomForm
					preserve={false}
					form={form}
					name="dict"
					items={items}
					initialValues={record ?? initialValues}
					onFinish={(values) => {
						submit(
							{ ...values, id: record?.id },
							{
								method: mode === modes.create ? 'post' : 'patch',
								action: location.pathname,
								encType: 'application/json',
							}
						)
						setRecord(null)
						setMode(modes.view)
					}}
				/>
			</Modal>
		</>
	)
}

export default DictionaryTemplate
