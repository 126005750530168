import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { EquipmentType as EquipmentTypeEntity } from '@isf/backend/src/modules/dictionaries/modules/equipment-type/entities/equipment-type.entity'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import React from 'react'
import { CompareFn } from 'antd/es/table/interface'
import { useColumnSearchProps } from '../../../utils/hooks'

const layout = { labelCol: { span: 8 } }

const searchFields = ['type', 'model']

const EquipmentType = () => {
	const data = useLoaderData() as EquipmentTypeEntity[]
	const { getColumnSearchProps } = useColumnSearchProps(data)
	const submit = useSubmit()
	const columns: ColumnsType<EquipmentTypeEntity> = [
		{
			title: 'Тип оборудования',
			dataIndex: 'type',
			sorter: sorter(SorterTypes.string, 'type') as CompareFn<EquipmentTypeEntity>,
			...getColumnSearchProps('type'),
		},
		{
			title: 'Модель оборудования',
			dataIndex: 'model',
			sorter: sorter(SorterTypes.string, 'model') as CompareFn<EquipmentTypeEntity>,
			...getColumnSearchProps('model'),
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'type',
			label: 'Тип оборудования',
			rules: [{ required: true }],
			...layout,
		},
		{
			name: 'model',
			label: 'Модель оборудования',
			rules: [{ required: true }],
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default EquipmentType
