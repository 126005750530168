import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
import { SorterTypes, sorter } from '../../../utils/helpers/sorter'
import { SelectFetch } from '../../../components'
import { useColumnSearchProps } from '../../../utils/hooks'

const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['label', 'tmcCategoryLabel', 'tmcGroupLabel', 'measureLabelShort']

const MaterialTypes = () => {
	const data = useLoaderData()
	const { getColumnSearchProps } = useColumnSearchProps(data)
	const submit = useSubmit()
	const columns: ColumnsType<{
		categoryLabel: string
		group: string
		id: number
	}> = [
		{
			title: 'Наименование',
			dataIndex: 'label',
			sorter: sorter(SorterTypes.string, 'label'),
			...getColumnSearchProps('label'),
		},
		{
			title: 'Категория',
			dataIndex: ['tmcCategory', 'label'],
			...getColumnSearchProps(['tmcCategory', 'label']),
		},
		{
			title: 'Группа',
			dataIndex: ['tmcCategory', 'label'],
			...getColumnSearchProps(['tmcGroup', 'label']),
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'label',
			label: 'Наименование',
			rules: [{ required: true }],
			...layout,
		},
		{
			name: 'tmcCategory',
			label: 'Категория',
			required: true,
			rules: [{ required: true }],
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => ({
				id: value,
				label,
			}),
			component: <SelectFetch url="dictionaries/tmc-categories" />,

			...layout,
		},
		{
			name: 'tmcGroup',
			label: 'Группа',
			required: true,
			rules: [{ required: true }],
			getValueFromEvent: ({ value, label }: { value: number; label: string }) => {
				return {
					id: value,
					label,
				}
			},
			component: <SelectFetch url="dictionaries/tmc-groups" />,
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default MaterialTypes
