export enum Modes {
	view = 'VIEW',
	edit = 'EDIT',
	create = 'CREATE',
}

const modes = {
	view: Modes.view,
	edit: Modes.edit,
	create: Modes.create,
}

export default modes
