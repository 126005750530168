import lodash from 'lodash'
import { FilterDropDown } from '../../components'
import { ColumnFilterItem, FilterDropdownProps } from 'antd/es/table/interface'
import { Key } from 'react'
import { Typography } from 'antd'

const getText = (el, key, specialKeys) => {
	const value = lodash.get(el, key)
	if (typeof value === 'boolean') {
		return value ? specialKeys.true ?? 'Да' : specialKeys.false ?? 'Нет'
	}
	if (value === null || value === undefined) {
		return <Typography.Text italic>Пусто</Typography.Text>
	}
	return (
		<Typography.Text style={{ width: '22ch' }} ellipsis={{ tooltip: true }}>
			{value}
		</Typography.Text>
	)
}

export const getFilters = <T,>(
	inArr: T[] = [],
	inKey: string | string[],
	specialKeys?: { true: string; false: string }
): ColumnFilterItem[] =>
	inArr
		.filter(
			(el, index, self) =>
				self.findIndex((selfEl) => lodash.get(selfEl, inKey) === lodash.get(el, inKey)) === index
		)
		.sort((a, b) =>
			[null, undefined].includes(lodash.get(a, inKey))
				? -1
				: [null, undefined].includes(lodash.get(b, inKey))
				? 1
				: String(lodash.get(a, inKey))?.localeCompare(String(lodash.get(b, inKey)))
		)
		.map((e, _, array) => ({
			text: getText(e, inKey, specialKeys),
			value: lodash.get(e, inKey)?.id || lodash.get(e, inKey),
		}))

/**
 * Возвращает обьект со свойствами нужными для колонки таблицы для отрисовки фильтра
 * @param {String} inKey - ключ через дот нотацию по которому читать значение
 * @param {Object} inArr - массив с обьектами в которых искать поле
 * @param {{pathFunc: Function, divider: string, filters: [{text,value}], onFilter: (value, record) => boolean, formatter: (record) => (String | Number)}} } options - объект опций для получения кастомных
 */
export const getColumnSearchProps: <T>(
	inArr: T[],
	inKey: string | string[],
	frontFilters?: T[],
	isNeedToFormat?: boolean
) => {
	filterDropdown: (props: FilterDropdownProps) => React.JSX.Element
	onFilter: (value: boolean | Key, record: T) => boolean
} = (inArr, inKey, frontFilters) => ({
	filterDropdown: (props) => <FilterDropDown {...props} filters={getFilters(inArr, inKey)} />,
	...(frontFilters && {
		filtered: lodash.get(frontFilters, inKey)?.length,
		filteredValue: lodash.get(frontFilters, inKey) || [],
	}),
	onFilter: inKey
		? (value, record) => {
				const recordValue = lodash.get(record, inKey)
				if (Array.isArray(recordValue)) {
					return recordValue.map((v) => v.id).includes(value)
				}
				return recordValue === value
		  }
		: () => {
				return true
		  },
})

/**
 * подготавливает даннные для отображения в фильтре
 * @param {Array<object>} inArr
 * @param {string} inKey - поле из которого будут браться значения
 */
export const getValuesForFilter = <T,>(inArr: T[], inKey: [keyof T]) => {
	const result = inArr.filter((e) => lodash.get(e, inKey)).map((e) => lodash.get(e, inKey))

	const onlyUnique = [...new Set(result)]
	return onlyUnique.sort((a, b) =>
		typeof a === 'number' ? a - b : String(a).localeCompare(String(b))
	)
}

export const forSearchInFieldProps = {
	showSearch: true,
	filterOption: (input, option) => {
		return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
	},
}

/**
 * проверяет удовлетворяет ли значение критериям фильтра
 */
export const isAcceptedByFilters = (obj, filters) => {
	return Object.entries(filters).every(([name, val]) => {
		if (!val?.length) return true
		val = val.map((v) => String(v))
		if (!obj[name]) {
			return false
		}
		if (typeof obj[name] === 'object' && !Array.isArray(obj[name])) {
			return val.includes(String(obj[name]['id']))
		}
		if (Array.isArray(obj[name]) && obj[name].some((e) => val.includes(String(e)))) return true
		if (!val.includes(String(lodash.get(obj, name)))) {
			return false
		}
		return true
	})
}
