import { useLoaderData, useSubmit } from 'react-router-dom'
import DictionaryTemplate from '../DictionaryTemplate'
import type { ColumnsType } from 'antd/es/table'
import { NdsRate as NdsRateEntity } from '@isf/backend/src/modules/dictionaries/modules/nds-rate/entities/nds-rate.entity'
import { Checkbox, Input } from 'antd'
import { CustomFormItemProps } from '../../../types/antd-custom-types'
const layout = { labelCol: { span: 8 } }

const searchFields: string[] = ['nds']

const NdsRates = () => {
	const data = useLoaderData()
	const submit = useSubmit()
	const columns: ColumnsType<NdsRateEntity> = [
		{
			title: 'Ставка НДС, %',
			dataIndex: 'nds',
		},
		{
			title: 'Не используется',
			dataIndex: 'isNotUse',
			render: (isNotUse) => <Checkbox checked={isNotUse} disabled />,
		},
	]
	const items: CustomFormItemProps[] = [
		{
			name: 'nds',
			label: 'Ставка НДС, %',
			rules: [{ required: true }],
			component: <Input />,
			...layout,
		},
		{
			name: 'isNotUse',
			label: 'Не используется',
			valuePropName: 'checked',
			component: <Checkbox />,
			...layout,
		},
	]
	return (
		<DictionaryTemplate
			columns={columns}
			dataSource={data}
			items={items}
			searchFields={searchFields}
			submit={submit}
		/>
	)
}

export default NdsRates
