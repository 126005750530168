import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { Staff } from '@isf/backend/src/modules/staff/entities/staff.entity'
import { getColumns, getType } from './constants'
import ListTemplate from '../ListTemplate'
import { Card, Layout, Space } from 'antd'
import Bar from '../../layout/bar/Bar'
import { useColumnSearchProps } from '../../utils/hooks'

const StaffList = () => {
	const data = useLoaderData() as Staff[]
	const { getColumnSearchProps } = useColumnSearchProps(data)
	const navigate = useNavigate()
	const location = useLocation()
	const onRow = (record: Staff) => ({
		onClick: () => navigate(`${location.pathname}/${record.id}`),
	})
	const columns = getColumns(getColumnSearchProps)
	return (
		<Space direction="vertical">
			<Bar />
			<Layout.Content>
				<Card style={{ margin: '0 16px' }} size="small">
					<ListTemplate
						columns={columns}
						dataSource={data}
						onRow={onRow}
						noRowButtons
						searchFields={columns.map((col) => col.dataIndex).with(-1, getType)}
					/>
				</Card>
			</Layout.Content>
		</Space>
	)
}

export default StaffList
